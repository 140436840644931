import React, { useState, useEffect } from "react";
import { Drawer, Form, Input, Button, Select, Upload, Row, Col } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import CompanyService from "../../services/CompanyService";
import "./Company.scss";
import { filterOption, openNotification } from "../../utils";
import { RcFile } from "antd/es/upload";
import ImageUpload from "../Uploads/ImageUpload";

interface CompanyEditDrawerProps {
  visible: boolean;
  onClose: () => void;
  companyId: string;
}

const CompanyEditDrawer: React.FC<CompanyEditDrawerProps> = ({ visible, onClose, companyId }) => {
  const [form] = Form.useForm();
  const [imageId, setImageId] = useState<number | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [cities, setCities] = useState<{ id: number; name: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [billingAddressId, setBillingAddressId] = useState<number | null>(null);
  const [existingData, setExistingData] = useState<any>({});
  const [districts, setDistricts] = useState<{ id: number; name: string }[]>([]);
  const [company, setCompany] = useState<any>([]);
  const [selectedCityId, setSelectedCityId] = useState<number | null>(null);

  useEffect(() => {

    const fetchCompanyDetails = async () => {
      const responseCity = await CompanyService.getStates(225);
      setCities(responseCity.data);

      if (companyId) {
        try {
          const response = await CompanyService.getCompany(companyId);
          const companyData = response.data;
          setExistingData(response.data)
          const billingAddress = companyData.addresses.find(
            (address: any) => address.type === "BILLING"
          );

          if (billingAddress) {
            form.setFieldsValue({
              address: billingAddress.addressLine1,
              city: billingAddress.city,
              district: billingAddress.state,
            });
            setBillingAddressId(billingAddress.id);
          }

          // Set other company fields
          form.setFieldsValue({
            name: companyData.name,
            taxOffice: companyData.taxOffice,
            taxNumber: companyData.taxNumber,
            email: companyData.email,
            phoneNumber: companyData.phoneNumber,
            kepAddress: companyData.kepAddress,
          });

          setImageId(companyData.imageId);
          setCompany(companyData);

          const city = await responseCity.data.find((c: any) => c.name === billingAddress.city);
          console.log(city);
          setSelectedCityId(city?.id ?? null);
          getDistricts(city?.id);


          if (companyData.imageId) {
            const imageResponse = await CompanyService.getImage(companyData.imageId);
            console.log(imageResponse.data.preSignUrl);
            setImagePreview(imageResponse.data.preSignUrl);
          }
        } catch (error) {
          console.error("Firma bilgileri yüklenirken hata oluştu:", error);
        }
      }
    };



    fetchCompanyDetails();
  }, [companyId, form]);

 

  const onFinish = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();

      let updatedCompanyData: any = {};
      updatedCompanyData = values;
      updatedCompanyData.imageId = imageId;

      const updatedAddresses = existingData?.addresses.map((address: any) => {
        if (address.type === "BILLING") {
          return {
            ...address,
            addressLine1: values.address || form.getFieldValue('address'),
            city: values.city || form.getFieldValue('city'),
            state: values.district || form.getFieldValue('district'),
          };
        }
        return address;
      });

      if (updatedAddresses) {
        updatedCompanyData.companyAddresses = updatedAddresses;
      }

      console.log(updatedCompanyData);

      await CompanyService.update(companyId, updatedCompanyData);

      openNotification("success", "Başarılı", "Firma bilgileri güncellendi.");
      onClose();
      setLoading(false);
    } catch (error) {
      openNotification("error", "Hata", "Firma bilgileri güncellenemedi.");
      setLoading(false);
    }
  };


  const getDistricts = async (stateId: number) => {
    try {
      const response = await CompanyService.getDistricts(stateId);
      setDistricts(response.data);
    } catch (error) {
      console.error("İlçeler alınırken hata oluştu:", error);
    }
  };

  const handleCityChange = (value: number) => {
    const city = cities.find((c) => c.id === value);
    setSelectedCityId(value);
    form.setFieldsValue({ city: city?.name, district: undefined });
    setDistricts([]);
    if (value) {
      getDistricts(value);
    }
  };

  const handleDistrictChange = (value: string) => {
    form.setFieldsValue({ controlDistrict: value });
  };

  return (
    <Drawer
      title="Firma Düzenle"
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div className="text-right" >
          <Button type="primary" onClick={onFinish} loading={loading} className="d-button dark-button p-20px width-auto">
            Kaydet ve Bitir
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Logo" name="logo" valuePropName="file" className="form-item">
            {/* // Upload component */}
            <ImageUpload imageId={company?.imageId} setImageId={setImageId} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Firma Ünvanı"
              className="form-item"
              rules={[{ required: true, message: "Firma Ünvanı boş bırakılamaz" }]}
            >
              <Input
                placeholder="Firma Ünvanı"
                className="d-input d-input-form"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="taxOffice"
              label="Vergi Dairesi"
              className="form-item"
              rules={[{ required: true, message: "Vergi Dairesi boş bırakılamaz" }]}
            >
              <Input
                placeholder="Vergi Dairesi"
                className="d-input d-input-form"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="taxNumber"
              label="Vergi Numarası"
              className="form-item"
              rules={[
                { required: true, message: "Vergi Numarası boş bırakılamaz" },
                { message: "Vergi Numarası 10 veya 11 haneli olmalıdır", max: 11, min: 10 },
                { pattern: /^[0-9]*$/, message: "Vergi Numarası sadece rakamlardan oluşmalıdır" },
              ]}
            >
              <Input
                placeholder="Vergi Numarası"
                className="d-input d-input-form"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email"
              label="E-posta"
              className="form-item"
              rules={[
                { required: true, message: "E-posta boş bırakılamaz" },
                { type: "email", message: "Geçerli bir e-posta adresi girin" },
              ]}
            >
              <Input
                placeholder="E-posta"
                className="d-input d-input-form "
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phoneNumber"
              label="Telefon Numarası"
              className="form-item"
              rules={[
                { required: true, message: 'Telefon Numarası boş bırakılamaz' },
                { pattern: /^[1-9]/, message: 'Telefon Numarası 0 ile başlamamalıdır' },
                { pattern: /^\d{10}$/, message: 'Telefon Numarası toplamda 10 haneli olmalıdır' },
              ]}
            >
              <Input
                addonBefore="+90"
                placeholder="Cep Telefonu"
                className="d-input d-input-form"
                maxLength={10}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="city"
              label="İl"
              className="form-item"
              rules={[{ required: true, message: "İl seçimi zorunludur" }]}
            >
              <Select
                placeholder="İl seçin"
                className="d-input d-input-form"
                showSearch
                filterOption={filterOption}
                onChange={handleCityChange}

              >
                {cities.map((city) => (
                  <Select.Option key={city.id} value={city.id}>
                    {city.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="district"
              label="İlçe"
              rules={[{ required: true, message: "İlçe seçimi zorunludur" }]}
            >
              <Select
                placeholder="İlçe seçin"
                className="d-input d-input-form"
                showSearch
                filterOption={filterOption}
                onChange={handleDistrictChange}
                disabled={!selectedCityId}
              >
                {districts.map((district) => (
                  <Select.Option key={district.id} value={district.name}>
                    {district.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="address"
              label="Fatura Adresi"
              className="form-item"
              rules={[{ required: true, message: "Fatura Adresi boş bırakılamaz" }]}
            >
              <Input
                placeholder="Fatura Adresi"
                className="d-input d-input-form"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="kepAddress"
              label="Kep Adresi"
              className="form-item"
              rules={[
                { type: 'email', message: 'Geçerli bir Kep Adresi girin' }
              ]}
            >
              <Input
                placeholder="Kep Adresi"
                className='d-input d-input-form '
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default CompanyEditDrawer;