import React, { useState } from 'react';
import { Modal, Switch, Button, Divider, Typography, Space } from 'antd';

const { Text } = Typography;

const CookieConsentModal = ({ isVisible, onClose }: any) => {
  const [analyticsEnabled, setAnalyticsEnabled] = useState(true);
  const [adsEnabled, setAdsEnabled] = useState(true);
  const [isPrivacyModalVisible, setIsPrivacyModalVisible] = useState(false);

  const handleSave = () => {
    // Kullanıcı tercihlerinin localStorage'e kaydedilmesi
    localStorage.setItem('analyticsEnabled', JSON.stringify(analyticsEnabled));
    localStorage.setItem('adsEnabled', JSON.stringify(adsEnabled));

    // Tercihler kaydedildikten sonra kapat
    onClose();
  };

  const handleAcceptAll = () => {
    // Tüm switchleri açık duruma getirme
    setAnalyticsEnabled(true);
    setAdsEnabled(true);
    handleSave();
  };

  return (
    <>
      <Modal
        title="Kontrol X İş Ortağım Platformundaki Tanımlama Bilgileri (Çerezler)"
        visible={isVisible}
        footer={null}
        closable={false}
        width={600}
        bodyStyle={{ maxHeight: '500px', overflowY: 'auto' }}
      >
        <Typography.Paragraph type="secondary">
          Kontrol X olarak, size en iyi kullanıcı deneyimini sunmak amacıyla, platformumuzda tanımlama bilgileri (çerezler) kullanmaktayız. Bu çerezler, platformumuzun daha verimli çalışmasına, tercihlerinizi hatırlamamıza ve kullanıcı hareketlerini analiz ederek, hizmetlerimizi geliştirmemize yardımcı olur. Tanımlama bilgileri (çerezler), cihazınıza küçük veri dosyaları olarak kaydedilir ve platformumuzu ziyaret ettiğinizde belirli bilgileri toplar. Toplanan bu bilgiler kişisel verilerinizin güvenli bir şekilde işlenmesini sağlar. Tanımlama bilgileri (çerezler) hakkında daha fazla bilgi edinmek ve tercihlerinizi değiştirmek isterseniz, lütfen  <a onClick={() => setIsPrivacyModalVisible(true)} style={{ color: '#000', cursor: 'pointer', fontWeight: 600 }}>Gizlilik Politikamıza</a>  göz atın.
        </Typography.Paragraph>

        {/* <Typography.Paragraph>
          Tanımlama bilgileri (çerezler) hakkında daha fazla bilgi edinmek ve tercihlerinizi
          değiştirmek isterseniz, lütfen <a onClick={() => setIsPrivacyModalVisible(true)} style={{ color: '#000', cursor: 'pointer', fontWeight: 600 }}>Gizlilik Politikamıza</a> göz atın.
        </Typography.Paragraph> */}
        <Button className='d-button dark-button width-auto' type="primary" block onClick={handleAcceptAll}>
          Tüm Çerezleri Kabul Et
        </Button>
        <Divider />
        <div>
          <div className='d-flex align-items-center mb-8px'>
            <Space size={16}>
              <Text strong>Gerekli ve Fonksiyonel Çerezler:</Text>
              <div className="d-flex align-items-center">
                <Switch checked disabled />
                <Text className="ml-8px">Daima Etkin</Text>
              </div>
            </Space>
          </div>
          <Text type="secondary">
            Gerekli ve Fonksiyonel Çerezler: Bu çerezler, platformumuzun temel işlevlerini yerine getirmesine olanak tanır ve platformumuzun güvenliğini sağlar. Bu çerezler olmadan, platformumuzun düzgün çalışması mümkün değildir. (Her zaman etkin)
          </Text>
          <div className="d-flex align-items-center mt-16px">
            <Space size={16}>
              <Text strong>Analiz ve Performans Çerezleri:</Text>
              <div className="d-flex align-items-center">
                <Switch checked={analyticsEnabled} onChange={setAnalyticsEnabled} />
                <Text className="ml-8px">{analyticsEnabled ? 'Açık' : 'Kapalı'}</Text>
              </div>
            </Space>
          </div>
          <Text type="secondary">
            Analiz ve Performans Çerezleri: Bu çerezler, kullanıcıların platformumuzu nasıl kullandığı hakkında bilgi toplar ve platformumuzu iyileştirmemize yardımcı olur. Bu veriler, ziyaretçi sayısını, en çok ziyaret edilen sayfaları ve kullanıcıların platformumuzda geçirdiği süreyi içerir.
          </Text>
          <div className="d-flex align-items-center mt-16px">
            <Space size={16}>
              <Text strong>Reklam ve Pazarlama Çerezleri:</Text>
              <div className="d-flex align-items-center">
                <Switch checked={adsEnabled} onChange={setAdsEnabled} />
                <Text className="ml-8px">{adsEnabled ? 'Açık' : 'Kapalı'}</Text>
              </div>
            </Space>
          </div>
          <Text type="secondary">
            Reklam ve Pazarlama Çerezleri: Bu çerezler, ilgi alanlarınıza yönelik içerik ve reklamları size sunmak için kullanılır. Bu çerezler, üçüncü taraf reklam sağlayıcıları tarafından da kullanılabilir.
          </Text>
        </div>

        <Button className='d-button dark-button width-auto mt-24px' type="primary" onClick={handleSave}>
          Kaydet ve Kapat
        </Button>
      </Modal>

      <Modal
        title="Gizlilik Politikası"
        visible={isPrivacyModalVisible}
        onCancel={() => setIsPrivacyModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setIsPrivacyModalVisible(false)}>
            Kapat
          </Button>,
        ]}
        width={400}
      >
        <Typography.Paragraph>Buraya sözleşme gelecek.</Typography.Paragraph>
      </Modal>
    </>
  );
};

export default CookieConsentModal;