import React, { useState, useEffect } from 'react';
import { Tabs, Button, Card, Row } from 'antd';
import { useLocation } from 'react-router-dom';
import ProposalCompanySelect from '../../components/Proposal/ProposalCompanySelect';
import ControlAddress from '../../components/Companies/ControlAddress';
import Parametres from '../../components/Proposal/Parametres';
import NoData from '../../assets/nodata.png';
import ProposalService from '../../services/ProposalService';
import AuthorizedPersonTable from '../../components/Companies/AuthorizedPersonTable';

const { TabPane } = Tabs;

const Proposals: React.FC = () => {
    const location = useLocation();
    const [activeKey, setActiveKey] = useState('1');
    const [companyId, setCompanyId] = useState<any>(null);
    const [attributes, setAttributes] = useState<any>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedAddressId, setSelectedAddressId] = useState<any>(null);
    const [roles, setRoles] = useState<any>();
    const [selectedAuthorizedPersonIds, setSelectedAuthorizedPersonIds] = useState<number[]>([]); // Multiple selected authorized person IDs

    // Function to extract query params
    const getQueryParams = (search: string) => {
        return new URLSearchParams(search);
    };

    useEffect(() => {
        let storedRoles: any = localStorage.getItem('roles');
        let parsedRoles = JSON.parse(storedRoles);
        setRoles(parsedRoles);

        const queryParams = getQueryParams(location.search);
        const companyIdFromQuery = queryParams.get('companyId');
        const proposalIdFromQuery: any = queryParams.get('proposalId');
        const isEditQuery: any = queryParams.get('isEdit');
        const activeKey: any = queryParams.get('activeKey');
        if (companyIdFromQuery) {
            setActiveKey('2');
            setCompanyId(companyIdFromQuery);
        }
        if (activeKey) {
            setActiveKey('2');
        }
        if (proposalIdFromQuery) {
            setActiveKey('2');
            const getInfos = async () => {
                let response: any = await ProposalService.get(proposalIdFromQuery);
                setCompanyId(response.data.company.id);
                setSelectedAddressId(response.data.address.id);
                setAttributes(response.data.attributes);
                setSelectedAuthorizedPersonIds(response?.data?.authorizedPersonIds?.map((person: any) => person) || []); // Multiple authorized persons
                if (isEditQuery && isEditQuery === 'true') {
                    setIsEdit(true);
                }
            };
            getInfos();
        }
    }, [location.search]);

    return (
        <div style={{paddingBottom:120}}>
            <ProposalCompanySelect setCompanyId={setCompanyId} companyId={companyId} />

            {/* Kontrol Adresi Kartı */}
            <Card className='br-30px mt-16px'>
                {companyId ? (
                    <ControlAddress
                        companyId={companyId}
                        type={true}
                        setSelectedAddress={setSelectedAddressId}
                        selectedAddressId={selectedAddressId}
                        isEdit={isEdit}
                    />
                ) : (
                    <div>
                        <Row justify={'space-between'} align={'middle'}>
                            <h3>Kontrol Adresi</h3>
                            <Button type='primary' className='d-button dark-button width-auto' disabled>
                                Yeni Kontrol Adresi Ekle
                            </Button>
                        </Row>
                        <Row justify={'center'}>
                            <img src={NoData} style={{ height: '50px' }} />
                        </Row>
                        <Row justify={'center'}>
                            <p>Teklif oluşturmak için firma seçmelisiniz.</p>
                        </Row>
                    </div>
                )}
            </Card>

            {/* Yetkili Kişi Tablosu */}
            <Card className='br-30px mt-16px'>
                {companyId ? (
                    <>
                        <AuthorizedPersonTable
                            type={true}
                            companyId={companyId}
                            setSelectedAuthorizedPersonIds={setSelectedAuthorizedPersonIds} // Set multiple IDs
                            selectedAuthorizedPersonIds={selectedAuthorizedPersonIds} // Pass multiple IDs
                        />
                    </>
                ) : (
                    <div>
                        <Row justify={'space-between'} align={'middle'}>
                            <h3>Yetkili Kişi</h3>
                            <Button type='primary' className='d-button dark-button width-auto' disabled>
                                Yeni Yetkili Ekle
                            </Button>
                        </Row>
                        <Row justify={'center'}>
                            <img src={NoData} style={{ height: '50px' }} />
                        </Row>
                        <Row justify={'center'}>
                            <p>Teklif oluşturmak için firma seçmelisiniz.</p>
                        </Row>
                    </div>
                )}
            </Card>

            {/* Parametre Kartı */}
            <Card className='br-30px mt-16px'>
                {companyId ? (
                    <Parametres
                        companyId={companyId}
                        addressId={selectedAddressId}
                        attributes={attributes}
                        isEdit={isEdit}
                        authorizedPersonIds={selectedAuthorizedPersonIds} // Pass multiple IDs to Parametres
                    />
                ) : (
                    <div>
                        <Row>
                            <h3>Parametre Listesi</h3>
                        </Row>
                        <Row justify={'center'}>
                            <img src={NoData} style={{ height: '50px' }} />
                        </Row>
                        <Row justify={'center'}>
                            <p>Teklif oluşturmak için firma seçmelisiniz.</p>
                        </Row>
                    </div>
                )}
            </Card>
        </div>
    );
};

export default Proposals;