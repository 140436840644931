import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Table, Button, Card, Input, Avatar, Popconfirm, Typography, Row, Col, Tooltip, Pagination } from "antd";
import { ColumnsType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import CompanyCreate from "../../components/Companies/CompanyCreate";
import CompanyService from "../../services/CompanyService";
import TrashIcon from '../../assets/icons/trash.svg';
import PenIcon from '../../assets/icons/pen.svg';
import { capitalizeWords, formatDate, openNotification } from "../../utils";
import { debounce } from 'lodash';
import './Company.scss'

const { Title, Text } = Typography;

interface Company {
  id: string;
  name: string;
  authorizedPersons: [{
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  }];
  email: string;
  phoneNumber: string;
  creationDate: string;
  imageId?: string;
  logoUrl?: string;
}

const Companies: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]); // Filtrelenen şirketler
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [roles, setRoles] = useState<any>();
  const [isEditDrawerVisible, setIsEditDrawerVisible] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
  const [total, setTotal] = useState(0); // Total records for pagination
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [pageSize, setPageSize] = useState(10); // Page size
  const [searchTerm, setSearchTerm] = useState(""); // Search term
  const navigate = useNavigate();
  const location = useLocation();
  const [sortField, setSortField] = useState("createdAt");
  const [sortDirection, setsortDirection] = useState<"ASC" | "DESC">("DESC");
  const [userId, setUserId] = useState(localStorage.getItem('id'))
  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      if (value.length >= 1) {
        setSearchTerm(value);
        setCurrentPage(0)

      } else {
        setSearchTerm("");
        setCurrentPage(0)
      }
    }, 300),
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    debouncedSearch(value);
  };

  useEffect(() => {


    fetchCompanies();
  }, [isDrawerVisible, isEditDrawerVisible, searchTerm, currentPage, pageSize,sortField, sortDirection]);

  const fetchCompanies = async () => {
    try {
      let storedRoles: any = await localStorage.getItem('roles');
      let parsedRoles = await JSON.parse(storedRoles);
      setRoles(parsedRoles);
      const response = await (parsedRoles && parsedRoles.some((role: any) => role.name === "ADMIN")
        ? CompanyService.getAll({ search: searchTerm, page: currentPage, size: pageSize, sortField, sortDirection })
        : CompanyService.getAllMe({ search: searchTerm, page: currentPage, size: pageSize, sortField, sortDirection })
      );

      const companiesWithLogo = await Promise.all(
        response.data.content.map(async (company: Company) => {
          if (company.imageId) {
            try {
              const logoResponse = await CompanyService.getImage(company.imageId);
              console.log("Logo yüklendi:", logoResponse.data);
              return { ...company, logoUrl: logoResponse.data.preSignUrl };
            } catch (error) {
              console.error("Logo yüklenirken hata oluştu:", error);
            }
          }
          return company;
        })
      );
      setCompanies(companiesWithLogo);
      setFilteredCompanies(companiesWithLogo);
      setTotal(response.data.totalElements); // Set total records for pagination
    } catch (error) {
      console.error("Şirketler yüklenirken hata oluştu:", error);
    }
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setCurrentPage(pagination.current - 1);
    setPageSize(pagination.pageSize || 10);
    setSortField(sorter.field || "createdAt");
    setsortDirection(sorter.order === "ascend" ? "ASC" : "DESC");
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const activeKey: any = queryParams.get('activeKey');
    if (activeKey === 'create') {
      showDrawer();
    }
  }, []);

  const handleOfferNavigation = (companyId: any) => {
    navigate(`/proposals/create?companyId=${companyId}`);
  };

  const columns: ColumnsType<Company> = [
    {
      title: "Firma Adı",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const displayText = text.length > 28 ? `${text.substring(0, 28)}...` : text;
        return (
          <div className="d-flex align-items-center" >
            <div className='avatar-image-logo' style={{ marginRight: 10 }}>
              {record.logoUrl ? (
                <img
                  src={record.logoUrl}
                  alt={record.name}
                  className='avatar-image-logo'
                  style={{ borderRadius: '50%' }}
                />
              ) : (
                <Avatar className='avatar-image-logo' style={{ backgroundColor: '#f0f0f0', color: '#000' }}>
                  {record.name.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </div>
            <Tooltip title={text.toUpperCase()}>
              <span>{displayText.toUpperCase()}</span>
            </Tooltip>
          </div>
        );
      },
    },
    ...(roles && roles.some((role: any) => role.name === 'ADMIN') ? [{
      title: "İş Ortağı Ad Soyad",
      key: "accountOwner",
      render: (text: any, record: any) => record.account ? `${record.account.firstName} ${record.account.lastName}` : "Bilgi Yok",
    }] : []),
    ...(roles && roles.some((role: any) => role.name === 'ADMIN') ? [{
      title: "İş Ortağı E-posta",
      key: "accountEmaıl",
      render: (text: any, record: any) => record.account ? `${record.account.email}` : "Bilgi Yok",
    }] : []),
    ...(roles && roles.some((role: any) => role.name === 'ADMIN') ? [{
      title: "İş Ortağı Numara",
      key: "accountOwner",
      render: (text: any, record: any) => record.account ? `${record.account.phoneNumber}` : "Bilgi Yok",
    }] : []),
    ...(roles && !roles.some((role: any) => role.name === 'ADMIN') ? [{
      title: "Yetkili Ad Soyad",
      key: "authorizedPersons",
      render: (text: any, record: any) => {
        const defaultPerson = record.authorizedPersons.find((person: any) => person.default) || record.authorizedPersons[0];
        return defaultPerson ? `${capitalizeWords(defaultPerson.firstName)} ${capitalizeWords(defaultPerson.lastName)}` : "Yetkili Bulunamadı";
      },
    }] : []),
    ...(roles && !roles.some((role: any) => role.name === 'ADMIN') ? [{
      title: "Yetkili E-posta",
      dataIndex: "email",
      key: "email",
      render: (text: any, record: any) => {
        const defaultPerson = record.authorizedPersons.find((person: any) => person.default) || record.authorizedPersons[0];
        return defaultPerson ? defaultPerson.email : "Yetkili E-posta Bulunamadı";
      },
    }] : []),
    ...(roles && !roles.some((role: any) => role.name === 'ADMIN') ? [{
      title: "Yetkili Cep Telefonu",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text: any, record: any) => {
        const defaultPerson = record.authorizedPersons.find((person: any) => person.default) || record.authorizedPersons[0];
        return defaultPerson ? defaultPerson.phoneNumber : "Yetkili Telefon Bulunamadı";
      },
    }] : []),
    {
      title: "Oluşturma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text) => <Text>{formatDate(text)}</Text>,
    },
    {
      title: "İşlem",
      key: "action",
      align: 'center',
      width: 200,
      render: (text, record:any) => (
        <div className="companies-action">
          <Button
            type="default"
            className='action-button'
            onClick={() => handleOfferNavigation(record.id)}
            disabled={record.account && record.account.id != userId}
          >
            Teklif Oluştur
          </Button>
          <Button
            icon={<img src={PenIcon} alt="pen" className="icon" />}
            shape="circle"
            onClick={() => handleSendOffer(record.id)}
            className="icon-button ml-12px"
          />
          <Popconfirm
            title="Bu firmayı silmek istediğinize emin misiniz?"
            onConfirm={() => handleDelete(record.id)}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button
              icon={<img src={TrashIcon} alt="trash" className="icon" />}
              shape="circle"
              className="icon-button ml-12px"
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const handleSendOffer = (companyId: string) => {
    navigate(`/companies/${companyId}`);
  };

  const handleDelete = async (companyId: string) => {
    try {
      await CompanyService.remove(companyId);
      openNotification('success', 'Başarılı', 'Firma başarıyla silindi');
      const updatedCompanies = companies.filter((company) => company.id !== companyId);
      setCompanies(updatedCompanies);
      setFilteredCompanies(updatedCompanies);
    } catch (error) {
      openNotification("error", "Hata", "Firma silinirken bir hata oluştu");
    }
  };

  const pagination = useMemo(() => ({
    current: currentPage + 1,
    pageSize: pageSize,
    total: total,
    onChange: (page: number, pageSize?: number) => {
      setCurrentPage(page - 1);
      setPageSize(pageSize || 10);
    },
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30'],
  }), [currentPage, pageSize, total]);

  return (
    <Card className="company-card br-30px mb-120px" >
      <Row gutter={[16, 16]} align="middle" justify="space-between" className="mb-20px">
        <Col xs={24} sm={12} lg={16}>
          <Title level={4} style={{ margin: 0 }}>
            Firma Listesi
          </Title>
        </Col>
        <Col xs={24} sm={12} lg={8} style={{ textAlign: 'center' }} className="d-flex justify-content-end">
          <Input
            placeholder="Ara"
            prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
            className="d-input d-input-filter"
            style={{ width: '100%', maxWidth: 200, marginRight: 8 }}
            onChange={handleSearch}
          />
            <Button type="primary" onClick={showDrawer} className="d-button dark-button width-auto br-30px">
              Yeni Firma Ekle
            </Button>
        </Col>
      </Row>
      <Table
        scroll={{ x: 768 }}
        columns={columns}
        dataSource={filteredCompanies}
        rowKey="id"
        pagination={pagination}
        onChange={handleTableChange}
      />
      <CompanyCreate visible={isDrawerVisible} onClose={closeDrawer} />
    </Card>
  );
};

export default Companies;