import { http } from "../http-common";

const postDocument = (data: any, code: any) => {
    const formData = new FormData();
    formData.append('document', data); 
    return http.post(`/documents?code=${encodeURIComponent(code)}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data', 
        }
    });
};

const getDocument = (id: any) => {
    return http.get<any>(`/documents/${id}`);
};

const DocumentService = {
    postDocument,
    getDocument,
};

export default DocumentService;