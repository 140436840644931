import React, { useState } from 'react';
import { Modal, Button, Upload, Tooltip, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { openNotification } from '../../utils';
import { uploadTicketAttachment } from './Service/TicketService';

interface FileUploadModalProps {
  visible: boolean;
  onClose: () => void;
  ticketId: number;
  onUploadSuccess: () => void;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({ visible, onClose, ticketId, onUploadSuccess }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const handleUploadChange = ({ fileList }: any) => {
    const filteredFiles = fileList.filter((file: any) => {
      if (file.size / 1024 / 1024 > 1) {
        message.error(`${file.name} dosyası 1 MB boyutunu aşıyor.`);
        return false;
      }
      return true;
    });
    setFileList(filteredFiles);
  };

  const handleUpload = async () => {
    if (fileList.length > 0) {
      setLoading(true);
      try {
        const uploadPromises = fileList.map(file => uploadTicketAttachment(ticketId, file.originFileObj));
        await Promise.all(uploadPromises);
        openNotification('success', 'Başarılı', 'Dosyalar başarıyla yüklendi');
        onUploadSuccess();
        onClose();
      } catch (error) {
        openNotification('error', 'Hata', 'Dosyalar yüklenirken bir hata oluştu');
      } finally {
        setLoading(false);
        setFileList([]);
      }
    }
  };

  return (
      <Modal
        title="Yükle"
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button key="cancel" onClick={onClose}>
            İptal
          </Button>,
          <Button key="upload" type="primary" loading={loading} onClick={handleUpload}>
            Yükle
          </Button>,
        ]}
      >
        <Upload
          multiple
          fileList={fileList}
          beforeUpload={() => false}
          onChange={handleUploadChange}
        >
          <Tooltip title="Dosya Seç">
            <Button icon={<UploadOutlined />}>Dosya Seç</Button>
          </Tooltip>
        </Upload>
      </Modal>
  );
};

export default FileUploadModal;