import { Button, Tag, notification, Popconfirm, Table, Row, Col, Input, Card, Typography } from 'antd';
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import tinycolor from 'tinycolor2';
import { IStatusListResponse } from './Type/Status';
import StatusService from './Service/StatusService';
import CreateStatusModal from './Components/CreateStatusModal';
import StatusModal from './Components/StatusModal';
import { ColumnsType } from 'antd/es/table';
import TrashIcon from '../../assets/icons/trash.svg';
import PenIcon from '../../assets/icons/pen.svg';


const Statuses = () => {
    const [visible, setVisible] = useState(false);
    const [statuses, setStatuses] = useState<IStatusListResponse[]>([]);
    const [createVisible, setCreateVisible] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<any>();
    const [searchText, setSearchText] = useState('');
    const { Title } = Typography;

    useEffect(() => {
        getStatuses();
    }, []);

    const getStatuses = () => {
        StatusService.getAll()
            .then((response: any) => {
                let data: IStatusListResponse[] = response.data;
                setStatuses(data);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const handleCreate = () => {
        setCreateVisible(true);
    };

    const onClose = () => {
        setVisible(false);
        setCreateVisible(false);
    };


    const getCategoryText = (category: 'NEW' | 'PROCESSING' | 'COMPLETED'): string => {
        switch (category) {
            case 'NEW':
                return 'YENİ';
            case 'PROCESSING':
                return 'HAZIRLANIYOR';
            case 'COMPLETED':
                return 'TAMAMLANDI';
            default:
                return category;
        }
    };

    const getDefaultText = (defaultValue: boolean): string => {
        return defaultValue ? 'Evet' : 'Hayır';
    };

    const handleDetail = (status: any) => {
        setSelectedStatus(status);
        setVisible(true);
    };

    const handleDelete = (id: number) => {
        StatusService.remove(id)
            .then(() => {
                getStatuses();
                notification.success({
                    message: 'Başarılı',
                    description: 'Statü başarıyla silindi.',
                });
            })
            .catch((e: Error) => {
                notification.error({
                    message: 'Hata',
                    description: 'Silme sırasında bir hata oluştu.',
                });
                console.error('Silme sırasında hata:', e);
            });
    };

    const filteredStatuses = statuses.filter(status =>
        status.name.toLowerCase().includes(searchText)
    );

    const columns: ColumnsType<IStatusListResponse> = [

        {
            title: 'Adı',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: IStatusListResponse) => {
                const color = tinycolor(record.color);
                const transparentColor = color.setAlpha(0.1).toRgbString();
                const transparentColorBorder = color.setAlpha(0.5).toRgbString();

                return (
                    <Tag
                        style={{
                            color: record.color,
                            backgroundColor: transparentColor,
                            borderColor: transparentColorBorder,
                            borderRadius: '12px',
                            padding: '5px 10px',
                            fontSize: '14px',
                            display: 'inline-block',
                            minWidth: '100px',
                            textAlign: 'center',
                            fontWeight: 500,
                        }}
                    >
                        {text}
                    </Tag>
                );
            },
        },
        {
            title: 'Açıklama',
            dataIndex: 'description',
            key: 'description',
        },
        // {
        //     title: 'Kategori',
        //     dataIndex: 'category',
        //     key: 'category',
        //     render: (category: 'NEW' | 'PROCESSING' | 'COMPLETED') => getCategoryText(category),
        // },
        // {
        //     title: 'Varsayılan',
        //     dataIndex: 'default',
        //     key: 'default',
        //     render: (defaultValue: boolean) => getDefaultText(defaultValue),
        // },
        {
            title: 'İşlem',
            key: 'actions',
            align: 'right',
            render: (_, record: any) => (
                <div style={{ display: 'flex', justifyContent: 'right', gap: '10px' }}>
                    <Button
                        icon={<img src={PenIcon} alt="pen" className='icon' />}
                        shape="circle"
                        onClick={() => handleDetail(record)}
                        className='icon-button'
                    />
                    {/* <Popconfirm
                        title="Bu statü'yü silmek istediğinize emin misiniz?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button
                            icon={<img src={TrashIcon} alt="trash" className='icon' />}
                            shape="circle"
                            className='icon-button'
                        />
                    </Popconfirm> */}
                </div>
            ),
        },
    ];

    return (
        <div>
            <Card className='br-30px'>
                <Row justify="space-between" align={'middle'} style={{ marginBottom: 16 }}>
                    <Title style={{ margin: 0 }} level={4}>Statü Listesi</Title>
                    <Row>
                        <Input
                            placeholder="Ara"
                            prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
                            className="d-input d-input-form mr-16px d-input-filter"
                            style={{ width: 200, marginRight: 16 }}
                            onChange={e => setSearchText(e.target.value)}
                        />
                        {/* <Button type="primary" className='d-button dark-button width-auto p-20px' onClick={handleCreate} >
                                Statü Ekle
                            </Button> */}

                    </Row>
                </Row>

                <CreateStatusModal onClose={onClose} createVisible={createVisible} refreshTrigger={getStatuses} />
                <StatusModal refreshTrigger={getStatuses} selectedStatus={selectedStatus} onClose={onClose} visible={visible} />
                <Table scroll={{ x: 768 }} columns={columns} dataSource={filteredStatuses} rowKey="id" pagination={{ position: ['bottomCenter'] }} />
            </Card>
        </div>
    )
}

export default Statuses;