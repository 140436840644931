import { Button, Checkbox, ColorPicker, Form, Modal, Select, notification, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import StatusService from '../Service/StatusService';
import { IStatusRequest, IStatusResponse } from '../Type/Status';

interface StatusModalProps {
    selectedStatus: any;
    onClose: any;
    visible: any;
    refreshTrigger: any;
}

const { Option } = Select;

const StatusModal: React.FC<StatusModalProps> = ({ selectedStatus, onClose, visible, refreshTrigger }) => {
    const [form] = Form.useForm();
    const [color, setColor] = useState<string>('');

    useEffect(() => {
        if (selectedStatus) {
            getStatus();
        }
    }, [selectedStatus]);

    const getStatus = () => {
        StatusService.get(selectedStatus.id)
            .then((response: any) => {
                const data: IStatusResponse = response.data;
                form.setFieldsValue({
                    name: data.name,
                    description: data.description,
                    category: data.category,
                    color: data.color,
                    default: data.default,
                });
                setColor(data.color);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const handleCreateSubmit = (values: any): void => {
        const data: any = {
            name: values.name,
            category: values.category,
            description: values.description,
            color: color,
            isDefault: values.default || false,
        };

        StatusService.update(selectedStatus.id, data)
            .then(() => {
                refreshTrigger();
                form.resetFields();
                setColor('');
                notification.success({
                    message: 'Başarılı',
                    description: 'Statü başarıyla kaydedildi.',
                });
                onClose();
            })
            .catch((e: Error) => {
                notification.error({
                    message: 'Hata',
                    description: 'Kaydetme sırasında bir hata oluştu.',
                });
                console.error('Kaydetme sırasında hata:', e);
            });
    };

    return (
        <Modal
            title="Statü Düzenle"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <Form layout="vertical" form={form} onFinish={handleCreateSubmit}>
                <Form.Item label="Adı">
                    <Input.Group compact>
                        <Form.Item
                            name="name"
                            noStyle
                            rules={[{ required: true, message: 'Lütfen statü adını girin' }]}
                        >
                            <Input
                                className='d-input d-input-form'
                                style={{
                                    width: 'calc(100% - 48px)',
                                    borderRadius: '8px 0 0 8px',
                                }}
                                placeholder="Statü adı"
                            />
                        </Form.Item>
                        <Form.Item name="color" noStyle>
                            <ColorPicker
                                value={color}
                                onChange={(value, hex: string) => setColor(hex)}
                                format="hex"
                                style={{
                                    width: '48px',
                                    height: '48px',
                                    borderRadius: '0 8px 8px 0',
                                }}
                                trigger="click"
                            />
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
                <Form.Item name="description" label="Açıklama" rules={[{ message: 'Lütfen açıklama girin' }]}>
                    <Input.TextArea
                        placeholder="Statü Detay"
                        className='d-input d-input-form'
                    />
                </Form.Item>
                {/* <Form.Item name="category" label="Kategori" rules={[{ required: true, message: 'Lütfen bir kategori seçin' }]}>
                    <Select placeholder="Kategori seçin" className='d-input d-input-form'>
                        <Option value="NEW">YENİ</Option>
                        <Option value="PROCESSING">HAZIRLANIYOR</Option>
                        <Option value="COMPLETED">TAMAMLANDI</Option>
                    </Select>
                </Form.Item> */}
                {/* <Form.Item name="default" valuePropName="checked">
                    <Checkbox>Varsayılan Olarak Belirle</Checkbox>
                </Form.Item> */}
                <Form.Item>
                    <Row justify={'center'}>
                        <Button type="primary" htmlType="submit" block className='d-button dark-button width-auto'>
                            Kaydet
                        </Button>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default StatusModal;