import { http } from "../http-common";

const postImage = (data: any,code:any) => {
    return http.post<any>(`/images${code}`, data);
};

const getImage = (id: any) => {
    return http.get<any>(`/images/${id}`);
};

const ImageService = {
    postImage,
    getImage,
};

export default ImageService;