import React, { useState, useEffect } from 'react';
import { Modal, Input, Form, Button, Select, Row, Col } from 'antd';
import ToolService from '../../services/ToolService';
import OfferingService from '../../services/OfferingService';

const { Option } = Select;

const ParameterModal: React.FC<any> = ({
    visible,
    onClose,
    onSave,
    initialData,
    handleSave,
}) => {
    const [form] = Form.useForm();
    const isEdit = Boolean(initialData);
    const [tools, setTools] = useState<any[]>([]);
    const [offerings, setOfferings] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [toolResponse, offeringResponse] = await Promise.all([
                    ToolService.getAllTools(),
                    OfferingService.getAllOfferings(),
                ]);
                setTools(toolResponse.data);
                setOfferings(offeringResponse.data);
            } catch (error) {
                console.error('Veri çekilirken hata oluştu:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (visible) {
            if (isEdit && initialData) {
                const { offering, toolId, ...rest } = initialData;
                form.setFieldsValue({
                    ...rest,
                    offeringId: offering?.id,
                    toolId: toolId,
                });
            } else {
                form.resetFields();
            }
        }
    }, [visible, isEdit, initialData, form]);

    const handleFinish = (values: any) => {
        const payload = { ...initialData, ...values };
        onSave(payload, isEdit ? 'edit' : 'add');
        if (handleSave) {
            handleSave();
        }
        form.resetFields();
        onClose();
    };

    const handleClose = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={isEdit ? 'Parametre Düzenle' : 'Yeni Parametre Ekle'}
            visible={visible}
            onCancel={handleClose}
            footer={null}
            destroyOnClose
        >
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Form.Item
                    name="name"
                    label="Adı"
                    rules={[{ required: true, message: 'Lütfen parametre adı giriniz.' }]}
                >
                    <Input placeholder="Adı" />
                </Form.Item>
                <Form.Item name="description" label="Açıklama"
                 rules={[
                    { max: 1000, message: 'Açıklama en fazla 1000 karakter olabilir' },
                ]}>
                    <Input.TextArea placeholder="Açıklama" />
                </Form.Item>
                <Form.Item
                    name="offeringId"
                    label="Grup"
                    rules={[{ required: true, message: 'Lütfen bir grup seçiniz.' }]}
                >
                    <Select placeholder="Grup Seçiniz" allowClear>
                        {offerings.map((offering) => (
                            <Option key={offering.id} value={offering.id}>
                                {offering.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="toolId" label="Bileşen">
                    <Select placeholder="Bileşen Seçiniz" allowClear>
                        {tools.map((tool) => (
                            <Option key={tool.id} value={tool.id}>
                                {tool.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Fiyat"
                    name="price"
                    rules={[{ required: true, message: 'Lütfen fiyat giriniz' }]}
                >
                    <Input
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={(event) => {
                            const value = event.target.value.replace(/[^0-9]/g, '');
                            event.target.value = value;
                        }}
                    />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Saha Kontrol Dk"
                            name="fieldControlTime"
                            rules={[{ required: true, message: 'Lütfen süre giriniz' }]}
                        >
                            <Input
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    const value = event.target.value.replace(/[^0-9]/g, '');
                                    event.target.value = value;
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Rapor Hazırlık Dk"
                            name="reportPreparationTime"
                            rules={[{ required: true, message: 'Lütfen süre giriniz' }]}
                        >
                            <Input
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    const value = event.target.value.replace(/[^0-9]/g, '');
                                    event.target.value = value;
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Row justify={'center'}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="d-button dark-button width-auto"
                        >
                            Kaydet
                        </Button>
                    </Row>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ParameterModal;
