import React from 'react'
import Notes from '../../modules/Note/Notes'

const Video = () => {
  return (
    <div>
        <Notes entityName={'Video'} entityId={1}></Notes>
    </div>
  )
}

export default Video