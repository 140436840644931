import { Card, Col, Row, Button, Table, Select, Dropdown, Menu, Input, Avatar, Tooltip, message, DatePicker } from 'antd';
import { CalendarOutlined, WalletOutlined, DatabaseOutlined, DownOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import EarningIcon from '../../assets/icons/earning.svg';
import NoteIcon from '../../assets/icons/note.svg';
import ValletIcon from '../../assets/icons/vallet2.svg';
import { formatDate, formatToTL } from '../../utils';
import ProposalService from '../../services/ProposalService';
import { useParams } from 'react-router-dom';
import CompanyService from '../../services/CompanyService';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import PaymentModal from '../ProjectFollowUp/PaymentModal';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ProposalsList = () => {
    const [activeTab, setActiveTab] = useState('gelir'); // 'gelir' = Bekleyen Ödemeler, 'odemeler' = Ödemeler
    const [currentPage, setCurrentPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [filteredProposals, setFilteredProposals] = useState<any[]>([]);
    const [searchText, setSearchText] = useState(''); // Yeni: Arama metni için state
    const [loading, setLoading] = useState(false); // Yeni: Loading state
    const [dateRange, setDateRange] = useState<[string | null, string | null]>([null, null]); // Tarih aralığı
    const [selectedProposalId, setSelectedProposalId] = useState<number | null>(null);
    const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
    const [paymentModalType, setPaymentModalType] = useState<any>();

    const logoCache = new Map();
    const pendingRequests = new Map();

    const { id } = useParams<{ id: string }>();
    const userId = id ?? localStorage.getItem('id');

    const fetchProposals = useCallback(async (params: any) => {
        setLoading(true);
        try {
            params.statuIds = [4, 7].join(',');

            let response: any = await ProposalService.getMe(params);
            const enrichedProposals = await enrichProposalsWithLogos(response.data.content);

            setFilteredProposals(enrichedProposals);
            setTotalItems(response.data.totalElements);
        } catch (error) {
            console.error('Teklif verileri alınırken hata oluştu:', error);
            message.error('Teklif verileri alınırken bir hata oluştu.');
        } finally {
            setLoading(false);
        }
    }, []);

    // Debounce edilmiş fetchProposals fonksiyonunu oluşturuyoruz
    const debouncedFetchProposals = useCallback(
        debounce((params: any) => {
            fetchProposals(params);
        }, 300), // 300ms debounce süresi
        [fetchProposals]
    );

    // useEffect ile fetchProposals'ı çağırıyoruz
    useEffect(() => {
        let isPaid;
        if (activeTab === 'gelir') {
            isPaid = false; 
        } else if (activeTab === 'odemeler') {
            isPaid = true;
        }

        const params = {
            page: currentPage,
            size: pageSize,
            isPaid: isPaid, // activeTab'e bağlı olarak isPaid değerini ayarla
            company: searchText, // Arama metnini ekle
            accountId: userId ?? '',
            startDate: dateRange[0], // Tarih aralığı başlangıcı
            endDate: dateRange[1],   // Tarih aralığı bitişi
            sortField: 'createdAt',
            sortDirection: 'DESC',
        };

        debouncedFetchProposals(params);

        // Cleanup fonksiyonu ile debounce'i iptal ediyoruz
        return () => {
            debouncedFetchProposals.cancel();
        };
    }, [activeTab, currentPage, pageSize, userId, searchText, dateRange, debouncedFetchProposals]);

    const getMenu = (record: any) => (
        <Menu>
            <Menu.Item
                key="view"
                onClick={() => openPaymentModal(record.id, 'view')}
            >
                Ödeme Görüntüle
            </Menu.Item>
        </Menu>
    );

    const openPaymentModal = (proposalId: number, type: any) => {
        setSelectedProposalId(proposalId);
        setIsPaymentModalVisible(true);
        setPaymentModalType(type);
    };

    const enrichProposalsWithLogos = async (proposals: any[]) => {
        return Promise.all(
            proposals.map(async (proposal) => {
                const { company } = proposal;
                if (company.imageId) {
                    if (logoCache.has(company.imageId)) {
                        return { ...proposal, company: { ...company, logoUrl: logoCache.get(company.imageId) } };
                    } else if (pendingRequests.has(company.imageId)) {
                        await pendingRequests.get(company.imageId);
                        return { ...proposal, company: { ...company, logoUrl: logoCache.get(company.imageId) } };
                    } else {
                        // Logo yüklenmemişse istek atıyoruz
                        const logoPromise = CompanyService.getImage(company.imageId)
                            .then((logoResponse) => {
                                const logoUrl = logoResponse.data.preSignUrl;
                                logoCache.set(company.imageId, logoUrl); // Logo'yu cache'e kaydediyoruz
                                pendingRequests.delete(company.imageId); // İstek tamamlandığında pendingRequest'ten kaldırıyoruz
                                return logoUrl;
                            })
                            .catch((error) => {
                                console.error("Logo yüklenirken hata oluştu:", error);
                                pendingRequests.delete(company.imageId); // Hata olduğunda istekten kaldırıyoruz
                                return null;
                            });

                        pendingRequests.set(company.imageId, logoPromise);

                        const logoUrl = await logoPromise;
                        return { ...proposal, company: { ...company, logoUrl } };
                    }
                }
                return proposal;
            })
        );
    };

    const onDateChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
        if (dates) {
            setDateRange([
                dates[0]?.format('YYYY-MM-DD') ?? null,
                dates[1]?.format('YYYY-MM-DD') ?? null,
            ]);
        } else {
            setDateRange([null, null]);
        }
    };

    const columns: any = [
        {
            title: 'Firma Adı',
            dataIndex: ['company', 'name'],
            key: 'companyName',
            render: (text: string, record: any) => {
                const displayText = text?.length > 28 ? `${text.substring(0, 28)}...` : text;
                return (
                    <div className="d-flex align-items-center">
                        <div className='avatar-image-logo' style={{ marginRight: 10 }}>
                            {record?.company?.logoUrl ? (
                                <img
                                    src={record?.company?.logoUrl}
                                    alt={record?.company?.name}
                                    className='avatar-image-logo'
                                    style={{ borderRadius: '50%' }}
                                />
                            ) : (
                                <Avatar className='avatar-image-logo' style={{ backgroundColor: '#f0f0f0', color: '#000' }}>
                                    {record?.company?.name?.charAt(0)?.toUpperCase()}
                                </Avatar>
                            )}
                        </div>
                        <Tooltip title={text?.toUpperCase()}>
                            <span>{displayText?.toUpperCase()}</span>
                        </Tooltip>
                    </div>
                )
            },
        },
        {
            title: 'Teklif No',
            dataIndex: 'proposalNumber',
            key: 'proposalNumber',
            render: (proposalNumber: string) => (
                <a href={`#`} style={{ color: '#4180f6' }}>
                    {proposalNumber}
                </a>
            ),
        },
        {
            title: 'Sözleşme Tarihi',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date: string) => formatDate(date),
        },
        {
            title: 'Gelir Paylaşımı',
            dataIndex: 'maxDiscountPrice',
            key: 'maxDiscountPrice',
            render: (maxDiscountPrice: number) => `${formatToTL(maxDiscountPrice)}`,
        },
        {
            title: 'İşlemler',
            key: 'actions',
            align: 'right',
            render: (text: any, record: any) => (
                <Dropdown overlay={getMenu(record)} trigger={['hover']}>
                    <Button type='default' className='d-button action-button width-auto'>
                        Detay
                    </Button>
                </Dropdown>
            ),
        }
    ];

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        // searchText state'i güncellendiğinde useEffect çalışacak ve fetchProposals çağrılacak (debounce edilmiş)
    };

    return (
        <Row gutter={16} style={{ marginTop: '24px' }}>
            <Col span={24}>
                <Card bordered={false} className='br-30px'>
                    <Row justify="space-between" style={{ marginBottom: '16px' }} gutter={[16, 16]}>
                        <div>
                            <Button
                                type={activeTab === 'gelir' ? 'primary' : 'default'}
                                onClick={() => {
                                    setActiveTab('gelir');
                                    setCurrentPage(0); // Sayfayı sıfırla
                                    setPageSize(10);   // Sayfa boyutunu sıfırla (isteğe bağlı)
                                }}
                                style={{ marginRight: '8px' }}
                                className='d-button tab-button width-auto'
                            >
                                Bekleyen Ödemeler
                            </Button>
                            <Button
                                type={activeTab === 'odemeler' ? 'primary' : 'default'}
                                onClick={() => {
                                    setActiveTab('odemeler');
                                    setCurrentPage(0); // Sayfayı sıfırla
                                    setPageSize(10);   // Sayfa boyutunu sıfırla (isteğe bağlı)
                                }}
                                className='d-button tab-button width-auto'
                            >
                                Ödemeler
                            </Button>
                        </div>
                        <Row>
                            <RangePicker onChange={onDateChange} className='d-input-filter mr-10px' />
                            <Input
                                placeholder="Ara"
                                prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
                                className="d-input d-input-form mr-16px d-input-filter"
                                value={searchText}
                                onChange={handleSearchChange}
                                style={{ width: 200 }}
                            />
                        </Row>
                    </Row>

                    {activeTab === 'gelir' && (
                        <Table
                            scroll={{ x: 768 }}
                            columns={columns}
                            dataSource={filteredProposals}
                            rowKey="proposalNumber"
                            loading={loading}
                            pagination={{
                                current: currentPage + 1,
                                pageSize: pageSize,
                                total: totalItems,
                                onChange: (page, pageSize) => {
                                    setCurrentPage(page - 1);
                                    setPageSize(pageSize || 10);
                                },
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '30'],
                            }}
                        />
                    )}
                    {activeTab === 'odemeler' && (
                        <Table
                            scroll={{ x: 768 }}
                            columns={columns}
                            dataSource={filteredProposals}
                            rowKey="proposalNumber"
                            loading={loading}
                            pagination={{
                                current: currentPage + 1,
                                pageSize: pageSize,
                                total: totalItems,
                                onChange: (page, pageSize) => {
                                    setCurrentPage(page - 1);
                                    setPageSize(pageSize || 10);
                                },
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '30'],
                            }}
                            style={{ marginTop: '16px' }}
                        />
                    )}
                </Card>
            </Col>
            {isPaymentModalVisible && selectedProposalId && (
                <PaymentModal
                    visible={isPaymentModalVisible}
                    onClose={() => setIsPaymentModalVisible(false)}
                    proposalId={selectedProposalId}
                    mode={paymentModalType}

                />
            )}
        </Row>
    );
};

export default ProposalsList;