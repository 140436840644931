import React, { useState } from 'react';
import { Drawer, Button, Form, Input, Select, Spin, Upload, Typography, Row, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { createTicket, uploadTicketAttachment } from './Service/TicketService';
import { TicketCreateUpdate } from './Type/Ticket';
import { openNotification } from '../../utils';

const { Option } = Select;
const { Title } = Typography;

interface NewTicketDrawerProps {
  visible: boolean;
  onClose: () => void;
  onSave: () => void;
  initialValues: Partial<TicketCreateUpdate>;
}

const NewTicketDrawer: React.FC<NewTicketDrawerProps> = ({ visible, onClose, onSave, initialValues }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);

  const onFinish = async (values: TicketCreateUpdate) => {
    setLoading(true);
    try {
      const newTicketId = await createTicket(values);
      if (fileList.length > 0) {
        await uploadTicketAttachment(newTicketId, fileList[0].originFileObj);
      }
      openNotification('success', 'Başarılı', 'Destek talebi başarıyla oluşturuldu');
      onSave();
      onClose();
      form.resetFields();
      setFileList([]);
    } catch (error) {
      openNotification('error', 'Hata', 'Destek talebi oluşturulurken bir hata oluştu');
    } finally {
      setLoading(false);
    }
  };

  const handleUploadChange = ({ fileList }: any) => {
    const filteredFiles = fileList.filter((file: any) => {
      if (file.size / 1024 / 1024 > 1) {
        message.error(`${file.name} dosyası 1 MB boyutunu aşıyor.`);
        return false;
      }
      return true;
    });
    setFileList(filteredFiles);
  };

  return (
    <Drawer
      title="Destek"
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <div className='ticket-container'>
        <Spin spinning={loading}>
          <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
            <Form.Item name="subject" label="Konu" rules={[{ required: true, message: 'Lütfen konuyu girin' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Açıklama" rules={[{ required: true, message: 'Lütfen açıklamayı girin' }]}>
              <Input.TextArea />
            </Form.Item>
            <Form.Item name="priority" label="Öncelik" rules={[{ required: true, message: 'Lütfen önceliği seçin' }]}>
              <Select>
                <Option value="LOW">Düşük</Option>
                <Option value="MEDIUM">Orta</Option>
                <Option value="HIGH">Yüksek</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Dosya Ekle">
              <Upload
                fileList={fileList}
                beforeUpload={() => false}
                onChange={handleUploadChange}
              >
                <Button icon={<UploadOutlined />}>Yükle</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Row justify={'end'} style={{ marginTop: 16 }}>
                <Button type="primary" htmlType="submit" className='dark-button' loading={loading}>
                  Kaydet
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </div>

    </Drawer>
  );
};

export default NewTicketDrawer;