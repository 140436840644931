import React, { useEffect, useState } from 'react';
import { Tabs, Button, Table, Tag, Space, Card } from 'antd';
import { Link } from 'react-router-dom';
import Statuses from '../../modules/Status/Statuses';
import { RoleList } from '../Role/RoleList';
import DiscountRate from '../DiscountRate/DiscountRate';
import ToolList from '../../components/Tools/ToolList';
import ParameterList from '../../components/Parameters/ParameterList';
import OfferingList from '../../components/Offerings/OfferingList';
import ProposalListWithDetails from '../../components/ProjectFollowUp/ProposalList';
import ProposalList from '../../components/Proposal/ProposalList';

const { TabPane } = Tabs;

const ProjectFollowUp: React.FC = () => {
    const [activeKey, setActiveKey] = useState('1');
    const [roles, setRoles] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleTabChange = (key: string) => {
        setActiveKey(key);
    };

    useEffect(() => {
        let storedRoles: any = localStorage.getItem('roles');
        let parsedRoles = JSON.parse(storedRoles);
        setRoles(parsedRoles);
        setIsLoading(false);
    }, []);


    return (
        <div className='mb-60px'>
            {isLoading ? <div>Loading...</div> :
                roles && roles.some((role: any) => role.name === 'ADMIN') ? (
                    <Tabs activeKey={activeKey} onChange={handleTabChange} className="custom-tabs" tabBarStyle={{ borderBottom: 'none' }}>
                        <TabPane
                            tab={
                                <Button type={activeKey === '1' ? 'primary' : 'default'} className='d-button tab-button'>Teklifler</Button>
                            }
                            key="1"
                        >
                            <ProposalListWithDetails defaultStatusIds={[3, 6, 10, 11]} type={1} />

                        </TabPane>
                        <TabPane
                            tab={
                                <Button type={activeKey === '2' ? 'primary' : 'default'} className='d-button tab-button'>Planlama Talepleri</Button>
                            }
                            key="2"
                        >
                            <ProposalListWithDetails defaultStatusIds={[6, 5]} type={2} />
                        </TabPane>
                        <TabPane
                            tab={
                                <Button type={activeKey === '3' ? 'primary' : 'default'} className='d-button tab-button'>Rapor & Fatura</Button>
                            }
                            key="3"
                        >
                            <ProposalListWithDetails defaultStatusIds={[5]} type={3} />
                        </TabPane>
                        <TabPane
                            tab={
                                <Button type={activeKey === '4' ? 'primary' : 'default'} className='d-button tab-button'>Tamamlanan Projeler</Button>
                            }
                            key="4"
                        >
                            <ProposalListWithDetails defaultStatusIds={[4]} type={4} />
                        </TabPane>
                        <TabPane
                            tab={
                                <Button type={activeKey === '5' ? 'primary' : 'default'} className='d-button tab-button'>Ödemeler</Button>
                            }
                            key="5"
                        >
                            <ProposalListWithDetails defaultStatusIds={[4]} type={5} />
                        </TabPane>
                    </Tabs>
                ) : (
                    <ProposalList defaultStatusIds={[4, 5, 6]} type={2} />
                )
            }</div>
    );
};

export default ProjectFollowUp;