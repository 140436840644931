import React from 'react'
import CompanyDetailCard from '../../components/Companies/CompanyDetailCard'
import Notes from '../../components/Companies/Notes'
import { Col, Row, Space } from 'antd'
import Proposals from '../../components/Companies/Proposals'
import './Company.scss';

const CompanyDetail = () => {
    return (
        <div style={{paddingBottom:120}}>
            <Row gutter={[24,24]}>
                <Col md={16} sm={24} xs={24}>
                    <Space direction="vertical" size={32} style={{width:'100%' }}>
                        <CompanyDetailCard />
                        <Proposals />
                    </Space>
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Notes />
                </Col>
            </Row>

        </div>
    )
}

export default CompanyDetail