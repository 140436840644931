import React, { useState, useEffect } from 'react';
import { Button, InputNumber, Modal, Table, Popconfirm } from 'antd';
import { ColumnsType } from 'antd/es/table';
import DiscountAttributeService from '../../services/DiscountedAttributeService';
import TrashIcon from '../../assets/icons/trash.svg';
import PenIcon from '../../assets/icons/pen.svg';
import { SaveOutlined } from '@ant-design/icons'; // Ant Design kaydet icon'u
import { openNotification } from '../../utils';

interface DiscountCriteriaModalProps {
    visible: boolean;
    onClose: () => void;
    attributeId: number | null;
}

interface DiscountAttribute {
    id: number;
    quantity: number;
    discountRate: number;
}

const DiscountCriteriaModal: React.FC<DiscountCriteriaModalProps> = ({ visible, onClose, attributeId }) => {
    const [quantity, setQuantity] = useState<number>(0);
    const [discountRate, setDiscountRate] = useState<number>(0);
    const [criteriaList, setCriteriaList] = useState<DiscountAttribute[]>([]);
    const [selectedCriteria, setSelectedCriteria] = useState<DiscountAttribute | null>(null);

    useEffect(() => {
        if (attributeId !== null) {
            DiscountAttributeService.getAllByAttributeId(attributeId)
                .then((response) => {
                    setCriteriaList(response.data);
                })
                .catch((error) => {
                    console.error("Kriterler yüklenirken hata:", error);
                });
        }
    }, [attributeId]);

    const handleEdit = (record: DiscountAttribute) => {
        setSelectedCriteria(record); // Düzenlenen kriteri set et
        setQuantity(record.quantity); // Yukarıdaki input alanına veri doldur
        setDiscountRate(record.discountRate); // Yukarıdaki input alanına veri doldur
    };

    const handleSave = () => {
        if (attributeId === null) {
            console.error("Attribute ID null olamaz.");
            return;
        }

        const data = { quantity, discountRate, attributeId };

        if (selectedCriteria) {
            // Edit modunda, mevcut veriyi güncelle
            DiscountAttributeService.update(selectedCriteria.id, data)
                .then(() => {
                    setCriteriaList((prev) =>
                        prev.map((item) => 
                            item.id === selectedCriteria.id 
                            ? { ...data, id: selectedCriteria.id } 
                            : item
                        )
                    );
                    openNotification('success', 'Başarılı', 'Kriter başarıyla güncellendi.');
                    setSelectedCriteria(null); // Edit modundan çık
                })
                .catch((error) => console.error('Kriter güncellenirken hata:', error));
        } else {
            // Yeni kayıt ekleme
            DiscountAttributeService.create(data)
                .then((response) => {
                    setCriteriaList((prev) => [...prev, response.data]);
                    openNotification('success', 'Başarılı', 'Kriter başarıyla eklendi.');
                })
                .catch((error) => console.error('Kriter eklenirken hata:', error));
        }

        setQuantity(0); // Alanları sıfırla
        setDiscountRate(0); // Alanları sıfırla
    };

    const handleDelete = (id: number) => {
        DiscountAttributeService.remove(id)
            .then(() => {
                setCriteriaList((prev) => prev.filter((item) => item.id !== id));
                openNotification('success', 'Başarılı', 'Kriter başarıyla silindi.');
            })
            .catch((error) => console.error('Kriter silinirken hata:', error));
    };

    const columns: ColumnsType<DiscountAttribute> = [
        {
            title: 'Adet',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (quantity: number) => `${quantity} ve üzeri`,
        },
        {
            title: 'İndirim Oranı',
            dataIndex: 'discountRate',
            key: 'discountRate',
            render: (_: any, record) => `${record.discountRate} %`,
        },
        {
            title: 'İşlem',
            key: 'action',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button
                        shape="circle"
                        icon={<img src={PenIcon} alt="pen" className="icon" />}
                        onClick={() => handleEdit(record)}
                        className="icon-button"
                    />
                    <Popconfirm
                        title="Bu kriteri silmek istediğinize emin misiniz?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button
                            shape="circle"
                            icon={<img src={TrashIcon} alt="trash" className="icon" />}
                            className="icon-button"
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <Modal
            title="Hesap Kriteri Düzenle"
            visible={visible}
            onCancel={onClose}
            footer={null}
        >
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px', alignItems: 'center' }}>
                <div>
                    <span>Adet</span>
                    <InputNumber
                        min={0}
                        value={quantity}
                        onChange={(value: number | null) => setQuantity(value ?? 0)}
                        style={{ width: '100%' }}
                    />
                </div>
                <div>
                    <span>İndirim Oranı</span>
                    <InputNumber
                        addonBefore="%"
                        min={0}
                        max={100}
                        value={discountRate}
                        onChange={(value: number | null) => setDiscountRate(value ?? 0)}
                        style={{ width: '100%' }}
                    />
                </div>

                <Button key="submit" type="primary" className="d-button dark-button width-auto mt-16px" onClick={handleSave}>
                    {selectedCriteria ? 'Güncelle' : 'Kaydet'}
                </Button>
            </div>
            <Table columns={columns} dataSource={criteriaList} rowKey="id" pagination={false} />
        </Modal>
    );
};

export default DiscountCriteriaModal;