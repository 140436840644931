import React, { useState, useEffect } from 'react';
import { Modal, Button, DatePicker, Typography, Form, Row } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import './Company.scss';
import { openNotification } from '../../utils';
import ProposalService from '../../services/ProposalService';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/tr_TR';
import 'dayjs/locale/tr';

dayjs.locale('tr');
const { Text } = Typography;
const { RangePicker } = DatePicker;

const PlanningModal: React.FC<any> = ({ proposalId, isCustomer = false, isModalVisible, setIsModalVisible }: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [isNull, setIsNull] = useState<any>(true);

    useEffect(() => {
        if (isModalVisible && proposalId) {
            fetchProposalDates();
        }
    }, [isModalVisible, proposalId]);

    const fetchProposalDates = async () => {
        try {
            const response = await ProposalService.get(proposalId);
            if (response?.data) {
                const proposal = response.data;
                if (!isCustomer) {
                    setStartDate(proposal.requestedPlanningStartDate ? dayjs(proposal.requestedPlanningStartDate) : null);
                    setEndDate(proposal.requestedPlanningEndDate ? dayjs(proposal.requestedPlanningEndDate) : null);
                    if (proposal.requestedPlanningStartDate == null) {
                        setIsNull(true)
                    } else {
                        setIsNull(false)
                    }
                } else {
                    if (proposal.approvedPlanningStartDate != null) {
                        setStartDate(proposal.approvedPlanningStartDate ? dayjs(proposal.approvedlanningStartDate) : null);
                        setEndDate(proposal.approvedPlanningEndDate ? dayjs(proposal.approvedPlanningEndDate) : null);
                    } else if (proposal.approvedPlanningStartDate != null) {
                        setStartDate(proposal.requestedPlanningStartDate ? dayjs(proposal.requestedPlanningStartDate) : null);
                        setEndDate(proposal.requestedPlanningEndDate ? dayjs(proposal.requestedPlanningEndDate) : null);
                    } else {
                        setStartDate(null);
                        setEndDate(null);
                    }
                    if (proposal.approvedPlanningStartDate == null) {
                        setIsNull(true)
                    } else {
                        setIsNull(false)
                    }
                }

            } else {
                setStartDate(null);
                setEndDate(null);
                setIsNull(false)
            }

        } catch (error) {
            openNotification('error', 'Hata', 'Planlama tarihleri getirilemedi.');
            console.error(error);
        }
    };


    const showModal = () => {
        setStartDate(null);
        setEndDate(null);
        setIsModalVisible(true);
    };

    useEffect(() => {
        if (isModalVisible) {
            showModal();
        }
    }, [isModalVisible])


    const handleCancel = () => {
        setIsModalVisible(false);
        setStartDate(null);
        setEndDate(null);
    };

    const updateProposal = async () => {
        setIsLoading(true);

        if (!startDate || !endDate) {
            openNotification('warning', 'Uyarı', 'Lütfen planlama tarih aralığını seçiniz.');
            setIsLoading(false);
            return;
        }

        let proposalData: any = {
            approvedPlanningStartDate: dayjs(startDate).add(3, 'hour').toISOString(),
            approvedPlanningEndDate: dayjs(endDate).add(3, 'hour').toISOString(),
            statusId: 5,
        };

        if (isCustomer) {
            proposalData = {
                requestedPlanningStartDate: dayjs(startDate).add(3, 'hour').toISOString(),
                requestedPlanningEndDate: dayjs(endDate).add(3, 'hour').toISOString(),
            };
        }

        try {
            await ProposalService.update(proposalId, proposalData);
            if (isNull) {
                openNotification('success', 'Başarılı', 'Planlama talebi başarıyla oluşturuldu.');
            } else {
                openNotification('success', 'Başarılı', 'Planlama tarihleri başarıyla güncellendi.');
            }
            setStartDate(null);
            setEndDate(null);
        } catch (error) {
            openNotification('error', 'Hata', 'Planlama tarihleri güncellenirken bir hata oluştu.');
            console.error(error);
        }
        setIsLoading(false);
        setIsModalVisible(false);
    };

    // Bugünden önceki tarihleri seçilemez hale getiriyoruz
    const disabledDate = (current: any) => {
        return current && current < dayjs().startOf('day');
    };

    const handleDateChange = (dates: any) => {
        if (dates && dates.length === 2) {
            setStartDate(dates[0]);
            setEndDate(dates[1]);
        } else {
            setStartDate(null);
            setEndDate(null);
        }
    };

    return (
        <>
            <Modal
                title={<Text className="modal-title">Planlama Talebi Oluştur</Text>}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                className="planlama-modal"
            >
                <Form layout="vertical">
                    <Form.Item label="Planlanan Tarih">
                        <RangePicker
                            locale={locale}
                            placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
                            format="DD/MM/YYYY HH:mm"
                            showTime={{ format: 'HH:mm' }}
                            suffixIcon={<CalendarOutlined />}
                            value={startDate && endDate ? [startDate, endDate] : null}
                            style={{ width: '100%' }}
                            className="date-picker-input"
                            onChange={handleDateChange}
                            disabledDate={disabledDate}
                        />
                    </Form.Item>
                    <Row justify={'center'}>
                        <Button type="primary" loading={isLoading} onClick={updateProposal} className="d-button dark-button width-auto">
                            Kaydet
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default PlanningModal;