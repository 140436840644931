import React, { useState, useEffect } from "react";
import { Drawer, Form, Input, Button, Select, Upload, Row, Col, Steps, Typography } from "antd";
import { LoadingOutlined, CheckCircleOutlined, PlusOutlined } from "@ant-design/icons";
import CompanyService from "../../services/CompanyService";
import "./Company.scss";
import { filterOption, openNotification } from "../../utils";
import ImageUpload from "../Uploads/ImageUpload";

interface CompanyDrawerProps {
    visible: boolean;
    onClose: () => void;
}

const CompanyCreate: React.FC<CompanyDrawerProps> = ({ visible, onClose }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [form] = Form.useForm();
    const [imageId, setImageId] = useState<number | null>(null);
    const [cities, setCities] = useState<{ id: number, name: string }[]>([]);
    const [step1Data, setStep1Data] = useState<any>({});
    const [step2Data, setStep2Data] = useState<any>({});
    const [step3Data, setStep3Data] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const cityIdsToShow = [2170, 2195, 2150, 2167, 2151, 2176, 2163, 2218, 2175];
    const [districts, setDistricts] = useState<{ id: number; name: string }[]>([]);
    const [selectedCityId, setSelectedCityId] = useState<number | null>(null);

    const [controlDistricts, setControlDistricts] = useState<{ id: number; name: string }[]>([]);
    const [controlSelectedCityId, setControlSelectedCityId] = useState<number | null>(null);

    const { Title } = Typography;
    useEffect(() => {
        const getState = async () => {
            const response = await CompanyService.getStates(225);
            setCities(response.data);
        };
        getState();
    }, []);

    const next = () => {
        form.validateFields().then(values => {
            if (currentStep === 0) {
                setStep1Data({ ...values, logo: imageId });
                console.log("Step1Data:", { ...values, logo: imageId });
            } else if (currentStep === 1) {
                setStep2Data(values);
            }
            setCurrentStep(currentStep + 1);
        }).catch((info) => {
            console.log("Validation Failed:", info);
        });
        setTimeout(() => {
            console.log(step1Data);
        }, 1000);
    };



    const onFinish = async () => {
        try {
            setLoading(true);
            const values = await form.validateFields();
            setStep3Data(values);

            const companyData = {
                name: step1Data.name,
                taxOffice: step1Data.taxOffice,
                taxNumber: step1Data.taxNumber,
                email: step1Data.email.toLowerCase(),
                phoneNumber: step1Data.phoneNumber,
                kepAddress: step1Data.kepAddress,
                imageId: imageId,
                companyAddresses: [
                    {
                        addressLine1: step1Data.address,
                        addressLine2: "",
                        city: step1Data.city,
                        state: step1Data.district,
                        postalCode: "",
                        country: "Turkey",
                        type: "BILLING",
                        identityNumber: "",
                        phone: step1Data.phoneNumber,
                        taxNumber: step1Data.taxNumber,
                        companyName: step1Data.name,
                        taxOffice: step1Data.taxOffice,
                        title: step2Data.name,
                        fullName: "",
                    },
                    {
                        addressLine1: step2Data.controlAddress,
                        addressLine2: "",
                        city: step2Data.controlCity,
                        state: step2Data.controlDistrict,
                        postalCode: "",
                        country: "Turkey",
                        type: "CONTROL",
                        identityNumber: "",
                        phone: "",
                        taxNumber: "",
                        companyName: "",
                        taxOffice: "",
                        title: step2Data.controlAddressTitle || "Kontrol Adresi 1",
                        fullName: "",
                    }
                ],
                authorizedPersons: [
                    {
                        firstName: values.authorizedPersonFirstName,
                        lastName: values.authorizedPersonLastName,
                        position: values.authorizedPersonPosition,
                        email: values.authorizedPersonEmail.toLowerCase(),
                        phoneNumber: values.authorizedPersonPhoneNumber,
                    }
                ],
            };

            console.log(companyData); // Loglama işlemi

            await CompanyService.create(companyData);

            form.resetFields();
            setCurrentStep(0);
            openNotification('success', 'Başarılı', 'Firma Eklendi');
            onClose();
            setLoading(false);
        } catch (error) {
            console.error("Validation Failed:", error);
            openNotification('error', 'Hata', 'Firma Eklenemedi');
            setLoading(false);
        }
    };

    const steps = [
        {
            title: (
                <span className="text-start">
                    Adım 1 <br /> Firma Bilgileri
                </span>
            ),
            description: 'Tamamlandı',
            status: currentStep > 0 ? 'Tamamlandı' : 'Bekliyor',
        },
        {
            title: (
                <>
                    Adım 2 <br /> Kontrol Adresi
                </>
            ),
            description: 'Devam ediyor',
            status: currentStep > 1 ? 'Tamamlandı' : currentStep === 1 ? 'Devam ediyor' : 'Bekliyor',
        },
        {
            title: (
                <>
                    Adım 3 <br /> Yetkili Bilgileri
                </>
            ),
            description: 'Bekliyor',
            status: currentStep === 2 ? 'Devam ediyor' : 'Bekliyor',
        },
    ];



    const onStepChange = (nextStep: number) => {
        if (nextStep <= currentStep) {
            setCurrentStep(nextStep);
        }
    };



    const getDistricts = async (stateId: number) => {
        try {
            const response = await CompanyService.getDistricts(stateId);
            setDistricts(response.data);
            setControlDistricts(response.data);
        } catch (error) {
            console.error("İlçeler alınırken hata oluştu:", error);
        }
    };

    const handleCityChange = (value: number) => {
        const city = cities.find((c) => c.id === value);
        setSelectedCityId(value);
        form.setFieldsValue({ city: city?.name, district: undefined });
        setDistricts([]);
        if (value) {
            getDistricts(value);
        }
    };

    const handleDistrictChange = (value: string) => {
        form.setFieldsValue({ controlDistrict: value });
    };

    const handleControlCityChange = (value: number) => {
        console.log(value);
        const city = cities.find((c) => c.id === value);
        setControlSelectedCityId(value);
        form.setFieldsValue({ controlCity: city?.name, controlDistrict: undefined });
        setControlDistricts([]);
        if (value) {
            getDistricts(value);
        }

    };

    const handleControlDistrictChange = (value: string) => {
        form.setFieldsValue({ controlDistrict: value });
    };

    return (
        <Drawer
            title="Yeni Firma Ekle"
            width={720}
            onClose={() => {
                setCurrentStep(0);
                onClose();
            }}
            visible={visible}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
                <div className="text-right">
                    {currentStep < steps.length - 1 && (
                        <Button type="primary" onClick={next} loading={loading} className='d-button dark-button width-auto p-20px'>
                            Kaydet ve Devam Et
                        </Button>
                    )}
                    {currentStep === steps.length - 1 && (
                        <Button type="primary" onClick={onFinish} loading={loading} className='d-button dark-button width-auto p-20px'>
                            Kaydet ve Bitir
                        </Button>
                    )}
                </div>
            }
        >
            <Row className="p-30px mb-16px border-bottom-1px" >
                <Steps
                    current={currentStep}
                    onChange={onStepChange}
                    items={steps.map((step, index) => ({
                        title: step.title,
                        description: step.status,
                        icon: index < currentStep ? <CheckCircleOutlined /> : null,
                    }))}
                    className="custom-steps"
                />
            </Row>
            <Form form={form} layout="vertical" >
                {currentStep === 0 && (
                    <>
                        <Title level={5}>Firma  Bilgileri</Title>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label="Logo" name="logo" valuePropName="file">
                                    <ImageUpload setImageId={setImageId} imageId={imageId} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    label="Firma Ünvanı"
                                    className="form-item"
                                    rules={[{ required: true, message: 'Firma Ünvanı boş bırakılamaz' }]}
                                >
                                    <Input
                                        placeholder="Firma Ünvanı"
                                        className='d-input d-input-form'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="taxOffice"
                                    label="Vergi Dairesi"
                                    className="form-item"
                                    rules={[{ required: true, message: 'Vergi Dairesi boş bırakılamaz' }]}
                                >
                                    <Input
                                        placeholder="Vergi Dairesi"
                                        className='d-input d-input-form'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="taxNumber"
                                    label="Vergi Numarası"
                                    className="form-item"
                                    rules={[
                                        { required: true, message: 'Vergi Numarası boş bırakılamaz' },
                                        { message: 'Vergi Numarası 10 veya 11 haneli olmalıdır', max: 11, min: 10 },
                                        { pattern: /^[0-9]*$/, message: 'Vergi Numarası sadece rakamlardan oluşmalıdır' }
                                    ]}
                                >
                                    <Input placeholder="Vergi Numarası" className='d-input d-input-form' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="email"
                                    label="Firma E-posta Adresi"
                                    className="form-item"
                                    rules={[
                                        { required: true, message: 'E-posta boş bırakılamaz' },
                                        { type: 'email', message: 'Geçerli bir e-posta adresi girin' }
                                    ]}
                                >
                                    <Input
                                        placeholder="E-posta"
                                        className='d-input d-input-form '
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="phoneNumber"
                                    label="Firma Telefon Numarası"
                                    className="form-item"
                                    rules={[
                                        { required: true, message: 'Telefon Numarası boş bırakılamaz' },
                                        { pattern: /^[1-9]/, message: 'Telefon Numarası 0 ile başlamamalıdır' },
                                        { pattern: /^\d{10}$/, message: 'Telefon Numarası toplamda 10 haneli olmalıdır' },
                                    ]}
                                >
                                    <Input
                                        addonBefore="+90"
                                        placeholder="Telefon Numarası"
                                        className='d-input d-input-form'
                                        maxLength={10}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="city"
                                    label="İl"
                                    className="form-item"
                                    rules={[{ required: true, message: "İl seçimi zorunludur" }]}
                                >
                                    <Select
                                        placeholder="İl seçin"
                                        className="d-input d-input-form"
                                        showSearch
                                        filterOption={filterOption}
                                        onChange={handleCityChange}
                                    >
                                        {cities.map((city) => (
                                            <Select.Option key={city.id} value={city.id}>
                                                {city.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="district"
                                    label="İlçe"
                                    rules={[{ required: true, message: "İlçe seçimi zorunludur" }]}
                                >
                                    <Select
                                        placeholder="İlçe seçin"
                                        className="d-input d-input-form"
                                        showSearch
                                        filterOption={filterOption}
                                        onChange={handleDistrictChange}
                                        disabled={!selectedCityId}
                                    >
                                        {districts.map((district) => (
                                            <Select.Option key={district.id} value={district.name}>
                                                {district.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="address"
                                    label="Fatura Adresi"
                                    className="form-item"
                                    rules={[{ required: true, message: 'Fatura Adresi boş bırakılamaz' }]}
                                >
                                    <Input
                                        placeholder="Fatura Adresi"
                                        className='d-input d-input-form'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="kepAddress"
                                    label="Kep Adresi"
                                    className="form-item"
                                    rules={[
                                        { type: 'email', message: 'Geçerli bir Kep Adresi girin' }
                                    ]}
                                >
                                    <Input
                                        placeholder="Kep Adresi"
                                        className='d-input d-input-form '
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
                {currentStep === 1 && (
                    <>
                        <Title level={5} className="mb-0">Kontrol Adresi</Title>
                        <p className="mt-0">Periyodik kontrolün gerçekleştirileceği adres bilgisini giriniz. <br />
                            Kontrolün gerçekleştirileceği birden fazla adres varsa, firma detay sayfasından diğer adresleri ekleyebilirsiniz.</p>
                        <Form.Item
                            name="controlAddressTitle"
                            label="Adres Başlığı"
                            className="form-item"
                            rules={[{ message: 'Adres Başlığı boş bırakılamaz' }]}
                        >
                            <Input
                                placeholder="Kontrol Adresi 1"
                                className='d-input d-input-form'
                            />
                        </Form.Item>
                        <Form.Item
                            name="controlAddress"
                            label="Adres"
                            className="form-item"
                            rules={[{ required: true, message: 'Adres boş bırakılamaz' }]}
                        >
                            <Input.TextArea
                                placeholder="Adres"
                                className='d-input d-input-form'
                            />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="controlCity"
                                    label="İl"
                                    className="form-item"
                                    rules={[{ required: true, message: "İl seçimi zorunludur" }]}
                                >
                                    <Select
                                        placeholder="İl seçin"
                                        className="d-input d-input-form"
                                        showSearch
                                        filterOption={filterOption}
                                        onChange={handleControlCityChange}
                                    >
                                        {cities
                                            .filter((city) => cityIdsToShow.includes(city.id))
                                            .map((city) => (
                                                <Select.Option key={city.id} value={city.id}>
                                                    {city.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="controlDistrict"
                                    label="İlçe"
                                    rules={[{ required: true, message: "İlçe seçimi zorunludur" }]}
                                >
                                    <Select
                                        placeholder="İlçe seçin"
                                        className="d-input d-input-form"
                                        showSearch
                                        filterOption={filterOption}
                                        onChange={handleControlDistrictChange}
                                        disabled={!controlSelectedCityId}
                                    >
                                        {controlDistricts.map((district) => (
                                            <Select.Option key={district.id} value={district.name}>
                                                {district.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
                {currentStep === 2 && (
                    <>
                        <Title level={5} className="mb-0">Yetkili Bilgileri</Title>
                        <p className="mt-0">Firmaya ait yetkili bilgisini giriniz. <br />
                            Firmada birden fazla yetkili varsa, firma detay sayfasından diğer yetkilileri ekleyebilirsiniz.</p>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="authorizedPersonFirstName"
                                    label="Ad"
                                    className="form-item"
                                    rules={[{ required: true, message: 'Ad boş bırakılamaz' }]}
                                >
                                    <Input
                                        placeholder="Ad"
                                        className='d-input d-input-form'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="authorizedPersonLastName"
                                    label="Soyad"
                                    className="form-item"
                                    rules={[{ required: true, message: 'Soyad boş bırakılamaz' }]}
                                >
                                    <Input
                                        placeholder="Soyad"
                                        className='d-input d-input-form'
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            name="authorizedPersonPosition"
                            label="Ünvan"
                            className="form-item"
                            rules={[]}
                        >
                            <Input
                                placeholder="Ünvan"
                                className='d-input d-input-form'
                            />
                        </Form.Item>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="authorizedPersonEmail"
                                    label="E-posta"
                                    className="form-item "
                                    rules={[
                                        { required: true, message: 'E-posta boş bırakılamaz' },
                                        { type: 'email', message: 'Geçerli bir e-posta adresi girin' }
                                    ]}
                                >
                                    <Input
                                        placeholder="E-posta"
                                        className='d-input d-input-form '
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="authorizedPersonPhoneNumber"
                                    label="Cep Telefonu"
                                    className="form-item"
                                    rules={[
                                        { required: true, message: 'Cep Telefonu boş bırakılamaz' },
                                        { pattern: /^5\d{9}$/, message: 'Cep Telefonu 5 ile başlamalı ve 10 haneli olmalıdır' }
                                    ]}
                                >
                                    <Input
                                        addonBefore="+90"
                                        placeholder="Cep Telefonu"
                                        className='d-input d-input-form'
                                        maxLength={10}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}
            </Form>
        </Drawer>
    );
};

export default CompanyCreate;