import React, { useState, useEffect, useRef } from 'react';
import {
  Table, Button, Dropdown, Menu, Input, Row, Col, Checkbox, Tag,
  Typography, Card, Avatar, Tooltip, Popconfirm, Select,
  DatePicker
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import ProposalService from '../../services/ProposalService';
import { capitalizeWords, formatDate, formatDateString, formatToTL, openNotification } from '../../utils';
import CompanyService from '../../services/CompanyService';
import StatusService from '../../modules/Status/Service/StatusService';
import tinycolor from 'tinycolor2';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import PlanningModal from '../Companies/PlanningModal';
import DocumentUploadModal from '../Companies/UploadModal';
import ApproveDocument from '../Companies/ApproveDocument';
import ReportInvoiceModal from './ReportInvoiceModal';
import PaymentModal from './PaymentModal';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/tr_TR';
import 'dayjs/locale/tr';

const { RangePicker } = DatePicker;

const { Text } = Typography;

const ProposalListWithDetails: React.FC<any> = ({ defaultStatusIds, type }) => {
  const [filteredProposals, setFilteredProposals] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const [statuses, setStatuses] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1); // Başlangıç sayfası 1
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isIndividual, setIsIndividual] = useState<boolean | null>(null);
  const logoCache = useRef<Map<number, string>>(new Map());
  const pendingRequests = useRef<Map<number, Promise<string | null>>>(new Map());
  const [isPlanningModalVisible, setIsPlanningModalVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isDocumentModalVisible, setIsDocumentModalVisible] = useState(false);
  const [isStatusChange, setIsStatusChange] = useState(false);
  const [selectedProposalId, setSelectedProposalId] = useState<number | null>(null);
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('ASC');
  const [roles, setRoles] = useState<any[]>([]);
  const [selectedStatusIds, setSelectedStatusIds] = useState<number[]>([]);
  const [onlyMyCompanies, setOnlyMyCompanies] = useState<boolean>(false); // State for the checkbox
  const userId = localStorage.getItem('id'); // Get user ID from localStorage
  const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState(false);
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [paymentModalType, setPaymentModalType] = useState<any>();
  const [invoiceModalData, setInvoiceModalData] = useState<any>();
  const [invoiceModalType, setInvoiceModalType] = useState<any>();
  const [dateRange, setDateRange] = useState<[string | null, string | null]>([null, null]);


  useEffect(() => {
    StatusService.getAll()
      .then((response: any) => {
        const filteredStatuses = response.data.filter((status: any) => defaultStatusIds.includes(status.id));
        setStatuses(filteredStatuses);
      })
      .catch((error) => console.error("Statüler yüklenirken hata oluştu:", error));

    const storedRoles = localStorage.getItem('roles');
    const parsedRoles = storedRoles ? JSON.parse(storedRoles) : [];
    setRoles(parsedRoles);
  }, [defaultStatusIds]);

  // Arama yapıldığında veya filtreler değiştiğinde currentPage'i 1'e sıfırla
  useEffect(() => {
    setCurrentPage(1);
  }, [searchText, isIndividual, onlyMyCompanies]);

  const fetchProposals = async () => {
    logoCache.current.clear();
    pendingRequests.current.clear();

    const params: any = {
      page: currentPage - 1,
      size: pageSize,
      company: searchText,
      startInvoiceDate: dateRange[0],
      endInvoiceDate: dateRange[1],
      statuIds: selectedStatusIds.length > 0 ? selectedStatusIds.join(',') : defaultStatusIds.join(','),
      sortField,
      sortDirection,
    };

    if (isIndividual !== null) {
      params.isIndividual = isIndividual;
    }

    if (onlyMyCompanies && userId) {
      params.accountId = userId;
    }

    try {
      const response: any = await ProposalService.getAll(params);
      const enrichedProposals = await enrichProposalsWithLogos(response.data.content);
      setFilteredProposals(enrichedProposals);
      setTotalItems(response.data.totalElements);
    } catch (error) {
      console.error('Teklif verileri alınırken hata oluştu:', error);
    }
  }; useEffect(() => {

    fetchProposals();
  }, [
    currentPage,
    pageSize,
    sortField,
    sortDirection,
    isPlanningModalVisible,
    isUploadModalVisible,
    isDocumentModalVisible,
    searchText,
    isIndividual,
    defaultStatusIds,
    selectedStatusIds,
    onlyMyCompanies,
    userId,
    dateRange
  ]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setSortField(sorter.field || 'createdAt');
    setSortDirection(sorter.order === 'descend' ? 'DESC' : 'ASC');
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize || 10);
  };

  const handleOpenInvioceModal = () => {
    setIsInvoiceModalVisible(true);
  };

  const handleCloseInvoiceModal = () => {
    setIsInvoiceModalVisible(false);
  };

  const enrichProposalsWithLogos = async (proposals: any[]) => {
    return Promise.all(
      proposals.map(async (proposal) => {
        const { company } = proposal;
        if (company.imageId) {
          if (logoCache.current.has(company.imageId)) {
            return { ...proposal, company: { ...company, logoUrl: logoCache.current.get(company.imageId) } };
          } else if (pendingRequests.current.has(company.imageId)) {
            await pendingRequests.current.get(company.imageId);
            return { ...proposal, company: { ...company, logoUrl: logoCache.current.get(company.imageId) } };
          } else {
            const logoPromise = CompanyService.getImage(company.imageId)
              .then((logoResponse) => {
                const logoUrl = logoResponse.data.preSignUrl;
                logoCache.current.set(company.imageId, logoUrl);
                pendingRequests.current.delete(company.imageId);
                return logoUrl;
              })
              .catch((error) => {
                console.error("Logo yüklenirken hata oluştu:", error);
                pendingRequests.current.delete(company.imageId);
                return null;
              });

            pendingRequests.current.set(company.imageId, logoPromise);

            const logoUrl = await logoPromise;
            return { ...proposal, company: { ...company, logoUrl } };
          }
        }
        return proposal;
      })
    );
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent, value: boolean) => {
    const { checked } = e.target;
    if (checked) {
      setIsIndividual(value);
    } else {
      setIsIndividual(null);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target?.value?.toLowerCase();
    setSearchText(searchValue);
  };

  const handleApprove = async (proposalId: number | null, statusId: number) => {
    if (proposalId === null) return;
    try {
      await ProposalService.update(proposalId, { statusId: statusId });
      console.log(`Teklif ${proposalId} başarıyla güncellendi.`);
      // fetchProposals çağırmaya gerek yok, useEffect güncel durumu yakalayacak
    } catch (error) {
      console.error(`Teklif güncellenirken hata oluştu:`, error);
    }
  };

  const getStatusNameById = (statusId: number) => {
    const status = statuses.find((status: any) => status.id === statusId);
    return status ? status.name : 'Bilinmiyor';
  };

  const getStatusColorById = (statusId: number) => {
    const status = statuses.find((status: any) => status.id === statusId);
    return status ? `${status.color}` : '#fdfdfd';
  };

  const openPaymentModal = (proposalId: number, type: any) => {
    setSelectedProposalId(proposalId);
    setIsPaymentModalVisible(true);
    setPaymentModalType(type);
  };

  const columns = [
    {
      title: 'Firma Adı',
      dataIndex: ['company', 'name'],
      key: 'companyName',
      render: (text: string, record: any) => {
        const displayText = text.length > 28 ? `${text.substring(0, 28)}...` : text;
        return (
          <div className="d-flex align-items-center">
            <div className='avatar-image-logo' style={{ marginRight: 10 }}>
              {record?.company?.logoUrl ? (
                <img
                  src={record?.company?.logoUrl}
                  alt={record?.company?.name}
                  className='avatar-image-logo'
                  style={{ borderRadius: '50%' }}
                />
              ) : (
                <Avatar className='avatar-image-logo' style={{ backgroundColor: '#f0f0f0', color: '#000' }}>
                  {record?.company?.name.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </div>

            <Tooltip title={text.toUpperCase()}>
              <span >{displayText.toUpperCase()}</span>
            </Tooltip>
          </div>
        )
      },
    },
    ...(roles && roles.some((role: any) => role.name === 'ADMIN') ? [{
      title: "İş Ortağı Ad Soyad",
      key: "accountOwner",
      render: (_: any, record: any) => record.account ? `${record.account.firstName} ${record.account.lastName}` : "Bilgi Yok",
    }] : []),
    {
      title: "Yetkili Ad Soyad",
      key: "authorizedPersons",
      render: (_: any, record: any) => {
        const defaultPerson = record.company.authorizedPersons.find((person: any) => person.default) || record.company.authorizedPersons[0];
        return defaultPerson ? `${capitalizeWords(defaultPerson.firstName)} ${capitalizeWords(defaultPerson.lastName)}` : "Yetkili Bulunamadı";
      },
    },
    {
      title: 'Teklif No',
      dataIndex: 'proposalNumber',
      key: 'proposalNumber',
      render: (proposalNumber: string) => (
        <span style={{ color: 'blue' }}>
          {proposalNumber}
        </span>
      ),
    },
    ...(type !== 4 && type !== 1 && type !== 5
      ? [{
        title: 'Teklif Tarihi',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        render: (date: string) => <Text>{formatDate(date)}</Text>,
      }]
      : []),
    ...(type === 5
      ? [{
        title: 'Ödeme Süresi',
        dataIndex: 'invoiceDate',
        key: 'invoiceDateStatus',
        render: (invoiceDate: string) => {
          if (!invoiceDate) return <Text>-</Text>;

          const today = dayjs();
          const adjustedDueDate = dayjs(invoiceDate).add(30, 'days'); // Fatura tarihine 30 gün ekleniyor
          const diff = adjustedDueDate.diff(today, 'days'); // Bugün ile 30 gün sonrası arasındaki fark

          let backgroundColor = 'green'; // Default beyaz
          if (diff < 10) {
            backgroundColor = 'red'; // 10'dan küçükse kırmızı
          } else if (diff >= 10 && diff <= 20) {
            backgroundColor = 'volacno'; // 10-20 arasıysa sarı
          }

          return (
            <Tag
              color={backgroundColor}
              style={{
                borderRadius: '12px',
                padding: '5px 10px',
                fontSize: '14px',
                fontWeight: 'bold',
                display: 'inline-block',
                textAlign: 'center',
                width: '50px',
              }}
            >
              {diff}
            </Tag>
          );
        },
      }]
      : []),

    {
      title: 'Durum',
      dataIndex: 'statusId',
      key: 'status',
      render: (statusId: number, record: any) => {
        if (type === 5) {
          return (
            <Tag
              style={{
                color: record.isPaid ? '#55DA69' : '#FF4D4F',
                backgroundColor: record.isPaid ? '#E6F7E6' : '#FFF2F0',
                borderRadius: '12px',
                padding: '5px 10px',
                fontSize: '14px',
                display: 'inline-block',
                minWidth: '100px',
                textAlign: 'center',
                fontWeight: 500,
              }}
            >
              {record.isPaid ? 'Ödendi' : 'Bekleniyor'}
            </Tag>
          );
        } else {
          const color = tinycolor(getStatusColorById(statusId));
          const transparentColor = color.setAlpha(0.1).toRgbString();
          const transparentColorBorder = color.setAlpha(0.5).toRgbString();

          return (
            <Tag
              style={{
                color: getStatusColorById(statusId),
                backgroundColor: transparentColor,
                borderColor: transparentColorBorder,
                borderRadius: '12px',
                padding: '5px 10px',
                fontSize: '14px',
                display: 'inline-block',
                minWidth: '100px',
                textAlign: 'center',
                fontWeight: 500,
              }}>
              {getStatusNameById(statusId)}
            </Tag>
          );
        }
      },
    },
    ...(type === 3 || type === 5 || type === 4
      ? [{
        title: 'Fiyat',
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        sorter: true,
        width: 110,
        render: (price: any, record: any) => <Text>{formatToTL(record.invoiceAmount ?? price)}</Text>,
      }]
      : []),
    ...(type === 5
      ? [{
        title: 'Fatura Tarihi',
        dataIndex: 'invoiceDate',
        key: 'invoiceDate',
        sorter: true,
        render: (date: string) => <Text>{formatDateString(date)}</Text>,
      }]
      : []),
    ...(type !== 5
      ? [{
        title: 'Planlama Tarihi',
        dataIndex: 'approvedPlanningEndDate',
        key: 'approvedPlanningEndDate',
        render: (_: any, record: any) => {
          return record.approvedPlanningStartDate ? (
            <Text>{formatDate(record.approvedPlanningStartDate)} - {formatDate(record.approvedPlanningEndDate!)}</Text>
          ) : (
            <Text>Planlanmamış</Text>
          );
        }
      }]
      : []),
    ...(type !== 4
      ? [{
        title: 'İşlemler',
        key: 'action',
        render: (_: any, record: any) => (
          type === 2 ?
            <Button type='default' disabled={type == 3} className="action-button d-button" onClick={() => openPlanningModal(record.id)}>
              Düzenle / Onayla
            </Button> :
            <Dropdown
              overlay={
                <Menu>
                  {type === 1 &&
                    <>
                      {/* <Menu.Item key="detail">Sözleşmeyi İndir </Menu.Item> */}
                      <Menu.Item key="uploadModal" onClick={() => openUploadModal(record.id)} disabled={!record.documentId}>Sözleşmeyi Görüntüle</Menu.Item>
                      {record.statusId === 6 ? (
                        <Popconfirm
                          title="Onayı geri almak istediğinize emin misiniz?"
                          onConfirm={() => handleApprove(record.id, 10)}
                          okText="Evet"
                          cancelText="Hayır"
                        >
                          <Menu.Item key="revertApprove">Onayı Geri Al</Menu.Item>
                        </Popconfirm>
                      ) : (
                        <Menu.Item key="approve" onClick={() => openDocumentModal(record.id)} disabled={!record.documentId}>Sözleşmeyi Onayla</Menu.Item>
                      )}
                    </>}
                  {type === 3 &&
                    <>
                      <Menu.Item key="reportView" disabled={record.financialInfoUrl == null || record.invoiceAmount == null || record.reportUrl == null} onClick={() => handleOpenInvoiceModal(record, 'view')}>Rapor & Fatura Görüntüle</Menu.Item>
                      <Menu.Item key="upload" onClick={() => handleOpenInvoiceModal(record, 'edit')}>Rapor & Fatura Yükle</Menu.Item>
                    </>}
                  {type === 5 &&
                    <>
                      {type === 5 && (
                        <Menu.Item key="payment" onClick={() => openPaymentModal(record.id, 'edit')}>
                          Ödeme Yap
                        </Menu.Item>
                      )}
                      <Menu.Item key="upload" disabled={record.paidAmount == null} onClick={() => openPaymentModal(record.id, 'view')} >Ödeme Görüntüle</Menu.Item>
                    </>}

                </Menu>
              }
            >
              <Button type='default' className="d-button action-button">
                İşlemler
              </Button>
            </Dropdown>

        ),
      }]
      : []),

  ];

  const openPlanningModal = (proposalId: number) => {
    setSelectedProposalId(proposalId);
    setIsPlanningModalVisible(true);
  };

  const openUploadModal = (proposalId: number) => {
    setSelectedProposalId(proposalId);
    setIsUploadModalVisible(true);
  };

  const openDocumentModal = (proposalId: number) => {
    setSelectedProposalId(proposalId);
    setIsDocumentModalVisible(true);
  };

  useEffect(() => {
    if (isStatusChange) {
      handleApprove(selectedProposalId, 5);
      setIsStatusChange(false);
    }
  }, [isStatusChange]);

  const handleStatusChange = (value: number[]) => {
    setSelectedStatusIds(value);
    setCurrentPage(1); // Statü değiştiğinde sayfayı 1'e sıfırla
  };

  const handleUpdateRequest = async (data: { invoiceAmount: string; reportUrl: string; financialInfoUrl: string, invoiceDate: any }) => {
    if (!selectedProposalId) {
      console.error("Proposal ID is not set");
      openNotification("error", "Hata", "Teklif ID'si bulunamadı.");
      return;
    }

    try {
      const payload = {
        invoiceAmount: data.invoiceAmount,
        reportUrl: data.reportUrl,
        financialInfoUrl: data.financialInfoUrl,
        invoiceDate: data.invoiceDate,
      };

      await ProposalService.update(selectedProposalId, payload); // Send selectedProposalId
      openNotification("success", "Başarılı", "Değişiklik kaydedildi.");
      setIsInvoiceModalVisible(false);
      fetchProposals(); // Refresh the proposals list
    } catch (error) {
      console.error("Error updating proposal:", error);
      openNotification("error", "Hata", "Değişiklik kaydedilemedi.");
    }
  };

  const handleOpenInvoiceModal = (proposal: any, mode: "edit" | "view") => {
    if (!proposal.id) {
      console.error("Proposal ID is missing");
      openNotification("error", "Hata", "Teklif ID'si bulunamadı.");
      return;
    }

    setSelectedProposalId(proposal.id);
    setInvoiceModalData({
      invoiceAmount: proposal.invoiceAmount || '',
      reportUrl: proposal.reportUrl || '',
      financialInfoUrl: proposal.financialInfoUrl || '',
      invoiceDate: proposal.invoiceDate || '',
    });

    setIsInvoiceModalVisible(true);

    // Pass the type ("edit" or "view") dynamically
    setInvoiceModalType(mode);
  };


  const onDateChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if (dates) {
      setDateRange([
        dates[0]?.format('YYYY-MM-DD') ?? null,
        dates[1]?.format('YYYY-MM-DD') ?? null,
      ]);
    } else {
      setDateRange([null, null]);
    }
  };

  return (
    <Card className='br-30px mb-60px proposal-card'>
      <Row justify="space-between" align="middle">
        <Col>
          {type === 1 && <h2>İş Takip Listesi</h2>}
          {type === 2 && <h2>Planlama Talepleri</h2>}
          {type === 3 && <h2>Rapor & Fatura</h2>}
          {type === 4 && <h2>Tamamlanan Projeler</h2>}
          {type === 5 && <h2>Tamamlanan Projeler</h2>}
        </Col>
        <Col>
          <Row align="middle">
            {roles && roles.some((role: any) => role.name === 'ADMIN') && (
              <Checkbox
                checked={onlyMyCompanies}
                onChange={(e) => setOnlyMyCompanies(e.target.checked)}
                style={{ marginRight: 16 }}
              >
                Sadece kendi firmalarımı listele
              </Checkbox>
            )}
            <Select
              mode="multiple"
              placeholder="Statü seçin"
              className='d-input-filter'
              style={{ width: 200, marginRight: '8px', paddingLeft: 10 }}
              onChange={handleStatusChange}
            >
              {statuses.map((status) => (
                <Select.Option key={status.id} value={status.id}>
                  {status.name}
                </Select.Option>
              ))}
            </Select>
            {type === 5 &&
              <RangePicker onChange={onDateChange} className='d-input-filter mr-10px' format="DD/MM/YYYY" // Görüntülenecek format
              />
            }
            <Input
              placeholder="Ara"
              prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
              className="d-input d-input-form mr-16px d-input-filter"
              style={{ width: 200 }}
              onChange={handleInputChange}
              value={searchText}
            />
          </Row>
        </Col>
      </Row>
      {type === 3 &&
        <Row justify="start" align="middle" style={{ marginTop: '16px', gap: 10 }} className='mb-16px'>
          <Checkbox
            onChange={(e) => handleCheckboxChange(e, true)}
            checked={isIndividual === true}
          >
            Bireysel
          </Checkbox>
          <Checkbox
            onChange={(e) => handleCheckboxChange(e, false)}
            checked={isIndividual === false}
          >
            Ticari
          </Checkbox>
        </Row>
      }

      <Table
        scroll={{ x: 768 }}
        columns={columns}
        dataSource={filteredProposals}
        rowKey="proposalNumber"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalItems,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize || 10);
          },
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30'],
        }}
        onChange={handleTableChange}
      />
      {isPlanningModalVisible && selectedProposalId !== null && (
        <PlanningModal
          proposalId={selectedProposalId}
          isModalVisible={isPlanningModalVisible}
          setIsModalVisible={setIsPlanningModalVisible}
          setIsStatusChange={setIsStatusChange}
        />
      )}
      {isUploadModalVisible && selectedProposalId !== null && (
        <DocumentUploadModal
          setIsModalVisible={setIsUploadModalVisible}
          isModalVisible={isUploadModalVisible}
          proposalId={selectedProposalId}
          isCustomer={true}
          type={2}
        />
      )}
      {isDocumentModalVisible && selectedProposalId !== null && (
        <ApproveDocument
          setIsModalVisible={setIsDocumentModalVisible}
          isModalVisible={isDocumentModalVisible}
          proposalId={selectedProposalId}
          isCustomer={true}
          type={2}
        />
      )}
      {isInvoiceModalVisible && selectedProposalId && (
        <ReportInvoiceModal
          visible={isInvoiceModalVisible}
          onClose={handleCloseInvoiceModal}
          onSubmit={handleUpdateRequest}
          initialValues={invoiceModalData}
          type={invoiceModalType}
        />
      )}
      {isPaymentModalVisible && selectedProposalId && (
        <PaymentModal
          visible={isPaymentModalVisible}
          onClose={() => setIsPaymentModalVisible(false)}
          proposalId={selectedProposalId}
          mode={paymentModalType}

        />
      )}
    </Card>
  );
};

export default ProposalListWithDetails;