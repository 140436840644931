import { Card, Row, Typography } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ArrowIcon from '../../assets/icons/arrow-up.svg';
import ProposalService from '../../services/ProposalService';

const { Text, Title } = Typography;

const RenuewCard = () => {
    const [reneuw, setRenuew] = useState<any>({})

    const fetchProposals = useCallback(async () => {
        try {
            let response: any = await ProposalService.getRenuew();
            setRenuew(response.data)
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        fetchProposals()
    }, [])
    return (
        <Link to="/vallet">

            <Card bordered={false} className="dashboard-card dashboard-card-bg-green" bodyStyle={{ padding: 10 }}>
                <div className='dashboard-container'>
                    <Row justify={'space-between'}>
                        <div className='card-value'>
                            {Math.round(reneuw?.totalMaxDiscount ?? 0)} ₺
                        </div>
                        <img src={ArrowIcon} height={20} width={20} />
                    </Row>
                    <div>
                        <Title style={{ fontWeight: 'bold' }} className='mb-0' level={3}>Gelir Toplamı</Title>
                        <Text style={{ fontWeight: 300, fontSize: 18, color: '#001433' }} className="m-0">+{reneuw?.recentMaxDiscount ?? 0} ₺  Son 6 Ay</Text>
                    </div>
                </div>
            </Card>
        </Link>
    )
}

export default RenuewCard