import React, { useEffect } from "react";
import { Modal, Input, Button, Form, DatePicker, InputNumber } from "antd";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/tr_TR";
import "dayjs/locale/tr";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
interface ReportInvoiceModalProps {
    visible: boolean;
    onClose: () => void;
    onSubmit?: (data: {
        invoiceAmount: string;
        reportUrl: string;
        financialInfoUrl: string;
        invoiceDate: string;
    }) => void;
    initialValues: {
        invoiceAmount: string;
        reportUrl: string;
        financialInfoUrl: string;
        invoiceDate: string;
    };
    type: "edit" | "view";
}

const ReportInvoiceModal: React.FC<ReportInvoiceModalProps> = ({
    visible,
    onClose,
    onSubmit,
    initialValues,
    type,
}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (visible) {
            form.setFieldsValue({
                ...initialValues,
                invoiceDate: initialValues.invoiceDate
                    ? dayjs(initialValues.invoiceDate) // Tarihi dayjs formatına çevir
                    : null,
            });
        }
    }, [visible, initialValues, form]);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            if (onSubmit) {
                const postData = {
                    invoiceAmount: values.invoiceAmount,
                    reportUrl: values.reportUrl,
                    financialInfoUrl: values.financialInfoUrl,
                    invoiceDate: dayjs(values.invoiceDate).add(3, 'hour').toISOString(),
                };
                onSubmit(postData);
            }
        } catch (error) {
            console.error("Validation failed:", error);
        }
    };

    return (
        <Modal
            title={type === "edit" ? "Rapor & Fatura Yükle" : "Rapor & Fatura Görüntüle"}
            visible={visible}
            onCancel={onClose}
            footer={
                type === "edit" && (
                    <div style={{ textAlign: "center" }}>
                        <Button type="primary" className="d-button width-auto dark-button" onClick={handleSubmit}>
                            Kaydet
                        </Button>
                    </div>
                )
            }
            centered
        >
            <Form form={form} layout="vertical">
                {/* Fatura Tutarı */}
                <Form.Item
                    label="Fatura Tutarı"
                    name="invoiceAmount"
                    rules={[
                        { required: true, message: "Lütfen fatura tutarını giriniz." },
                    ]}
                >
                    <InputNumber
                        placeholder="Fatura Tutarı"
                        min={0}
                        step={0.01}
                        className="d-input d-input-form"
                        precision={2}
                        style={{ width: "100%" }}
                        readOnly={type === "view"}
                    />
                </Form.Item>

                {/* Fatura Tarihi */}
                <Form.Item
                    label="Fatura Tarihi"
                    name="invoiceDate"
                    rules={[
                        { required: true, message: "Lütfen fatura tarihini seçiniz." },
                    ]}
                >
                    <DatePicker
                        locale={locale}
                        format="DD/MM/YYYY"
                        style={{ width: "100%" }}
                        placeholder="GG/AA/YYYY"
                        disabled={type === "view"}
                        value={initialValues.invoiceDate ? dayjs(initialValues.invoiceDate) : null}
                        onChange={(date) => {
                            if (type !== "view") {
                                form.setFieldsValue({ invoiceDate: date });
                            }
                        }}
                    />
                </Form.Item>
                {/* Rapor URL */}
                <Form.Item
                    label="Rapor URL"
                    name="reportUrl"
                    rules={[
                        { required: true, message: "Lütfen geçerli bir URL giriniz." },
                        { type: "url", message: "Geçerli bir URL giriniz." },
                    ]}
                >
                    {type === "view" ? (
                        <a
                            href={initialValues.reportUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {initialValues.reportUrl ?? "Rapor URL Yok"}
                        </a>
                    ) : (
                        <Input placeholder="Rapor URL" />
                    )}
                </Form.Item>

                {/* Mali Bilgiler URL */}
                <Form.Item
                    label="Mali Bilgiler URL"
                    name="financialInfoUrl"
                    rules={[
                        { required: true, message: "Lütfen geçerli bir URL giriniz." },
                        { type: "url", message: "Geçerli bir URL giriniz." },
                    ]}
                >
                    {type === "view" ? (
                        <a
                            href={initialValues.financialInfoUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {initialValues.financialInfoUrl ?? "Mali Bilgiler URL Yok"}
                        </a>
                    ) : (
                        <Input placeholder="Mali Bilgiler URL" />
                    )}
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ReportInvoiceModal;