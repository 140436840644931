import { Button, Checkbox, Row, Table } from 'antd';
import React, { useState, useEffect } from 'react';
import AuthorizedPersonService from '../../services/AuthorizedPersonService';
import AuthorizedPersonDrawer from './AuthorizedPersonDrawer';

interface AuthorizedPerson {
    id: number;
    firstName: string;
    lastName: string;
    position: string;
    email: string;
    phoneNumber: string;
    default: boolean;
}

const AuthorizedPersonTable = ({
    companyId,
    setSelectedAuthorizedPersonIds,
    selectedAuthorizedPersonIds,
    type = false
}: {
    companyId: number;
    setSelectedAuthorizedPersonIds: (ids: number[]) => void;
    selectedAuthorizedPersonIds: number[];
    type?: boolean
}) => {
    const [authorizedPersons, setAuthorizedPersons] = useState<AuthorizedPerson[]>([]);
    const [selectedRows, setSelectedRows] = useState<number[]>(selectedAuthorizedPersonIds);
    const [isAuthorizedPersonDrawerVisible, setAuthorizedPersonDrawerVisible] = useState(false);

    useEffect(() => {
        fetchAuthorizedPersons();
    }, [companyId]);

    const fetchAuthorizedPersons = async () => {
        try {
            const response = await AuthorizedPersonService.getCompanyAuthorizedPersons(companyId);
            setAuthorizedPersons(response.data);
        } catch (error) {
            console.error('Yetkili kişiler yüklenirken hata oluştu:', error);
        }
    };

    const handleCheckboxChange = (record: AuthorizedPerson) => {
        const updatedSelectedRows = selectedRows.includes(record.id)
            ? selectedRows.filter((id) => id !== record.id)
            : [...selectedRows, record.id];

        setSelectedRows(updatedSelectedRows);
        setSelectedAuthorizedPersonIds(updatedSelectedRows);
    };

    const columns = [
        {
            title: '',
            width: 50,
            key: 'selection',
            render: (text: string, record: AuthorizedPerson) => (
                <Checkbox
                    checked={selectedRows.includes(record.id)}
                    onChange={() => handleCheckboxChange(record)}
                />
            ),
        },
        {
            title: 'Ad',
            dataIndex: 'firstName',
            key: 'firstName',
            width:260,
        },
        {
            title: 'Soyad',
            dataIndex: 'lastName',
            key: 'lastName',
            width:260,
        },
        {
            title: 'Ünvan',
            dataIndex: 'position',
            key: 'position',
            width:150,
        },
        {
            title: 'E-Posta',
            dataIndex: 'email',
            key: 'email',
            width:250,
        },
        {
            title: 'Cep Telefonu',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        // {
        //     title: 'Birincil',
        //     dataIndex: 'default',
        //     key: 'default',
        //     render: (isDefault: boolean) => (isDefault ? 'Evet' : 'Hayır'),
        // },
    ];

    const handleAddPersonClick = () => {
        setAuthorizedPersonDrawerVisible(true);
    };

    const handleAuthorizedPersonDrawerClose = () => {
        setAuthorizedPersonDrawerVisible(false);
        fetchAuthorizedPersons();
    };

    return (
        <>
            <Row justify="space-between">
                <h3>Yetkili Kişi</h3>
                <Button
                    type="primary"
                    className="d-button mb-16px width-auto dark-button"
                    onClick={handleAddPersonClick}
                >
                    Yeni Yetkili Ekle
                </Button>
            </Row>
            {type == true &&
                <div style={{ marginBottom: 16 }}>Teklifin gönderileceği yetkilileri seçiniz.</div>
            }
            <Table
                scroll={{ x: 768 }}
                columns={columns}
                pagination={false}
                dataSource={authorizedPersons}
                rowKey={(record) => record.id}
                onRow={(record) => ({
                    onClick: () => handleCheckboxChange(record),
                })}
            />
            <AuthorizedPersonDrawer
                visible={isAuthorizedPersonDrawerVisible}
                onClose={handleAuthorizedPersonDrawerClose}
                companyId={companyId as any}
                existingData={null}
                editPerson={null}
            />
        </>
    );
};

export default AuthorizedPersonTable;