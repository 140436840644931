import React, { useState, useEffect } from "react";
import { Drawer, Form, Input, Button, Select, Row, Col } from "antd";
import AddressService from "../../services/AddressService";
import "./Company.scss";
import { filterOption, openNotification } from "../../utils";
import CompanyService from "../../services/CompanyService";

interface ControlAddressDrawerProps {
  visible: boolean;
  onClose: () => void;
  companyId: any;
  addresses: any;
  editAddress?: any;
}

const ControlAddressDrawer: React.FC<ControlAddressDrawerProps> = ({
  visible,
  onClose,
  companyId,
  addresses,
  editAddress,
}) => {
  const [form] = Form.useForm();
  const [cities, setCities] = useState<{ id: number; name: string }[]>([]);
  const [districts, setDistricts] = useState<{ id: number; name: string }[]>([]);
  const [selectedCityId, setSelectedCityId] = useState<number | null>(null);
  const [controlTitlePlaceholder, setControlTitlePlaceholder] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const cityIdsToShow = [2170, 2195, 2150, 2167, 2151, 2176, 2163, 2218, 2175];

  useEffect(() => {
    const getState = async () => {
      try {
        const response = await CompanyService.getStates(225);
        setCities(response.data);
      } catch (error) {
        console.error("Şehirler alınırken hata oluştu:", error);
      }
    };
    getState();
  }, []); // Bağımlılık dizisi boş

  // Düzenleme modunda veya yeni adres eklerken yapılacak işlemler
  useEffect(() => {
    if (editAddress) {
      form.setFieldsValue({
        controlTitle: editAddress.title,
        controlAddress: editAddress.addressLine1,
        controlAddressLine2: editAddress.addressLine2,
        controlCity: editAddress.city,
        controlDistrict: editAddress.state,
        controlPostalCode: editAddress.postalCode,
        controlPhone: editAddress.phone,
        controlIdentityNumber: editAddress.identityNumber,
        controlTaxNumber: editAddress.taxNumber,
        controlCompanyName: editAddress.companyName,
        controlTaxOffice: editAddress.taxOffice,
        controlFullName: editAddress.fullName,
      });

      // Seçili şehre göre ilçeleri yükle
      const city = cities.find((c) => c.name === editAddress.city);
      if (city) {
        setSelectedCityId(city.id);
        getDistricts(city.id);
      }
    } else {
      const controlAddresses = addresses?.filter(
        (address: any) => address.type === "CONTROL"
      ) || [];

      let maxIndex = controlAddresses.length + 1;

      controlAddresses.forEach((address: any) => {
        const match = address.title?.match(/Kontrol Adresi (\d+)/);
        if (match) {
          const index = parseInt(match[1], 10);
          if (index >= maxIndex) {
            maxIndex = index + 1;
          }
        }
      });

      // Placeholder for the title
      setControlTitlePlaceholder(`Kontrol Adresi ${maxIndex}`);
      form.resetFields();
    }
  }, [editAddress, form, addresses]); // Bağımlılık dizisinden cities çıkarıldı

  const getDistricts = async (stateId: number) => {
    try {
      const response = await CompanyService.getDistricts(stateId);
      setDistricts(response.data);
    } catch (error) {
      console.error("İlçeler alınırken hata oluştu:", error);
    }
  };

  const handleCityChange = (value: number) => {
    const city = cities.find((c) => c.id === value);
    setSelectedCityId(value);
    form.setFieldsValue({ controlCity: city?.name, controlDistrict: undefined });
    setDistricts([]);
    if (value) {
      getDistricts(value);
    }
  };

  const handleDistrictChange = (value: string) => {
    form.setFieldsValue({ controlDistrict: value });
  };

  const onFinish = async () => {
    try {
      setIsLoading(true);
      const values = await form.validateFields();

      const newControlAddress = {
        addressLine1: values.controlAddress,
        addressLine2: values.controlAddressLine2 || "",
        city: values.controlCity,
        phone: values.controlPhone || "",
        state: values.controlDistrict,
        postalCode: values.controlPostalCode || "",
        country: "Turkey",
        type: "CONTROL",
        identityNumber: values.controlIdentityNumber || "",
        taxNumber: values.controlTaxNumber || "",
        companyName: values.controlCompanyName || "",
        taxOffice: values.controlTaxOffice || "",
        title: values.controlTitle || controlTitlePlaceholder,
        fullName: values.controlFullName || "",
        companyId: companyId,
      };

      if (editAddress) {
        await AddressService.update(editAddress.id, newControlAddress);
        openNotification("success", "Başarılı", "Kontrol Adresi Güncellendi");
      } else {
        await AddressService.create(newControlAddress);
        openNotification("success", "Başarılı", "Kontrol Adresi Eklendi");
      }

      onClose();
      form.resetFields();
      setIsLoading(false);
    } catch (error) {
      console.error("Validation Failed:", error);
      openNotification(
        "error",
        "Hata",
        editAddress ? "Kontrol Adresi Güncellenemedi" : "Kontrol Adresi Eklenemedi"
      );
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={editAddress ? "Kontrol Adresi Düzenle" : "Yeni Kontrol Adresi Ekle"}
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div className="text-right">
          <Button onClick={onFinish} type="primary" loading={isLoading} className="d-button dark-button width-auto">
            Kaydet
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="controlTitle"
          label="Adres Başlığı"
          rules={[{ message: "Adres Başlığı boş bırakılamaz" }]}
        >
          <Input placeholder={controlTitlePlaceholder} className="d-input d-input-form" />
        </Form.Item>
        <Form.Item
          name="controlAddress"
          label="Adres"
          rules={[{ required: true, message: "Adres boş bırakılamaz" }]}
        >
          <Input.TextArea placeholder="Adres" className="d-input d-input-form" />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="controlCity"
              label="İl"
              rules={[{ required: true, message: "İl seçimi zorunludur" }]}
            >
              <Select
                placeholder="İl seçin"
                className="d-input d-input-form"
                showSearch
                filterOption={filterOption}
                onChange={handleCityChange}
              >
                {cities
                  .filter((city) => cityIdsToShow.includes(city.id))
                  .map((city) => (
                    <Select.Option key={city.id} value={city.id}>
                      {city.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="controlDistrict"
              label="İlçe"
              rules={[{ required: true, message: "İlçe seçimi zorunludur" }]}
            >
              <Select
                placeholder="İlçe seçin"
                className="d-input d-input-form"
                showSearch
                filterOption={filterOption}
                onChange={handleDistrictChange}
                disabled={!selectedCityId}
              >
                {districts.map((district) => (
                  <Select.Option key={district.id} value={district.name}>
                    {district.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default ControlAddressDrawer;