import React, { useState, useEffect } from "react";
import { Drawer, Form, Input, Button, Row, Col } from "antd";
import CompanyService from "../../services/CompanyService";
import "./Company.scss";
import { openNotification } from "../../utils";
import AuthorizedPersonService from "../../services/AuthorizedPersonService";

interface AuthorizedPersonDrawerProps {
  visible: boolean;
  onClose: () => void;
  companyId: string;
  existingData: any;
  editPerson?: any;
}

const AuthorizedPersonDrawer: React.FC<AuthorizedPersonDrawerProps> = ({
  visible,
  onClose,
  companyId,
  existingData,
  editPerson,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (editPerson) {
      form.setFieldsValue({
        firstName: editPerson.firstName,
        lastName: editPerson.lastName,
        position: editPerson.position,
        email: editPerson.email,
        phoneNumber: editPerson.phoneNumber,
      });
    }
  }, [editPerson, form]);

  const onFinish = async () => {
    try {
      setIsLoading(true);
      const values = await form.validateFields();
  
      const newPerson = {
        firstName: values.firstName || form.getFieldValue("firstName"),
        lastName: values.lastName || form.getFieldValue("lastName"),
        position: values.position || form.getFieldValue("position"),
        email: (values.email.toLowerCase() || form.getFieldValue("email")).toLowerCase(),
        phoneNumber: values.phoneNumber || form.getFieldValue("phoneNumber"),
      };
  
      if (editPerson) {
        // Eğer edit modundaysa, yetkili kişiyi güncelle
        await AuthorizedPersonService.update(editPerson.id, newPerson);
        openNotification("success", "Başarılı", "Yetkili Güncellendi");
      } else {
        // Eğer yeni bir yetkili ekleniyorsa, yetkiliyi yarat
        await AuthorizedPersonService.create({ ...newPerson, companyId });
        openNotification("success", "Başarılı", "Yeni Yetkili Eklendi");
      }
  
      setIsLoading(false);
      onClose();
      form.resetFields();
    } catch (error) {
      console.error("Validation Failed:", error);
      openNotification("error", "Hata", "Yetkili bilgisi güncellenemedi");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (editPerson) {
      form.setFieldsValue({
        firstName: editPerson.firstName,
        lastName: editPerson.lastName,
        position: editPerson.position,
        email: editPerson.email,
        phoneNumber: editPerson.phoneNumber,
      });
    } else {
      form.resetFields();
    }
  }, [editPerson, form]);

  return (
    <Drawer
      title={editPerson ? "Yetkiliyi Düzenle" : "Yeni Yetkili Ekle"}
      width={720}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div className="text-right">
          <Button onClick={onFinish} type="primary" loading={isLoading} className="d-button dark-button width-auto">
            Kaydet
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label="Ad"
              className="form-item"
              rules={[{ required: true, message: "Ad boş bırakılamaz" }]}
            >
              <Input
                placeholder="Ad"
                className="d-input d-input-form"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              label="Soyad"
              className="form-item"
              rules={[{ required: true, message: "Soyad boş bırakılamaz" }]}
            >
              <Input
                placeholder="Soyad"
                className="d-input d-input-form"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="position"
          label="Ünvan"
          className="form-item"
          rules={[]}
        >
          <Input
            placeholder="Ünvan"
            className="d-input d-input-form"
          />
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email"
              label="E-Posta"
              className="form-item"
              rules={[{ required: true, type: "email", message: "Geçerli bir e-posta adresi girin" }]}
            >
              <Input
                placeholder="E-Posta"
                className="d-input d-input-form "
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phoneNumber"
              label="Cep Telefonu"
              className="form-item"
              rules={[
                { required: true, message: "Cep Telefonu boş bırakılamaz" },
                { len: 10, message: "Telefon numarası 10 karakter olmalıdır" },
                { pattern: /^[0-9]*$/, message: "Telefon sadece rakamlardan oluşmalıdır" },
                {
                  validator: (_, value) => {
                    if (value && !value.startsWith('5')) {
                      return Promise.reject(new Error("Telefon numarası 5 ile başlamalıdır."));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                placeholder="Cep Telefonu"
                className="d-input d-input-form"
                addonBefore="+90"
                maxLength={10}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default AuthorizedPersonDrawer;