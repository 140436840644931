import React, { useState, useEffect } from 'react';
import { Modal, Button, Upload, Typography, Form, Row, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { openNotification } from '../../utils';
import ProposalService from '../../services/ProposalService';
import DocumentService from '../../services/DocumentService';

const { Text } = Typography;

const ApproveDocument: React.FC<any> = ({ proposalId, isModalVisible, setIsModalVisible, setIsStatusChange = false, type = 1 }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState<any[]>([]);
    const [documentName, setDocumentName] = useState<string | null>(null);
    const [preSignUrl, setPreSignUrl] = useState<string | null>(null);
    const [isImage, setIsImage] = useState<boolean>(false);
    const [roles, setRoles] = useState<any>();

    useEffect(() => {
        if (isModalVisible && proposalId) {
            fetchProposalDocument();
        }
    }, [isModalVisible, proposalId]);

    useEffect(() => {
        let storedRoles: any = localStorage.getItem('roles');
        let parsedRoles = JSON.parse(storedRoles);
        setRoles(parsedRoles);
    }, [])

    const fetchProposalDocument = async () => {
        try {
            const response = await ProposalService.get(proposalId);
            const documentId = response?.data?.documentId;

            if (documentId) {
                const responseDocument: any = await DocumentService.getDocument(documentId);
                const documentData = responseDocument?.data;

                if (documentData) {
                    setDocumentName(documentData.code);
                    setPreSignUrl(documentData.preSignUrl);

                    // Dosya uzantısına göre bir resim olup olmadığını kontrol et
                    const fileType = documentData.code.split('.').pop().toLowerCase();
                    setIsImage(['jpg', 'jpeg', 'png'].includes(fileType));
                } else {
                    setDocumentName(null);
                    setPreSignUrl(null);
                    setIsImage(false);
                }
            }
        } catch (error) {
            openNotification('error', 'Hata', 'Belge bilgisi getirilemedi.');
            console.error(error);
        }
    };

    const handleFileChange = ({ fileList }: any) => {
        setFileList(fileList.slice(-1)); // Tek bir dosyaya izin veriliyor
    };

    const handleUpload = async () => {
        if (fileList.length === 0) {
            message.warning("Lütfen bir dosya seçin.");
            return;
        }

        setIsLoading(true);

        try {
            const fileName = fileList[0].name;
            const uploadResponse: any = await DocumentService.postDocument(fileList[0].originFileObj, fileName);
            const documentId: any = uploadResponse?.data?.id;

            if (documentId) {
                await ProposalService.updateDocumentId(proposalId, { documentId });
                openNotification('success', 'Başarılı', 'Belge başarıyla yüklendi ve güncellendi.');
                setIsStatusChange(true);
                setIsModalVisible(false);
                setFileList([]);
            }
        } catch (error) {
            // openNotification('error', 'Hata', 'Belge yüklenirken bir hata oluştu.');
            console.error(error);
        } finally {
            setIsLoading(false);
            setIsModalVisible(false);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setFileList([]);
        setDocumentName(null);
        setPreSignUrl(null);
        setIsImage(false);
    };

    const handleApprove = async (proposalId: any, statusId: number) => {
        try {
            await ProposalService.update(proposalId, { statusId: statusId });
            openNotification('success', 'Başarılı', 'Teklif başarıyla onaylandı.');
            setIsModalVisible(false);
            console.log(`Teklif ${proposalId} başarıyla güncellendi.`);
        } catch (error) {
            console.error(`Teklif güncellenirken hata oluştu:`, error);
        }
    };

    return (
        <Modal
            title={type == 1 ? <Text className="modal-title">Sözleşme Yükle</Text> : <Text className="modal-title">Sözleşme Görüntüle</Text>}
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            className="planlama-modal"
        >
            <Form layout="vertical">
                {type === 1 &&
                    <Text>Yüklediğiniz sözleşme, Kontrol X tarafından incelendikten sonra onaylanacaktır. Lütfen dosyanızın doğru ve eksiksiz olduğundan emin olun.</Text>
                }
                <br />
                {documentName && (
                    <div style={{ marginTop: 16 }}>
                        <a href={preSignUrl ?? ''}>
                            <Text style={{ backgroundColor: '#F5F5F5', color: '#1890FF' }} strong>{documentName}</Text>
                        </a>
                        {isImage && preSignUrl ? (
                            <div style={{ marginTop: 16, textAlign: 'center' }}>
                                <a href={preSignUrl}>
                                    <img src={preSignUrl} alt="Document Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                                </a>
                            </div>
                        ) : null}
                        <Row justify="center" style={{ marginTop: 16 }}>
                            <Button type="primary" className='dark-button d-button width-auto' onClick={() => handleApprove(proposalId, 6)}>
                                Onayla
                            </Button>
                        </Row>
                    </div>
                )}


            </Form>
        </Modal>
    );
};

export default ApproveDocument;