import React, { useState, useEffect } from 'react';
import { Card, Button, Input, List, message, Popconfirm, Tooltip } from 'antd';
import NoteService from '../../services/NoteService';
import { useParams } from 'react-router-dom';
import { FileTextOutlined, DeleteOutlined } from '@ant-design/icons';
import './Company.scss';
import { formatRelativeTime, formatDate, openNotification } from '../../utils';
import noteIcon from '../../assets/icons/note.svg';
import TrashIcon from '../../assets/icons/trash.svg';

const { TextArea } = Input;

interface Note {
    id: number;
    content: string;
    createdAt: string;
}

const NoteSection: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [notes, setNotes] = useState<Note[]>([]);
    const [newNote, setNewNote] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchNotes();
    }, [id]);

    const fetchNotes = async () => {
        try {
            const response = await NoteService.getNotes('company', id ?? '');
            setNotes(response.data);
        } catch (error) {
            message.error('Notlar yüklenirken bir hata oluştu.');
            console.error(error);
        }
    };

    const addNote = async () => {
        setIsLoading(true);
        if (!newNote.trim()) {
            message.warning('Lütfen notunuzu yazın.');
            return;
        }

        const noteData = {
            content: newNote,
            entityName: 'company',
            entityId: Number(id),
        };

        try {
            await NoteService.postNote(noteData);
            openNotification('success', 'Başarılı', 'Not başarıyla eklendi.');
            setNewNote('');
            fetchNotes();
        } catch (error) {
            openNotification('error', 'Hata', 'Not eklenirken bir hata oluştu.');
            console.error(error);
        }
        setIsLoading(false);
    };

    const deleteNote = async (noteId: number) => {
        try {
            await NoteService.remove(noteId);
            openNotification('success', 'Başarılı', 'Not başarıyla silindi.');
            fetchNotes();
        } catch (error) {
            openNotification('error', 'Hata', 'Not silinirken bir hata oluştu.');
            console.error(error);
        }
    };

    return (
        <Card className="note-section-card br-15px p-20px" >
            <div className='mb-20px'>
                <h3 className='d-flex align-items-center'>
                    <Button
                        icon={<img src={noteIcon} alt="pen" className='icon' />}
                        shape="circle"
                        className='icon-button mr-10px'
                    />
                    Not Ekle
                </h3>
                <TextArea
                    placeholder="Notunuzu buraya yazınız."
                    value={newNote}
                    className='br-16px d-input-form'
                    onChange={(e) => setNewNote(e.target.value)}
                    rows={5}
                />
            </div>
            <div className='d-flex justify-content-end mb-20px'>
                <Button onClick={() => setNewNote('')} className='d-button width-auto mr-10px'>
                    Temizle
                </Button>
                <Button type="primary" onClick={addNote} loading={isLoading} className='d-button dark-button width-auto'>
                    Kaydet
                </Button>
            </div>
            <h3>Notlar</h3>
            <List
                itemLayout="horizontal"
                dataSource={notes}
                renderItem={(note) => (
                    <List.Item
                        className='d-flex align-items-start'
                        actions={[
                            <Popconfirm
                                title="Bu notu silmek istediğinize emin misiniz?"
                                onConfirm={() => deleteNote(note.id)}
                                okText="Evet"
                                cancelText="Hayır"
                            >
                                <Button
                                    icon={<img src={TrashIcon} alt="pen" className='icon' />}
                                    shape="circle"
                                    className='icon-button'
                                />

                            </Popconfirm>,
                        ]}
                    >
                        <List.Item.Meta
                            avatar={<Button
                                icon={<img src={noteIcon} alt="pen" className='icon' />}
                                shape="circle"
                                className='icon-button'
                            />}
                            description={
                                <>
                                    <div style={{ color: '#1B1E27' }}>{note.content}</div>
                                    <Tooltip title={formatDate(note.createdAt)}>
                                        <span style={{ color: '#888' }}>{formatRelativeTime(note.createdAt)}</span>
                                    </Tooltip>
                                </>
                            }
                        />
                    </List.Item>
                )}
            />
        </Card>
    );
};

export default NoteSection;