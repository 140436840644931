import { Card, Row, Typography } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ArrowIcon from '../../assets/icons/arrow-up.svg';
import ProposalService from '../../services/ProposalService';

const { Text, Title } = Typography;

const SummaryCard = () => {
    const [summary, setSummary] = useState<any>({})

    const fetchProposals = useCallback(async () => {
        try {
            let response: any = await ProposalService.getSummary();
            setSummary(response.data)
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        fetchProposals()
    }, [])
    return (
        <Card bordered={false} className="dashboard-card dashboard-card-bg-purple" bodyStyle={{ padding: 10 }}>
            <div className='dashboard-container'>
                <Row justify={'space-between'}>
                    <div className='card-value'>
                        {Math.round(summary?.totalProposals ?? 0)}
                    </div>
                    <img src={ArrowIcon} height={20} width={20} />
                </Row>
                <div>
                    <Title style={{ fontWeight: 'bold' }} className='mb-0' level={3}>Teklif Sayısı</Title>
                    <Text style={{ fontWeight: 300, fontSize: 18, color: '#001433' }} className="m-0">+{summary?.proposalsLast6Months ?? 0} Son 6 Ay</Text>
                </div>
            </div>
        </Card>
    )
}

export default SummaryCard