import { http } from "../http-common";

const getAllTools = (search?: string) => {
    const params = search ? { params: { search } } : {};
    return http.get<any[]>('/tools', params);
};

const createTool = (data: any) => {
    return http.post<any>('/tools', data);
};

const updateTool = (id: number, data: any) => {
    return http.put<any>(`/tools/${id}`, data);
};

const deleteTool = (id: number) => {
    return http.delete(`/tools/${id}`);
};

const ToolService = {
    getAllTools,
    createTool,
    updateTool,
    deleteTool,
};

export default ToolService;