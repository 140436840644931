import React, { useState } from "react";
import { Table, Button, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import jsPDF from "jspdf";



interface Contract {
    id: string;
    key: string;
    name: string;
    publicationDate: string;
    lastActionDate: string;
    content: string;
}


const dummyData: Contract[] = [
    {
        id: "123",
        key: "1",
        name: "Kontrol X ile İş Ortağı Arasında İş Ortaklığı Sözleşmesi",
        publicationDate: "01/06/2024",
        lastActionDate: "01/06/2024",
        content: `
            <p><strong>Sözleşme No:</strong> 12345 <br />
            <strong>Tarih:</strong> 01/06/2024</p>
            <p><strong>Taraflar</strong><br />
            1. <strong>Kontrol X Şirketi ("Kontrol X")</strong><br />
            • Adres: [Kontrol X Adresi]<br />
            • Telefon: [Kontrol X Telefonu]<br />
            • E-posta: [Kontrol X E-posta Adresi]<br />
            2. <strong>İş Ortağı ("İş Ortağı")</strong><br />
            • Adres: [İş Ortağı Adresi]<br />
            • Telefon: [İş Ortağı Telefonu]<br />
            • E-posta: [İş Ortağı E-posta Adresi]<br /></p>
            <p><strong>Tanımlar</strong><br />
            Hizmetler: Kontrol X tarafından sağlanan ve İş Ortağı'nın müşterilerine sunduğu periyodik kontrol, hijyen ölçümü ve diğer teknik hizmetler.</p>
            <p><strong>Sözleşme Süresi:</strong> Bu sözleşmenin geçerli olduğu süreyi belirtir.</p>
        `
    },
];

const Contracts: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedContract, setSelectedContract] = useState<Contract | null>(null);

    const showModal = (id: string) => {
        const contract = dummyData.find((c) => c.id === id);
        if (contract) {
            setSelectedContract(contract);
            setIsModalVisible(true);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleDownload = (contract: Contract) => {
        const doc = new jsPDF('p', 'pt', 'a4');
        jsPDF.API.events.push(['addFonts', function () {
            doc.addFileToVFS('DejaVuSans.ttf', 'AAEAAA...'); // Base64 font verisi
            doc.addFont('DejaVuSans.ttf', 'DejaVuSans', 'normal');
        }]);
        // Yazı tipini ayarla
        doc.setFont('DejaVuSans', 'normal');

        const tempElement = document.createElement('div');
        tempElement.innerHTML = `
            <style>
                * {
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                }
                body, html {
                    width: 100%;
                    font-size: 12pt;
                    font-family: 'DejaVuSans';
                }
                p {
                    margin: 0 0 10pt 0;
                    width: 100%;
                }
                br {
                    display: block;
                    line-height: normal;
                }
                div {
                    width: 100%;
                }
            </style>
            ${contract.content}
        `;

        doc.html(tempElement, {
            callback: function (doc) {
                doc.save(`${contract.name}.pdf`);
            },
            x: 10,
            y: 10,
            width: doc.internal.pageSize.getWidth() - 20,
            windowWidth: doc.internal.pageSize.getWidth() - 20,
            html2canvas: {
                scale: 1,
            },
        });
    };

    const columns: ColumnsType<Contract> = [
        {
            title: "Sözleşme Adı",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
                <a onClick={() => showModal(record.id)}>{text}</a>
            ),
        },
        {
            title: "Yayımlanma Tarihi",
            dataIndex: "publicationDate",
            key: "publicationDate",
        },
        {
            title: "Son İşlem Tarihi",
            dataIndex: "lastActionDate",
            key: "lastActionDate",
        },
        {
            title: "Aksiyonlar",
            key: "action",
            render: (text, record) => (
                <div>
                    <Button type="primary" className="d-button dark-button width-auto" onClick={() => handleDownload(record)}>
                        Sözleşmeyi İndir
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div>
            <Table scroll={{ x: 768 }} columns={columns} dataSource={dummyData} pagination={false} />
            <Modal
                centered
                bodyStyle={{ maxHeight: '500px', overflowY: 'auto' }}
                title={selectedContract?.name}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                {selectedContract && (
                    <div id="pdf-content" dangerouslySetInnerHTML={{ __html: selectedContract.content }} />
                )}
            </Modal>
        </div>
    );
};

export default Contracts;