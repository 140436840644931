import React, { useState, useEffect } from 'react';
import { Modal, Button, Upload, Typography, Form, Row, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { openNotification } from '../../utils';
import ProposalService from '../../services/ProposalService';
import DocumentService from '../../services/DocumentService';

const { Text } = Typography;

const DocumentUploadModal: React.FC<any> = ({ proposalId, isModalVisible, setIsModalVisible, setIsStatusChange = false, type = 1 }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState<any[]>([]);
    const [documentName, setDocumentName] = useState<string | null>(null);
    const [preSignUrl, setPreSignUrl] = useState<string | null>(null);
    const [isImage, setIsImage] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (isModalVisible && proposalId) {
            fetchProposalDocument();
        }
    }, [isModalVisible, proposalId]);

    const fetchProposalDocument = async () => {
        try {
            const response = await ProposalService.get(proposalId);
            const documentId = response?.data?.documentId;

            if (documentId) {
                const responseDocument: any = await DocumentService.getDocument(documentId);
                const documentData = responseDocument?.data;

                if (documentData) {
                    setDocumentName(documentData.code);
                    setPreSignUrl(documentData.preSignUrl);

                    const fileType = documentData.code.split('.').pop().toLowerCase();
                    setIsImage(['jpg', 'jpeg', 'png'].includes(fileType));
                } else {
                    setDocumentName(null);
                    setPreSignUrl(null);
                    setIsImage(false);
                }
            }
        } catch (error) {
            openNotification('error', 'Hata', 'Belge bilgisi getirilemedi.');
            console.error(error);
        }
    };

    const handleFileChange = ({ fileList }: any) => {
        const newFile = fileList.slice(-1)[0]; // Sadece son yüklenen dosyayı al

        // Dosya boyutunu kontrol et
        if (newFile && newFile.size / 1024 / 1024 <= 1) {
            setFileList([newFile]); // Geçerli dosya ise fileList'i güncelle
            setDisabled(false); // Kaydet butonunu aktif yap
        } else {
            setFileList([]); // Geçersiz dosya ise fileList'i temizle
            setDisabled(true); // Kaydet butonunu devre dışı bırak
            message.error('Dosya boyutu en fazla 1 MB olabilir.');
        }
    };

    const handleUpload = async () => {
        if (fileList.length === 0) {
            message.warning("Lütfen bir dosya seçin.");
            return;
        }

        setIsLoading(true);

        try {
            const fileName = fileList[0].name;
            const uploadResponse: any = await DocumentService.postDocument(fileList[0].originFileObj, fileName);
            const documentId: any = uploadResponse?.data?.id;

            if (documentId) {
                await ProposalService.updateDocumentId(proposalId, { documentId });
                openNotification('success', 'Başarılı', 'Belge başarıyla yüklendi ve güncellendi.');
                setIsStatusChange(true);
                setIsModalVisible(false);
                setFileList([]);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            setIsModalVisible(false);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setFileList([]);
        setDocumentName(null);
        setPreSignUrl(null);
        setIsImage(false);
        setDisabled(true); // Modal kapatıldığında Kaydet butonunu devre dışı bırak
    };

    return (
        <Modal
            title={type === 1 ? <Text className="modal-title">Sözleşme Yükle</Text> : <Text className="modal-title">Sözleşme Görüntüle</Text>}
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            className="planlama-modal"
        >
            <Form layout="vertical">
                {type === 1 &&
                    <Text>Yüklediğiniz sözleşme, Kontrol X tarafından incelendikten sonra onaylanacaktır. Lütfen dosyanızın doğru ve eksiksiz olduğundan emin olun.</Text>
                }
                <br />
                {documentName && (
                    <div style={{ marginTop: 16 }}>
                        <Text color='blue' style={{ backgroundColor: '#fafafa' }} strong>Yüklü Dosya: {documentName}</Text>
                        {isImage && preSignUrl ? (
                            <div style={{ marginTop: 16, textAlign: 'center' }}>
                                <img src={preSignUrl} alt="Document Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                            </div>
                        ) : null}
                        <Row justify="center" style={{ marginTop: 16 }}>
                            {preSignUrl && (
                                <Button type="link" href={preSignUrl} target="_blank" download>
                                    Dosyayı İndir
                                </Button>
                            )}
                        </Row>
                    </div>
                )}

                <Upload
                    fileList={fileList}
                    beforeUpload={() => false}
                    onChange={handleFileChange}
                    accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                >
                    {type === 1 &&
                        <Button icon={<UploadOutlined />}>Dosya Seç</Button>
                    }
                </Upload>

                <Row justify={'center'} style={{ marginTop: 16 }}>
                    {type === 1 &&
                        <Button type="primary" loading={isLoading} onClick={handleUpload} className="d-button dark-button width-auto" disabled={disabled}>
                            Kaydet
                        </Button>
                    }
                </Row>
            </Form>
        </Modal>
    );
};

export default DocumentUploadModal;