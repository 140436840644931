import { http } from "../../../http-common";
import { IStatusRequest, IStatusResponse } from "../Type/Status";


const getAll = () => {
    return http.get<IStatusResponse>(`/statuses`);
};

const get = (id:number) => {
    return http.get<IStatusResponse>(`/statuses/${id}`);
};

const create = (data: IStatusRequest) => {
    return http.post<IStatusRequest>(`/statuses`, data);
};

const update = (id: number, data: IStatusRequest) => {
    return http.put<IStatusRequest>(`/statuses/${id}`, data);
};

const remove = (id: number) => {
    return http.delete<IStatusRequest>(`/statuses/${id}`);
};

const StatusService = {
    getAll,
    create,
    update,
    remove,
    get
};

export default StatusService;