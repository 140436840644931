import React, { useState, useEffect } from "react";
import { Button, Table, Modal, Form, Input, Select, message, Typography, Card, Row, Popconfirm } from "antd";
import OfferingService from "../../services/OfferingService";
import TrashIcon from '../../assets/icons/trash.svg';
import PenIcon from '../../assets/icons/pen.svg';
import { formatToTL, openNotification } from "../../utils";
import { SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

const OfferingList = () => {
    const [offerings, setOfferings] = useState<any[]>([]);
    const [filteredOfferings, setFilteredOfferings] = useState<any[]>([]); // Arama için filtrelenmiş liste
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingOffering, setEditingOffering] = useState<any | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>(""); // Arama state
    const [form] = Form.useForm();
    const { Title } = Typography;

    useEffect(() => {
        loadOfferings();
    }, []);

    useEffect(() => {
        const filtered = offerings.filter((offering) => {
            return (
                offering.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                // offering.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                offering.type?.toLowerCase().includes(searchTerm.toLowerCase())
            );
        });

        setFilteredOfferings(filtered);
    }, [searchTerm, offerings]); // searchTerm veya offerings değiştiğinde tetiklenecek

    const loadOfferings = async () => {
        setLoading(true);
        try {
            const response = await OfferingService.getAllOfferings();
            setOfferings(response.data);
            setFilteredOfferings(response.data); // İlk yüklemede tüm veriyi filtrelenmiş olarak da ayarla
        } catch (error) {
            message.error("Error loading offerings.");
        } finally {
            setLoading(false);
        }
    };

    const handleCreate = () => {
        setEditingOffering(null);  // Clear form for creation
        form.resetFields();
        setIsModalVisible(true);
    };

    const handleEdit = (offering: any) => {
        setEditingOffering(offering);
        form.setFieldsValue(offering);  // Populate form with offering data
        setIsModalVisible(true);
    };

    const handleDelete = async (id: number) => {
        try {
            await OfferingService.deleteOffering(id);
            openNotification('success', 'Başarılı', "Servis silindi.");
            loadOfferings();
        } catch (error: any) {
            if (error.response && error.response.status === 409) {
                openNotification('error', 'Hata', "Silme işlemi gerçekleştirilemez. Bu servis aktif olarak kullanılmaktadır.");
            } else {
                openNotification('error', 'Hata', "Servis silinemedi.");
            }
        }
    };

    const handleSubmit = async (values: any) => {
        try {
            if (editingOffering) {
                // Update existing offering
                await OfferingService.updateOffering(editingOffering.id, values);
                openNotification('success', 'Başarılı', "Servis güncellendi.");
            } else {
                // Create new offering
                await OfferingService.createOffering(values);
                openNotification('success', 'Başarılı', "Servis oluşturuldu.");
            }
            loadOfferings();
            setIsModalVisible(false);
        } catch (error) {
            openNotification('error', 'Başarısız', "İşlemde hata meydana geldi.");
        }
    };

    const columns = [
        {
            title: "Adı",
            dataIndex: "name",
            key: "name",
        },
        // {
        //     title: "Açıklama",
        //     dataIndex: "description",
        //     key: "description",
        // },
        {
            title: "Fiyat",
            dataIndex: "price",
            key: "price",
            render: (_: any, record: any) => {
                return <span>{formatToTL(record.price)}</span>
            }
        },

        {
            title: "İşlemler",
            key: "actions",
            render: (_: any, record: any) => (
                <div >
                    <Button
                        shape="circle"
                        icon={<img src={PenIcon} alt="edit" className="icon" />}
                        onClick={() => handleEdit(record)}
                        className='icon-button mr-10px'
                    />
                    <Popconfirm
                        title="Bu servisi silmek istediğinize emin misiniz?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button
                            shape="circle"
                            icon={<img src={TrashIcon} alt="delete" className="icon" />}
                            className='icon-button'
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <Card className="br-30px">
            <Row justify="space-between" align={'middle'} style={{ marginBottom: 16 }}>
                <Title style={{ margin: 0 }} level={4}>Grup Listesi</Title>
                <Row>
                    <Input
                        placeholder="Ara"
                        prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
                        className="d-input d-input-form mr-16px d-input-filter"
                        style={{ width: 200, marginRight: 16 }}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Button type="primary" className="d-button dark-button width-auto" onClick={handleCreate}>
                        Yeni Grup Ekle
                    </Button>
                </Row>
            </Row>
            <Table
                dataSource={filteredOfferings}
                columns={columns}
                rowKey="id"
                loading={loading}
            />
            <Modal
                title={editingOffering ? "Düzenle" : "Yeni Grup Ekle"}
                visible={isModalVisible}
                footer={null}
                onCancel={() => setIsModalVisible(false)}
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item
                        label="Adı"
                        name="name"
                        rules={[{ required: true, message: 'Lütfen grup adı giriniz' }]}
                    >
                        <Input />
                    </Form.Item>
                    {/* <Form.Item
                        label="Açıklama"
                        name="description"
                    >
                        <Input.TextArea rows={3} />
                    </Form.Item> */}
                    <Form.Item
                        label="Grup Fiyatı"
                        name="price"
                        rules={[{ required: true, message: 'Lütfen grup fiyatı giriniz' }]}
                    >
                        <Input
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onChange={(event) => {
                                const value = event.target.value.replace(/[^0-9]/g, '');
                                event.target.value = value;
                            }}
                        />
                    </Form.Item>
                    <Row justify={'center'}>
                        <Button type="primary" htmlType="submit" className="d-button dark-button width-auto">
                            {editingOffering ? "Güncelle" : "Kaydet"}
                        </Button>
                    </Row>
                </Form>
            </Modal>
        </Card>
    );
};

export default OfferingList;