import { Card, Col, Row, Button, Table, Select, Dropdown, Menu, DatePicker } from 'antd';
import { CalendarOutlined, WalletOutlined, DatabaseOutlined, DownOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import moment from 'moment';
import './Vallet.scss';
import EarningIcon from '../../assets/icons/earning.svg';
import NoteIcon from '../../assets/icons/note.svg';
import ValletIcon from '../../assets/icons/vallet2.svg';
import ProposalsList from '../../components/Vallet/ProposalsList';
import ProposalsCountCard from '../../components/Vallet/ProposalsCountCard';
import ContractCountCard from '../../components/Vallet/ContractCountCard';
import RenuewCard from '../../components/Vallet/RenuewCard';
import EarningsPerTransactionCard from '../../components/Vallet/EarningsPerTransactionCard';

const { RangePicker } = DatePicker;

const dataSource = [
  {
    key: '1',
    date: '01/08/2024 10:30',
    description: 'Lorem ipsum dolor sit amet',
    amount: '1.500 TL',
  },
  {
    key: '2',
    date: '02/08/2024 10:30',
    description: 'Lorem ipsum dolor sit amet',
    amount: '5.000 TL',
  },
  {
    key: '3',
    date: '03/08/2024 10:30',
    description: 'Lorem ipsum dolor sit amet',
    amount: '3.000 TL',
  },
  {
    key: '4',
    date: '04/08/2024 10:30',
    description: 'Lorem ipsum dolor sit amet',
    amount: '1.500 TL',
  },
];

const Vallet = () => {
  const [activeTab, setActiveTab] = useState('gelir');
  const { Option } = Select;
  const [value, setValue] = useState('10.450 TL');
  const [filteredData, setFilteredData] = useState<any>(dataSource);


  const menu = (
    <Menu>
      <Menu.Item key="2">Ödeme Görüntüle</Menu.Item>
    </Menu>
  );

  const columns: any = [
    {
      title: 'Ödeme Tarihi',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ödeme Tutarı',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'İşlemler',
      key: 'actions',
      align: 'right',
      render: () => (
        <Dropdown overlay={menu} trigger={['hover']}>
          <Button type='default' className='d-button action-button width-auto'>
            İşlemler
          </Button>
        </Dropdown>
      ),
    },
  ];

  // Tarih seçildiğinde filtreleme yapılacak
  const onDateChange = (dates: any, dateStrings: [string, string]) => {
    if (!dates) {
      setFilteredData(dataSource); // Tarih seçilmezse tüm verileri göster
    } else {
      const filtered = dataSource.filter(item => {
        const itemDate = moment(item.date, 'DD/MM/YYYY HH:mm');
        return itemDate.isBetween(dates[0], dates[1], null, '[]');
      });
      setFilteredData(filtered);
    }
  };

  return (
    <div className='p-20px vallet'>
      <Row gutter={[16, 16]}>
        <Col lg={6} md={12} xs={24}>
           <EarningsPerTransactionCard/>
        </Col>

        <Col lg={6} md={12} xs={24}>
          <ProposalsCountCard />
        </Col>

        <Col lg={6} md={24} xs={24}>
          <ContractCountCard />
        </Col>
        <Col lg={6} md={24} xs={24}>
          <RenuewCard/>
        </Col>
      </Row>

      <ProposalsList />
    </div>
  );
};

export default Vallet;