import React, { useEffect, useRef, useState } from 'react';
import './Carousel.scss';
import image2 from './../assets/auth/references/image-2.png';
import image3 from './../assets/auth/references/image-3.png';
import image4 from './../assets/auth/references/image-4.png';
import image5 from './../assets/auth/references/image-5.png';
import image7 from './../assets/auth/references/image-7.png';
import image8 from './../assets/auth/references/image-8.png';
import image9 from './../assets/auth/references/image-9.png';
import image10 from './../assets/auth/references/image-10.png';
import image11 from './../assets/auth/references/image-11.png';
import image12 from './../assets/auth/references/image-12.png';
import image13 from './../assets/auth/references/image-13.png';
import image14 from './../assets/auth/references/image-14.png';

const images = [
  image2, image3, image4, image5,
  image7, image8, image9, image10, image11, image12,
  image13, image14,
];

const AuthCarousel = () => {
  const carouselTrackRef = useRef<HTMLDivElement>(null);
  const [duplicatedImages, setDuplicatedImages] = useState(images.concat(images));

  return (
    <div className="carousel">
      <div className="carousel-track" ref={carouselTrackRef}>
        {duplicatedImages.map((src, index) => (
          <div className="carousel-item" key={index}>
            <img src={src} alt={`Image ${index + 1}`} className="carousel-image" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AuthCarousel;