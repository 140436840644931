import { http } from "../../../http-common";
import { INoteRequest, INoteRequestEdit, INoteResponse } from "../Type/Note";


const getAll = (entityName:string) => {
    return http.get<INoteResponse>(`/accounts/me/notes?entityName=${entityName}`, );
};

const create = (data: INoteRequest) => {
    return http.post<INoteRequest>(`/accounts/me/notes`, data);
};

const update = (id: number, data: INoteRequestEdit) => {
    return http.put<INoteRequest>(`/notes/${id}`, data);
};

const remove = (id: number) => {
    return http.delete<INoteRequest>(`/notes/${id}`);
};

const NoteService = {
    getAll,
    create,
    update,
    remove
};

export default NoteService;