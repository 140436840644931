import React, { useEffect, useState } from 'react';
import { Table, Tag } from 'antd';
import UserService from '../../services/UserService';
import './profile.scss';
import { formatDate } from '../../utils';

const UserAttemps: React.FC<any> = (profileData) => {
    const [attemp, setAttemp] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    useEffect(() => {
        fetchAttempts(pagination.current, pagination.pageSize);
    }, []);

    const fetchAttempts = (page: number, pageSize: number) => {
        setLoading(true);
        let email = localStorage.getItem('email');
        if (profileData?.profileData?.email) {
            email = profileData?.profileData?.email??'';
        } 
        if (email) {
            UserService.getAttemp({
                page: page - 1,
                size: pageSize,
                email: email, // Email'i isteğe dahil et
            })
                .then((response: any) => {
                    const { content, totalElements } = response.data;
                    setAttemp(content);
                    setPagination({
                        ...pagination,
                        current: page,
                        pageSize: pageSize,
                        total: totalElements,
                    });
                })
                .catch((error: any) => {
                    console.error('Profil verilerini çekerken hata oluştu!', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

    };

    const handleTableChange = (pagination: any) => {
        fetchAttempts(pagination.current, pagination.pageSize);
    };

    const columns = [
        {
            title: 'Lokasyon',
            dataIndex: 'location',
            key: 'location',
            render: (_: any, record: any) => `${record.city},${record.country}`,
        },
        {
            title: 'IP Adres',
            dataIndex: 'ipAddress',
            key: 'ipAddress',
        },
        {
            title: 'Statüs',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => (
                <Tag color={status === 'SUCCESS' ? 'green' : 'red'}>
                    {status === 'SUCCESS' ? 'Başarılı Giriş' : 'Başarısız Giriş'}
                </Tag>
            ),
        },
        {
            title: 'Tarih',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string) => <span>{text ? formatDate(text) : 'Bilinmiyor'}</span>,
        },
    ];

    return (
        <div className='profile-attemp'>
            <Table
                scroll={{ x: 768 }}
                columns={columns}
                dataSource={attemp}
                rowKey="id"
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                }}
                loading={loading}
                onChange={handleTableChange}
            />
        </div>
    );
};

export default UserAttemps;
