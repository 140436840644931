import React, { useState, useEffect, useCallback } from 'react';
import {
  Table, Button, Dropdown, Menu, Input, Row, Col, Tag,
  Typography, Card, Avatar, Modal, Form, InputNumber, Tooltip,
  Divider, Select, Checkbox
} from 'antd';
import { InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import ProposalService from '../../services/ProposalService';
import { capitalizeWords, formatDate, formatToTL, openNotification } from '../../utils';
import CompanyService from '../../services/CompanyService';
import StatusService from '../../modules/Status/Service/StatusService';
import tinycolor from 'tinycolor2';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import PlanningModal from '../Companies/PlanningModal';
import DocumentUploadModal from '../Companies/UploadModal';
import './ProposalCompanySelect.scss';
import ProposalView from './ProposalView';
import ReportInvoiceModal from '../ProjectFollowUp/ReportInvoiceModal';

const { Title, Text } = Typography;

const ProposalList: React.FC<any> = ({ defaultStatusIds = [], type = 1 }) => {
  const [filteredProposals, setFilteredProposals] = useState<any[]>([]);
  const [searchText, setSearchText] = useState('');
  const [isDiscountModalVisible, setIsDiscountModalVisible] = useState(false);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState<any | null>(null);
  const [discountedPrice, setDiscountedPrice] = useState<number | null>(null);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1); // Başlangıç sayfasını 1 olarak ayarladık
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const logoCache = new Map();
  const pendingRequests = new Map();
  const [selectedProposalId, setSelectedProposalId] = useState<number | null>(null);
  const [isPlanningModalVisible, setIsPlanningModalVisible] = useState(false);
  const [selectedStatusIds, setSelectedStatusIds] = useState<number[]>([]);
  const [roles, setRoles] = useState<any[]>([]);
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>('DESC');
  const [onlyMyCompanies, setOnlyMyCompanies] = useState<boolean>(false); // State for the checkbox
  const userId = localStorage.getItem('id');
  const [invoiceModalData, setInvoiceModalData] = useState<any>();
  const [invoiceModalType, setInvoiceModalType] = useState<any>();
  const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState(false);


  useEffect(() => {
    StatusService.getAll()
      .then((response: any) => {
        const filteredStatuses = response.data.filter((status: any) => defaultStatusIds.includes(status.id));
        setStatuses(filteredStatuses);
      })
      .catch((error) => console.error("Statüler yüklenirken hata oluştu:", error));
  }, [defaultStatusIds]);

  useEffect(() => {
    fetchProposals();
  }, [
    currentPage,
    pageSize,
    sortField,
    sortDirection,
    isPlanningModalVisible,
    isUploadModalVisible,
    searchText,
    defaultStatusIds,
    selectedStatusIds,
    onlyMyCompanies,
    userId,
  ]);

  useEffect(() => {
    if (!isUploadModalVisible) {
      fetchProposals();
    }
  }, [isUploadModalVisible]);

  useEffect(() => {
    const storedRoles = localStorage.getItem('roles');
    const parsedRoles = storedRoles ? JSON.parse(storedRoles) : [];
    setRoles(parsedRoles);
  }, []);

  // Reset currentPage when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [onlyMyCompanies]);

  const fetchProposals = async () => {
    logoCache.clear();
    pendingRequests.clear();

    const storedRoles = await localStorage.getItem('roles');
    let parsedRoles = storedRoles ? JSON.parse(storedRoles) : [];
    parsedRoles = await parsedRoles.map((role: any) => role.name);

    const fetchMethod = parsedRoles.includes('ADMIN') ? await ProposalService.getAll : await ProposalService.getMe;
    const params: any = {
      page: currentPage - 1, // Eğer API 0 tabanlı sayfa kullanıyorsa
      size: pageSize,
      company: searchText,
      statuIds: selectedStatusIds.length > 0 ? selectedStatusIds.join(',') : defaultStatusIds.join(','),
      sortField: sortField,
      sortDirection: sortDirection,
    };

    if (onlyMyCompanies && userId) {
      params.accountId = userId;
    }

    await fetchMethod(params)
      .then(async (response: any) => {
        console.log('Gelen Proposals:', response.data.content); // Gelen verileri kontrol edin
        const enrichedProposals = await enrichProposalsWithLogos(response.data.content);
        const filteredByStatus = type === 2 ? enrichedProposals.filter((proposal: any) => proposal.statusId != null) : enrichedProposals;
        console.log('Filtrelenmiş Proposals:', filteredByStatus); // Filtreleme sonrası
        setFilteredProposals(filteredByStatus);
        setTotalItems(response.data.totalElements);
      })
      .catch((error: any) => {
        console.error('Teklif verileri alınırken hata oluştu:', error);
      });
  };

  useEffect(() => {
    console.log('Filtered Proposals State:', filteredProposals);
  }, [filteredProposals]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // setCurrentPage(pagination.current);
    // setPageSize(pagination.pageSize || 10);
    setSortField(sorter.field || 'createdAt');
    setSortDirection(sorter.order === 'ascend' ? 'ASC' : 'DESC');
  };

  const enrichProposalsWithLogos = async (proposals: any[]) => {
    return Promise.all(
      proposals.map(async (proposal) => {
        const { company } = proposal;
        if (company.imageId) {
          if (logoCache.has(company.imageId)) {
            return { ...proposal, company: { ...company, logoUrl: logoCache.get(company.imageId) } };
          } else if (pendingRequests.has(company.imageId)) {
            await pendingRequests.get(company.imageId);
            return { ...proposal, company: { ...company, logoUrl: logoCache.get(company.imageId) } };
          } else {
            const logoPromise = CompanyService.getImage(company.imageId)
              .then((logoResponse) => {
                const logoUrl = logoResponse.data.preSignUrl;
                logoCache.set(company.imageId, logoUrl);
                pendingRequests.delete(company.imageId);
                return logoUrl;
              })
              .catch((error) => {
                console.error("Logo yüklenirken hata oluştu:", error);
                pendingRequests.delete(company.imageId);
                return null;
              });

            pendingRequests.set(company.imageId, logoPromise);

            const logoUrl = await logoPromise;
            return { ...proposal, company: { ...company, logoUrl } };
          }
        }
        return proposal;
      })
    );
  };

  const handleMenuClick = (key: string, record: any) => {
    switch (key) {
      case 'download':
        console.log('Teklif indir:', record);
        break;
      case 'revise':
        console.log('Teklifi revize et:', record);
        break;
      case 'applyDiscount':
        console.log('İndirim uygula:', record);
        setSelectedProposal(record);
        setIsDiscountModalVisible(true);
        break;
      case 'cancelProposal':
        handleUpdateStatus(record.id, 2);
        break;
      case 'uploadDocument':
        setSelectedProposalId(record.id);
        setSelectedProposal(record);
        setIsUploadModalVisible(true);
        break;
      case 'requestPlanningDate':
        setSelectedProposalId(record.id);
        setIsPlanningModalVisible(true);
        break;
      case 'sendProposal':
        handleUpdateStatus(record.id, 3);
        break;
      default:
        break;
    }
  };

  const handleUpdateStatus = async (id: any, statusId: any) => {
    let proposalData: any = {
      statusId: statusId,
    };
    try {
      await ProposalService.update(id, proposalData);
      openNotification('success', 'Başarılı', 'Teklif Güncellendi.');
      fetchProposals();

    } catch (error) {
      openNotification('error', 'Hata', 'Teklif iptal edilirken bir hata oluştu.');
      console.error(error);
    }
  };

  const handleModalOk = async () => {
    if (selectedProposal && discountedPrice !== null) {
      try {
        const response = await ProposalService.updatePrice(selectedProposal.id, { newTotalPrice: discountedPrice });
        console.log('Fiyat başarıyla güncellendi:', response.data);

        fetchProposals();
        setIsDiscountModalVisible(false);
        setDiscountedPrice(null);
        form.setFieldValue('discountedPrice', null);
      } catch (error) {
        console.error('Fiyat güncellenirken hata oluştu:', error);
      }
    }
  };

  const handleModalCancel = () => {
    setIsDiscountModalVisible(false);
    setIsUploadModalVisible(false);
    setDiscountedPrice(null);
    setIsPlanningModalVisible(false);
  };

  const getStatusNameById = (statusId: number) => {
    const status = statuses.find((status: any) => status.id === statusId);
    return status ? status.name : 'Bilinmiyor';
  };

  const getStatusColorById = (statusId: number) => {
    const status = statuses.find((status: any) => status.id === statusId);
    return status ? `${status.color}` : '#fdfdfd';
  };
  const handleOpenInvoiceModal = (proposal: any, mode: "edit" | "view") => {
    if (!proposal.id) {
      console.error("Proposal ID is missing");
      openNotification("error", "Hata", "Teklif ID'si bulunamadı.");
      return;
    }

    setSelectedProposalId(proposal.id);
    setInvoiceModalData({
      invoiceAmount: proposal.invoiceAmount || '',
      reportUrl: proposal.reportUrl || '',
      financialInfoUrl: proposal.financialInfoUrl || '',
      invoiceDate: proposal.invoiceDate || '',
    });

    setIsInvoiceModalVisible(true);

    // Pass the type ("edit" or "view") dynamically
    setInvoiceModalType(mode);
  };

  const renderAllAttributesTooltipContent = (attributes: any) => {
    const mergeDiscounts = (discounts: any) => {
      const merged: any = [];
      discounts.forEach((discount: any) => {
        const existing = merged.find((d: any) => d.price === discount.price);
        if (existing) {
          existing.quantity += discount.quantity;
        } else {
          merged.push({ ...discount });
        }
      });
      return merged;
    };



    return (
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <Divider style={{ borderColor: '#fff' }} />
        {attributes.map((attribute: any, index: any) => {
          // Servis ücreti ve attribute toplamını hesaplıyoruz
          const totalAttributePrice = attribute.discounts
            .filter((discount: any) => discount.type !== 'offering') // Offering olmayanlar attribute fiyatlarıdır
            .reduce((total: number, discount: any) => total + discount.price * discount.quantity, 0);

          const serviceFee = attribute.discounts.find((discount: any) => discount.type === 'offering')?.price ?? 0;

          const serviceFeeDifference = serviceFee > totalAttributePrice ? serviceFee - totalAttributePrice : 0;

          return (
            <div key={index} style={{ marginBottom: '8px' }} className="mt-16px">
              <Text strong style={{ color: '#fff' }}>{attribute.attributeName}</Text>
              {mergeDiscounts(attribute.discounts).map((discount: any, idx: any) => (
                <p key={idx}>
                  {discount.type === 'offering' ? (
                    <></>
                  ) : (
                    <>
                      {discount.quantity} adet - {formatToTL(discount.price)}
                    </>
                  )}
                </p>
              ))}
              {serviceFeeDifference > 0 && (
                <p style={{ color: '#f0ad4e', marginTop: '8px' }}>
                  Servis Ücreti = {formatToTL(serviceFeeDifference)}
                </p>
              )}
              {index < attributes.length - 1 && <Divider style={{ borderColor: '#fff' }} />}
            </div>
          );
        })}
      </div>
    );
  };

  const renderPriceTooltipContent = (priceCalculationResponse: any) => {
    if (!priceCalculationResponse || !priceCalculationResponse.offeringLogs) {
      return <Text>Veri mevcut değil</Text>; // Fallback mesaj
    }

    return (
      <div style={{ maxHeight: '300px', overflowY: 'auto', padding: '10px' }}>
        {priceCalculationResponse.offeringLogs.map((log: any, index: number) => (
          <div key={index} style={{ marginBottom: '15px' }}>
            <strong>{log.offeringName}</strong>
            <ul style={{ paddingLeft: '20px', marginTop: '10px' }}>
              {log.attributes.map((attribute: any, idx: number) => (
                <li key={idx}>
                  <span>
                    {attribute.attributeName} - {attribute.quantity} adet - {formatToTL(attribute.price)}
                  </span>
                  <p style={{ color: '#999', fontSize: '12px' }}>
                    {attribute.description}
                  </p>
                </li>
              ))}
            </ul>
            {log.serviceFeeDifference > 0 && (
              <p style={{ color: '#f0ad4e' }}>
                Servis Fiyatı Farkı: {formatToTL(log.serviceFeeDifference)}
              </p>
            )}
            <p>
              Toplam: {formatToTL(log.finalPrice)}
            </p>
            {index !== priceCalculationResponse.offeringLogs.length - 1 && (
              <Divider style={{ background: 'gray', height: '2px' }} />
            )}
          </div>
        ))}
      </div>
    );
  };

  const columns = [
    {
      title: 'Firma Adı',
      dataIndex: ['company', 'name'],
      key: 'companyName',
      render: (text: any, record: any) => {
        const displayText = text.length > 28 ? `${text.substring(0, 28)}...` : text;
        return (
          <div className="d-flex align-items-center">
            <div className='avatar-image-logo' style={{ marginRight: 10 }}>
              {record?.company?.logoUrl ? (
                <img
                  src={record?.company?.logoUrl}
                  alt={record?.company?.name}
                  className='avatar-image-logo'
                  style={{ borderRadius: '50%' }}
                />
              ) : (
                <Avatar className='avatar-image-logo' style={{ backgroundColor: '#f0f0f0', color: '#000' }}>
                  {record?.company?.name.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </div>

            <Tooltip title={text.toUpperCase()}>
              <span >{displayText.toUpperCase()}</span>
            </Tooltip>
          </div>
        )
      },
    },
    ...(roles && roles.some((role: any) => role.name === 'ADMIN') ? [{
      title: "İş Ortağı Ad Soyad",
      key: "accountOwner",
      render: (text: any, record: any) => record.account ? `${record.account.firstName} ${record.account.lastName}` : "Bilgi Yok",
    }] : []),
    {
      title: 'Fiyat',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (price: number, record: any) => (
        price ? (
          <div className="price-tooltip">
            <span>{formatToTL(price)}</span>
            <Tooltip
              title={renderPriceTooltipContent(record.priceCalculationResponse)}
              overlayStyle={{ maxWidth: '450px', maxHeight: '300px' }}
            >
              <InfoCircleOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
            </Tooltip>
          </div>
        ) : <span>-</span>
      ),
    },
    {
      title: 'Kontrol Adresi',
      dataIndex: ['address', 'addressLine1'],
      key: 'controlAddress',
      width: 200,
      render: (text: any, record: any) => {
        const addressLine1 = capitalizeWords(record?.address?.addressLine1 || '');
        const city = capitalizeWords(record?.address?.city || '');
        let combinedText;
        if (addressLine1 !== '' && city !== '') {
          combinedText = `${addressLine1}, ${city}`;
        } else {
          combinedText = '-';
        }

        return combinedText && combinedText.length > 40 ? (
          <Tooltip title={combinedText}>
            <Text>{combinedText.slice(0, 40)}...</Text>
          </Tooltip>
        ) : (
          <Text>{combinedText || '-'}</Text>
        );
      },
    },
    {
      title: 'Teklif Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (date: string) => date ? <Text>{formatDate(date)}</Text> : <span>-</span>,
    },
    {
      title: 'Teklif No',
      dataIndex: 'proposalNumber',
      key: 'proposalNumber',
      render: (proposalNumber: string) => (
        proposalNumber ?
          <span style={{ color: 'blue' }}>
            {proposalNumber}
          </span> : '-'
      ),
    },
    {
      title: 'Statü',
      dataIndex: 'statusId',
      key: 'status',
      render: (statusId: number) => {
        const color = tinycolor(getStatusColorById(statusId));
        const transparentColor = color.setAlpha(0.1).toRgbString();
        const transparentColorBorder = color.setAlpha(0.5).toRgbString();

        return (
          <Tag
            style={{
              color: getStatusColorById(statusId),
              backgroundColor: transparentColor,
              borderColor: transparentColorBorder,
              borderRadius: '16px',
              padding: '5px 10px',
              fontSize: '14px',
              display: 'inline-block',
              minWidth: '100px',
              textAlign: 'center',
              fontWeight: 500,
            }}
          >
            {getStatusNameById(statusId)}

          </Tag>
        )
          ;
      }
    },
    {
      title: '',
      key: 'action',
      render: (text: string, record: any) => (
        <Dropdown disabled={record.proposalNumber == null}
          overlay={
            <Menu onClick={({ key }) => handleMenuClick(key, record)}>
              {type === 1 && record.statusId !== 1 && record.statusId !== null &&
                <Menu.Item key="download"><ProposalView proposalId={record.id}></ProposalView></Menu.Item>
              }
              {record.statusId !== null && (record.statusId === 3 || record.statusId === 11) && record.statusId !== 2 &&
                <Menu.Item key="revise" onClick={() => handleOfferEditNavigation(record.id)}>Revize Et</Menu.Item>
              }
              {type === 2 && record.statusId === 6 &&
                <Menu.Item key="requestPlanningDate">Planlama Talebi Oluştur</Menu.Item>
              }
              {type === 1 && (record.statusId === 3 || record.statusId === 10) &&
                < Menu.Item key="uploadDocument">Sözleşme Yükle</Menu.Item>
              }
              {type === 1 && (record.statusId === 3 || record.statusId === 11) &&
                <Menu.Item key="applyDiscount">İndirim Uygula</Menu.Item>
              }
              {type === 1 && record.statusId === 11 &&
                <Menu.Item key="cancelProposal">Teklifi İptal Et</Menu.Item>
              }
              {type === 1 && record.statusId === 11 &&
                <Menu.Item key="sendProposal">Teklifi Gönder</Menu.Item>
              }
              {type === 2 && (record.statusId === 4 || record.statusId === 7 || record.statusId === 8) &&
                <Menu.Item key="reportView" disabled={record.financialInfoUrl == null || record.invoiceAmount == null || record.reportUrl == null} onClick={() => handleOpenInvoiceModal(record, 'view')}>Rapor & Fatura Görüntüle</Menu.Item>
              }
            </Menu >
          }
        >
          <Button type='default' className="d-button action-button" disabled={record.proposalNumber == null || record.statusId == 5}>
            İşlemler
          </Button>
        </Dropdown >
      ),
    },
  ];

  const handleSearch = useCallback(debounce((value: string) => {
    setSearchText(value);
    setCurrentPage(1); // Arama yapıldığında sayfayı 1'e sıfırla
  }, 300), []);

  const handleStatusChange = (value: number[]) => {
    setSelectedStatusIds(value);
    setCurrentPage(1); // Statü değiştiğinde sayfayı 1'e sıfırla
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target?.value?.toLowerCase();
    handleSearch(searchValue);
  };

  const handleOfferEditNavigation = (id: number) => {
    navigate(`/proposals/create?proposalId=${id}`);
  };

  const handleNavigateCreate = () => {
    navigate(`/proposals/create`);
  };

  const handleCloseInvoiceModal = () => {
    setIsInvoiceModalVisible(false);
  };

  return (
    <>
      <Card className='br-30px mb-120px proposal-card'>
        <Row gutter={[16, 16]} align="middle" justify="space-between" className="mb-20px">
          <Col span={12}>
            <Row align="middle">
              {type === 1 ?
                <Title level={4} style={{ margin: 0 }}>
                  Teklif Takip Listesi
                </Title>
                :
                <Title level={4} style={{ margin: 0 }}>
                  İş Takip Listesi
                </Title>
              }
              {roles && roles.some((role: any) => role.name === 'ADMIN') && (
                <Checkbox
                  checked={onlyMyCompanies}
                  onChange={(e) => setOnlyMyCompanies(e.target.checked)}
                  style={{ marginLeft: 16 }}
                >
                  Sadece kendi tekliflerimi listele
                </Checkbox>
              )}
            </Row>
          </Col>
          <Col span={12}>
            <Row justify={'end'} align="middle">
              <Select
                mode="multiple"
                placeholder="Statü seçin"
                className='d-input-filter'
                style={{ width: 200, minHeight: 48, height: 'auto', marginRight: 10, padding: 10, paddingLeft: 10 }}
                onChange={handleStatusChange}
              >
                {statuses.map((status) => (
                  <Select.Option key={status.id} value={status.id}>
                    {status.name}
                  </Select.Option>
                ))}
              </Select>
              <Input
                placeholder="Ara"
                prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
                className="d-input d-input-filter mr-8px d-input-filter"
                style={{ width: 200 }}
                onChange={handleInputChange}
              />
              {type === 1 &&
                <Button type='primary' className='d-button dark-button width-auto ml-10px' onClick={handleNavigateCreate}>
                  Teklif Oluştur
                </Button>
              }
            </Row>
          </Col>
        </Row>
        <Table
          scroll={{ x: 768 }}
          columns={columns}
          dataSource={filteredProposals}
          rowKey={(record, key) => record.id || key}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalItems,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize || 10);
            },
          }}
          onChange={handleTableChange}
        />

        {/* İndirim Modal */}
        <Modal
          title="İndirim Uygula"
          visible={isDiscountModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          footer={null}
        >
          {selectedProposal && (
            <>
              <p>İndirimli fiyat teklifinizi hızlı bir şekilde hazırlayın.</p>
              <div style={{ marginBottom: 16 }}>
                <Text strong>Teklif Fiyatı</Text>
                <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
                  {formatToTL(selectedProposal?.totalPrice)}
                </div>
              </div>
              <Form layout="vertical" form={form}>
                <Form.Item
                  label={`İndirim tutarını belirleyin (Kalan indirim hakkı: ${formatToTL(selectedProposal.maxDiscountPrice)})`}
                  name="discountAmount"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (
                          !value ||
                          (value >= 0 && value <= selectedProposal.maxDiscountPrice)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            `İndirim tutarı, 0 ile ${formatToTL(
                              selectedProposal.maxDiscountPrice
                            )} arasında olmalıdır!`
                          )
                        );
                      },
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={selectedProposal.maxDiscountPrice}
                    step={1}
                    precision={0}
                    value={discountedPrice} // Input değeri
                    onChange={(value) => {
                      // Eğer maksimum değeri aşarsa, otomatik olarak maksimum değeri ayarla
                      const adjustedValue =
                        value > selectedProposal.maxDiscountPrice
                          ? selectedProposal.maxDiscountPrice
                          : value || 0;
                      form.setFieldValue('discountAmount', adjustedValue);
                      setDiscountedPrice(
                        selectedProposal.totalPrice - adjustedValue
                      );
                    }}
                    style={{ width: '100%' }}
                    className="d-input d-input-form d-flex align-items-center"
                    placeholder="İndirim Tutarı Giriniz"
                  />
                </Form.Item>
                <div style={{ marginTop: 16 }}>
                  <Text strong>Yeni Teklif Tutarı</Text>
                  <div
                    style={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                      color: '#52c41a',
                      marginTop: '8px',
                    }}
                  >
                    {discountedPrice !== null
                      ? formatToTL(discountedPrice)
                      : formatToTL(selectedProposal.totalPrice)}
                  </div>
                </div>
                {/* <Text type="secondary">
          Maksimum İndirim Sınırı: Teklif fiyatının %10'udur. (Kalan Maksimum
          İndirim: {formatToTL(selectedProposal.maxDiscountPrice)})
        </Text> */}
              </Form>
              <div style={{ marginTop: 24, textAlign: 'center' }}>
                <Button
                  type="primary"
                  onClick={handleModalOk}
                  disabled={selectedProposal.isTotalPriceSet}
                  className="d-button dark-button width-auto p-20px"
                >
                  İndirimli Teklifi Oluştur
                </Button>
              </div>
            </>
          )}
        </Modal>
        {/* Planlama Modal */}
        {isPlanningModalVisible && (
          <PlanningModal
            setIsModalVisible={setIsPlanningModalVisible}
            isModalVisible={isPlanningModalVisible}
            proposalId={selectedProposalId!}
            isCustomer={true}
          />
        )}
        {/* Upload Modal */}
        {isUploadModalVisible && (
          <DocumentUploadModal
            setIsModalVisible={setIsUploadModalVisible}
            isModalVisible={isUploadModalVisible}
            proposalId={selectedProposalId!}
            isCustomer={true}
          />
        )}
      </Card>
      {isInvoiceModalVisible && selectedProposalId && (
        <ReportInvoiceModal
          visible={isInvoiceModalVisible}
          onClose={handleCloseInvoiceModal}
          onSubmit={() => null}
          initialValues={invoiceModalData}
          type={invoiceModalType}
        />
      )}
    </>
  );
};

export default ProposalList;
