import React from 'react';
import Chart from 'react-apexcharts';

const JobTrackingChart: React.FC = () => {
    const series = [
        {
            name: 'İş Takibi',
            data: [100, 50, 15, 8]  // Her bir sütunun değeri
        }
    ];

    const options: any = {
        chart: {
            type: 'bar',
            height: 170,  // Grafiğin yüksekliğini ayarladık
            background: '#ffffff',
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',  // Bar genişliğini ayarladık
                borderRadius: 8,
                distributed: true,
                dataLabels: {
                    position: 'top',
                    offsetY: -15  // Veri ile bar arasındaki boşluğu artırdık
                },
            }
        },
        colors: ['#6E7B8B', '#D1D1D1', '#A9A9A9', '#4A90E2'], // Her sütun için renkler
        fill: {
            type: ['solid', 'pattern', 'pattern', 'solid'], // İlk ve son sütun düz renk, diğerleri desenli
            pattern: {
                style: ['solid', 'slantedLines', 'dots', 'solid'], // Desen türleri
                width: 6,
                height: 6,
                strokeWidth: 2,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: (val: number) => `${val}`, // Yalnızca değeri göster
            style: {
                fontSize: '12px',
                colors: ['#001F3F'],
            }
        },
        xaxis: {
            categories: ['Tamamlanan', 'Devam Eden', 'Teklif Aşamasında', 'Teklif Oluşturuldu'], // X ekseni etiketleri
            labels: {
                show: true,
                trim: false, // Uzun etiketlerin görünmesi için kesmeyi kapatıyoruz
                style: {
                    fontSize: '10px',
                    colors: ['#001F3F']
                }
            }
        },
        yaxis: {
            show: false,  // Y eksenini göster
            labels: {
                style: {
                    fontSize: '12px',
                    colors: ['#001F3F']
                }
            }
        },
        legend: {
            show: false
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (val: number) => `${val} adet`  // Tooltip içinde değer gösterimi
            }
        },
    };

    return (
        <div>
            <h3 style={{ color: '#001F3F', margin: '0', fontSize: 24 }}>İş Takibi</h3>
            <Chart options={options} series={series} type="bar" height={190} />
        </div>
    );
};

export default JobTrackingChart;