import {http} from "../http-common";
import { IRoleResponseData, IRoleRequestData } from "../types/Role";

const getAll = () => {
  return http.get<Array<IRoleResponseData>>("/roles");
};
const get = (id: Number) => {
  return http.get<IRoleResponseData>(`/roles/${id}`);
};

const create = (data: IRoleRequestData) => {
  return http.post<IRoleRequestData>("/roles", data);
};

const update = (id: any, data: IRoleRequestData) => {
  return http.put<any>(`/roles/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/roles/${id}`);
};

const RoleService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default RoleService;