import { Button, Divider, Row, Col, Typography, Checkbox } from 'antd';
import React, { useState, useEffect } from 'react';
import { IRoleResponseData } from "../../types/Role";
import PermissionService from '../../services/PermissonService';
import RoleService from '../../services/RoleService';
import { IPermissionResponseData } from '../../types/Permission';

const { Title } = Typography;

interface RoleDetailProps {
    id: number;
}

const RoleDetail: React.FC<RoleDetailProps> = ({ id }) => {
    const [role, setRole] = useState<IRoleResponseData | null>(null);
    const [groupedPermissions, setGroupedPermissions] = useState<{ [key: string]: IPermissionResponseData[] }>({});
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);

    useEffect(() => {
        getPermissions();
    }, [id]);

    const getRole = (id: number) => {
        RoleService.get(id)
            .then((response: any) => {
                setRole(response.data);
                const ids: number[] = response.data.permissions.map((permission: any) => permission.id);
                setSelectedPermissions(ids);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const getPermissions = () => {
        PermissionService.getAll()
            .then((response: any) => {
                const sortedData = [...response.data].sort((a, b) => a.name.localeCompare(b.name));
                groupPermissions(sortedData);
                getRole(id);
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const groupPermissions = (permissions: IPermissionResponseData[]) => {
        const grouped = permissions.reduce((acc, permission) => {
            const [group] = permission.name.split('.');
            if (!acc[group]) {
                acc[group] = [];
            }
            acc[group].push(permission);
            return acc;
        }, {} as { [key: string]: IPermissionResponseData[] });

        setGroupedPermissions(grouped);
    };

    return (
            <div className="role-detail-content">
                <h4>Rol Adı</h4>
                <p>{role?.name}</p>
                <Divider />
                <h4>Rol Permission</h4>
                {Object.keys(groupedPermissions).map(group => (
                    <div key={group}>
                    <Divider>{group}</Divider>
                        <Row gutter={[16, 16]} className="permission-row">
                            {groupedPermissions[group].map(permission => (
                                <Col key={permission.id} span={6}>
                                    <Checkbox
                                        value={permission.id}
                                        checked={selectedPermissions.includes(permission.id)}
                                        disabled
                                    >
                                        {permission.name.split('.')[1]}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ))}
            </div>
    );
};

export default RoleDetail;
