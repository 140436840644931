import { Card, Row, Typography } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ArrowIcon from '../../assets/icons/arrow-up.svg';
import ProposalService from '../../services/ProposalService';

const { Text, Title } = Typography;

const ProposalsCountCard = () => {
    const [counts, setCounts] = useState<any>({})

    const fetchProposals = useCallback(async () => {
        try {
            let response: any = await ProposalService.getRenewSummary();
            setCounts(response.data)
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        fetchProposals()
    }, [])



    return (
        // <Link to="/proposals?activeKey=2">
            <Card bordered={false} className="dashboard-card" bodyStyle={{ padding: 10 }}>
                <div className='dashboard-container'>
                    <Row justify={'space-between'}>
                        <div className='card-value'>
                            {counts.totalRenew ?? 0} ₺
                        </div>
                        <img src={ArrowIcon} height={20} width={20} />
                    </Row>
                    <div>
                        <Title style={{ fontWeight: 'bold' }} className='mb-0' level={3}>Bekleyen Ödeme Tutarı</Title>
                        <Text style={{ fontWeight: 300, fontSize: 18, color: '#001433' }} className="m-0">+{counts.totalRenewForLastWeek ?? 0} ₺ Son 1 Hafta</Text>
                    </div>
                </div>
            </Card>
        // </Link>
    )
}

export default ProposalsCountCard