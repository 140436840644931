import { Col, Row, Card, Typography, Image, Space } from 'antd';
import { Outlet } from 'react-router-dom';
import './../views/Auth/auth.scss';
import appImage from './../assets/auth/auth-app.png';
import AuthCarousel from './AuthCarousel';

const { Title, Text } = Typography;

const AuthLayout = () => {
  return (
    <Row className='auth-layout'>
      <Col md={12} xs={24}>
        <Row style={{ height: 'calc(100vh - 16px)', maxHeight: 'calc(100vh - 16px)', overflow: 'auto' }} className='p-16' justify={"center"} align={"middle"}>
          <Col xxl={10} lg={10} md={20} sm={24} xs={24}>
            <Outlet />
          </Col>
        </Row>
      </Col>
      <Col md={12} xs={0}>
        <Card className="sticky-card">
          <Title level={3} className="card-title">Güvenliği sağlarken kazanmanın en kolay yolu</Title>
          <Text className="card-text">
            Ekipmanlarınıza gözümüz gibi bakıyoruz.
          </Text>
          <Row justify={'center'} align={"middle"} style={{ height: 'calc(100vh - 350px)' }}>
            <Image
              src={appImage}
              alt="Dashboard Preview"
              className="card-image"
              preview={false}
            />
          </Row>
          <div className='mt-16px'>
            <AuthCarousel />
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default AuthLayout;
