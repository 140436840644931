import { http } from "../http-common";

const getNotes = (entityName: string, entityId: string) => {
    return http.get<any>(`/accounts/me/notes/all?entityName=${entityName}&entityId=${entityId}`);
};

const postNote = (data: any) => {
    return http.post<any>(`/accounts/me/notes`, data);
};

const remove = (id: any) => {
    return http.delete<any>(`/notes/${id}`);
};

const NoteService = {
    getNotes,
    postNote,
    remove
};

export default NoteService;