import React from 'react';
import Chart from 'react-apexcharts';

const RenuewChart: React.FC = () => {
    const series = [30, 40, 30]; // Her bir dilimin yüzdesi
    const options: any = {
        chart: {
            type: 'pie',
            height: 350,
            background: '#ffffff',
            toolbar: {
                show: false
            }
        },
        colors: ['#A9A9A9', '#C1C6B9', '#010101'], // Dilimlerin ana renkleri
        labels: ['Makine', 'Elektrik', 'İş Hijyeni'],
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
                offsetY: 10,
                expandOnClick: false,
                dataLabels: {
                    offset: -10
                }
            }
        },
        fill: {
            type: ['solid', 'pattern', 'pattern'], // Desenler ve düz renk
            pattern: {
                style: ['solid', 'verticalLines', 'circles'], // Desen türleri
                width: 6,
                height: 6,
                strokeWidth: 2,
            },
        },
        dataLabels: {
            enabled: false, // Yüzde gösterimini kapat
        },
        legend: {
            show: true,
            position: 'left',
            horizontalAlign: 'center',
            markers: {
                radius: 0,
            },
            fontSize: '14px',
            labels: {
                colors: ['#001F3F']
            },
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (val: number) => `${val}%`, // Tooltip'te yüzdeleri göster
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: '100%'
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    return (
        <div className='renuew-chart'>
            <h3 style={{ color: '#001F3F', margin: '0',fontSize:24 }}>Gelir Dağılımı</h3>
            <Chart options={options} series={series} type="pie" height={350} />
        </div>
    );
};

export default RenuewChart;