import { http } from "../http-common";
import { IDiscountedAttributeRequest, IDiscountedAttributeResponse } from "../types/DiscountedAttribute";

// Discounted attributes ile ilgili tüm listeyi getir
const getAllByAttributeId = (attributeId: number) => {
  return http.get<IDiscountedAttributeResponse[]>(`/attribute/${attributeId}/discounted-attributes`);
};

// Tek bir discounted attribute'ı ID ile getir
const getById = (id: number) => {
  return http.get<IDiscountedAttributeResponse>(`/discounted-attributes/${id}`);
};

// Yeni bir discounted attribute oluştur
const create = (data: IDiscountedAttributeRequest) => {
  return http.post<IDiscountedAttributeResponse>(`/attributes/discounted-attributes`, data);
};

// Var olan discounted attribute'ı güncelle
const update = (id: number, data: IDiscountedAttributeRequest) => {
  return http.put<IDiscountedAttributeResponse>(`/discounted-attributes/${id}`, data);
};

// Discounted attribute'ı ID ile sil
const remove = (id: number) => {
  return http.delete<void>(`/discounted-attributes/${id}`);
};

const DiscountedAttributeService = {
  getAllByAttributeId,
  getById,
  create,
  update,
  remove,
};

export default DiscountedAttributeService;