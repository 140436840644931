import React, { act, useEffect, useState } from 'react';
import { Card, Col, Row, Input, Avatar, Button, Form, Modal, Typography, Progress, Tabs, Space, Upload, Checkbox } from 'antd';
import UserService from '../../services/UserService';
import { formatDate, formatDateString, openNotification } from '../../utils';
import './profile.scss'
import UserAttemps from './UserAttemps';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import Contracts from '../../components/Proile/Contracts';
import ImageService from '../../services/ImageService';
import ImageUpload from '../../components/Uploads/ImageUpload';

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

interface ProfileData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    address: string;
    identityNumber: string;
    iban: string;
    password?: string;
    profilePhoto?: string;
    createdAt: string;
    individual: boolean;
}

const modalContents: { [key: string]: JSX.Element } = {
    privacyPolicy: (
        <Typography>
            <Paragraph>
                Gizlilik Politikası metni burada yer alacak. Bu metin, kullanıcıların gizlilik hakları ve kişisel verilerin nasıl korunduğu hakkında bilgi içerir.
            </Paragraph>
        </Typography>
    ),
    terms: (
        <Typography>
            <Paragraph>
                İş Ortağı Sözleşmesi metni burada yer alacak. Bu metin, kullanıcıların siteyi kullanırken uymaları gereken kurallar ve şartlar hakkında bilgi içerir.
            </Paragraph>
        </Typography>
    ),
    clarification: (
        <Typography>
            <Title level={4}>Kişisel Verilerin Korunması Kanunu (KVKK) Aydınlatma Metni</Title>
            <Paragraph>
                <strong>1. Veri Sorumlusu ve Temsilcisi</strong><br />
                Bu aydınlatma metni, Kontrol X İş Güvenliği Hizmetleri tarafından, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) uyarınca, veri sorumlusu sıfatıyla, iş güvenliği uzmanlarının kişisel verilerinin işlenmesine ilişkin olarak bilgilendirme amacıyla hazırlanmıştır.
            </Paragraph>
            <Paragraph>
                <strong>2. Kişisel Verilerin İşlenme Amacı</strong><br />
                Toplanan kişisel veriler, iş güvenliği uzmanlarının ihtiyaç duyduğu ekipmanlar için teklif alabilmesi amacıyla işlenecektir. Bu kapsamda kişisel veriler şu amaçlarla işlenmektedir:
                <ul>
                    <li>Teklif sürecinin yürütülmesi</li>
                    <li>İletişim süreçlerinin yönetilmesi</li>
                    <li>Müşteri memnuniyetinin sağlanması ve artırılması</li>
                    <li>Yasal yükümlülüklerin yerine getirilmesi</li>
                </ul>
            </Paragraph>
            <Paragraph>
                <strong>3. İşlenen Kişisel Veriler</strong><br />
                Uygulama aracılığıyla işlenen kişisel veriler şunlardır:
                <ul>
                    <li>Ad, soyad</li>
                    <li>İletişim bilgileri (telefon numarası, e-posta adresi)</li>
                    <li>İş yeri bilgileri</li>
                    <li>Teklif taleplerine ilişkin detaylar</li>
                </ul>
            </Paragraph>
        </Typography>
    ),
};


const ProfileContent: React.FC = () => {
    const [profileData, setProfileData] = useState<ProfileData>({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        identityNumber: '',
        iban: '',
        password: '',
        profilePhoto: '',
        createdAt: '',
        individual: false
    });
    const [form] = Form.useForm();
    const [passwordForm] = Form.useForm();
    const [emailForm] = Form.useForm();
    const [roles, setRoles] = useState<any>();
    const [profileCompletion, setProfileCompletion] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isTermModalVisible, setIsTermModalVisible] = useState(false);
    const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
    const [emailToUpdate, setEmailToUpdate] = useState('');
    const [activeKey, setActiveKey] = useState('1');
    const [loading, setLoading] = useState(false);
    const [modalContent, setModalContent] = useState(modalContents.clarification);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [imageId, setImageId] = useState<string | null>(null);


    const showModal = (contentKey: keyof typeof modalContents) => {
        setModalContent(modalContents[contentKey]);
        setIsTermModalVisible(true);
    };

    const handleOk = () => {
        setIsTermModalVisible(false);
    };

    const handleCancel = () => {
        setIsTermModalVisible(false);
    };

    const getCustomer = () => {
        if (roles && roles.some((role: any) => role.name === 'ADMIN')) {
            getAccount();
        } else {
            UserService.getMe()
                .then((response: any) => {
                    setProfileData(response.data);
                    setImageId(response.data.profilePhoto);
                    localStorage.setItem('firstName', response.data.firstName);
                    localStorage.setItem('lastName', response.data.lastName);
                    localStorage.setItem('email', response.data.email);
                    localStorage.setItem('phoneNumber', response.data.phoneNumber);
                    localStorage.setItem('id', response.data.id);
                    localStorage.setItem('profilePhoto', response.data.profilePhoto);

                    form.setFieldsValue(response.data);
                })
                .catch((error: any) => {
                    console.error('Profil verilerini çekerken hata oluştu!', error);
                });
        }
    }


    useEffect(() => {
        if (roles && roles.some((role: any) => role.name === 'ADMIN')) {
            if (profileData.profilePhoto !== imageId && imageId && !isNaN(imageId as any)) {
                UserService.updateMeUser({ ['profilePhoto']: imageId })
                    .then(() => {
                        openNotification('success', 'Başarılı', `Profil resmi başarıyla güncellendi.`);

                        getCustomer();
                    })
                    .catch((e: Error) => {
                        console.log(e);
                        openNotification('error', 'Hata', `Profil resmi güncellenirken bir hata oluştu.`);
                    });
            }
        }
        if (profileData.profilePhoto !== imageId && imageId && !isNaN(imageId as any)) {
            UserService.updateMe({ ['profilePhoto']: imageId })
                .then(() => {
                    openNotification('success', 'Başarılı', `Profil resmi başarıyla güncellendi.`);

                    getCustomer();
                })
                .catch((e: Error) => {
                    console.log(e);
                    openNotification('error', 'Hata', `Profil resmi güncellenirken bir hata oluştu.`);
                });
        }
    }, [imageId]);

    const getAccount = () => {
        UserService.getMeUser()
            .then((response: any) => {
                setProfileData(response.data);
                setImageId(response.data.profilePhoto);
                localStorage.setItem('firstName', response.data.firstName);
                localStorage.setItem('lastName', response.data.lastName);
                localStorage.setItem('email', response.data.email);
                localStorage.setItem('phoneNumber', response.data.phoneNumber);
                localStorage.setItem('id', response.data.id);
                localStorage.setItem('profilePhoto', response.data.profilePhoto);
                form.setFieldsValue(response.data);
            })
            .catch((error: any) => {
                console.error('Profil verilerini çekerken hata oluştu!', error);
            });
    }

    useEffect(() => {
        let role = JSON.parse(localStorage.getItem('roles') || '[]')
        setRoles(role);
        if (role && role.some((role: any) => role.name === 'ADMIN')) {
            getAccount();
        } else {
            getCustomer()
        }
    }, [])


    const handlePasswordChange = (values: any) => {
        const { currentPassword, newPassword } = values;
        UserService.updatePassword({ currentPassword, newPassword })
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Şifreniz başarıyla değiştirildi.');
                setIsModalVisible(false);
                passwordForm.resetFields(); // Formu temizle
            })
            .catch((error: any) => {
                console.error('Şifre değiştirme işlemi sırasında hata oluştu!', error);
                openNotification('error', 'Hata', 'Şifre değiştirme işlemi sırasında bir hata oluştu. Eski şifrenizi doğru girdiğinizden emin olun.');
            });
    };

    const handleEmailChange = (values: any) => {
        const { password, email } = values;
        UserService.updateEmailRequest({ password, email })
            .then((response: any) => {
                openNotification('success', 'Başarılı', 'Email değiştirme isteği gönderildi.');
                setIsEmailModalVisible(false);
                emailForm.resetFields();
            })
            .catch((error: any) => {
                console.error('Email değiştirme işlemi sırasında hata oluştu!', error);
                openNotification('error', 'Hata', 'E-posta değiştirme işlemi sırasında bir hata oluştu. Yeni e-posta adresinizi ve şifrenizi doğru girdiğinizden emin olun. ');
            });
    };

    const handleEmailUpdateClick = () => {
        setEmailToUpdate(profileData.email);
        setIsEmailModalVisible(true);
    };

    const calculateProfileCompletion = () => {
        const totalFields = Object.keys(profileData).length;
        const filledFields = Object.values(profileData).filter(value => value).length;
        setProfileCompletion((filledFields / (totalFields-1)) * 100);
    };

    const handleTabChange = (key: string) => {
        setActiveKey(key);
    };


    function validateIBAN(iban: string): boolean {
        iban = iban.replace(/\s+/g, '');

        if (iban.length !== 24 || !/^\d+$/.test(iban)) {
            return false;
        }

        return true;
    }

    const handleUpdateProfile = (values: any) => {
        if (roles && roles.some((role: any) => role.name === 'ADMIN')) {
            UserService.updateMeUser(values)
                .then((response: any) => {
                    openNotification('success', 'Başarılı', 'Profiliniz başarıyla güncellendi.');
                    getCustomer()
                })
                .catch((e: Error) => {
                    console.log(e);
                });

        } else {
            UserService.updateMe(values)
                .then((response: any) => {
                    openNotification('success', 'Başarılı', 'Profiliniz başarıyla güncellendi.');
                    getCustomer()
                })
                .catch((e: Error) => {
                    console.log(e);
                });
        }
    };



    useEffect(() => {
        form.setFieldsValue(profileData);
        calculateProfileCompletion();
    }, [profileData])

    const handleUpdatePhone = (value: any) => {
        if (roles && roles.some((role: any) => role.name === 'ADMIN')) {
            UserService.updateMeUser({ ['phoneNumber']: value })
                .then(() => {
                    openNotification('success', 'Başarılı', `Telefon başarıyla güncellendi.`);
                    getCustomer();
                })
                .catch((e: Error) => {
                    // Hata oluşursa
                    console.log(e);
                    openNotification('error', 'Hata', `Telefon güncellenirken bir hata oluştu.`);
                });

        } else {
            UserService.updateMe({ ['phoneNumber']: value })
                .then(() => {
                    openNotification('success', 'Başarılı', `Telefon başarıyla güncellendi.`);
                    getCustomer();
                })
                .catch((e: Error) => {
                    // Hata oluşursa
                    console.log(e);
                    openNotification('error', 'Hata', `Telefon güncellenirken bir hata oluştu.`);
                });
        }
    };

    const handleUpdateIndividual = (value: any) => {
        UserService.updateMe({ ['isIndividual']: value })
            .then(() => {
                openNotification('success', 'Başarılı', `Şirket bilginiz başarıyla güncellendi.`);
                getCustomer();
            })
            .catch((e: Error) => {
                // Hata oluşursa
                console.log(e);
                openNotification('error', 'Hata', `Şirket bilginiz bir hata oluştu.`);
            });
    };

    return (
        <div className='profile-section'>
            <Row gutter={[16, 16]} justify="center">
                <Col xs={24}>
                    <Card bordered={false} className='profile-card'>
                        <Row align="middle" justify="space-between">
                            <Col>
                                <Row gutter={[16, 16]}>
                                    <Col>
                                        {/* // Upload component */}
                                        <ImageUpload imageId={profileData.profilePhoto} setImageId={setImageId} type={'profile'} />
                                    </Col>
                                    <Col>
                                        <div>
                                            <Title level={4} style={{ margin: 0 }}>{profileData.firstName + ' ' + profileData.lastName}</Title>
                                            <Text>{profileData.email}</Text><br />
                                            {roles && !roles.some((role: any) => role.name === 'ADMIN') && (
                                                <>
                                                    <Text>Kayıt Tarihi: {formatDateString(profileData.createdAt)}</Text><br />
                                                </>
                                            )}
                                            <Row >
                                                <Progress percent={profileCompletion} showInfo={false} size="small" trailColor='primary' style={{ flex: 1 }} strokeColor={'#6179fa'} />
                                                <Text style={{ marginLeft: '10px' }}> %{Math.round(profileCompletion)}</Text>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                {/* Additional Buttons or Elements */}
                            </Col>
                        </Row>
                    </Card>
                    <Card className='profile-card' style={{ marginTop: 32 }}>
                        <Tabs activeKey={activeKey} onChange={handleTabChange} className="custom-tabs"
                            tabBarStyle={{ borderBottom: 'none' }}>
                            <TabPane
                                tab={
                                    <Button type={activeKey === '1' ? 'primary' : 'default'} className='d-button tab-button'>Kullanıcı Bilgileri</Button>
                                }
                                key="1"
                            >
                                {!profileData.email ? ' ' :
                                    <Form form={form} layout="vertical" onFinish={handleUpdateProfile}>
                                        <Row gutter={24}>
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    label="Ad"
                                                    name="firstName"
                                                    rules={[{ required: true, message: 'Ad gerekli' }]}
                                                >
                                                    <Input
                                                        className='d-input d-input-form'
                                                        placeholder="Ad girin"
                                                        onKeyPress={(event) => {
                                                            const key = event.key;
                                                            const target = event.target as HTMLInputElement;
                                                            const isValid = /[a-zA-ZığüşöçİĞÜŞÖÇ\s]/.test(key);
                                                            const errorMsg = document.getElementById('firstName-error');

                                                            if (!isValid) {
                                                                event.preventDefault();
                                                                if (!errorMsg) {
                                                                    const errorDiv = document.createElement('div');
                                                                    errorDiv.id = 'firstName-error';
                                                                    errorDiv.style.color = 'red';
                                                                    errorDiv.innerText = 'Sadece harf kullanabilirsiniz.';
                                                                    target.parentNode?.appendChild(errorDiv);
                                                                }
                                                            } else if (errorMsg) {
                                                                errorMsg.remove();
                                                            }

                                                            if (target.value.length >= 100) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    label="Soyad"
                                                    name="lastName"
                                                    rules={[{ required: true, message: 'Soyad gerekli' }]}
                                                >
                                                    <Input
                                                        className='d-input d-input-form'
                                                        placeholder="Soyad girin"
                                                        onKeyPress={(event) => {
                                                            const key = event.key;
                                                            const target = event.target as HTMLInputElement;
                                                            const isValid = /[a-zA-ZığüşöçİĞÜŞÖÇ\s]/.test(key);
                                                            const errorMsg = document.getElementById('lastName-error');

                                                            if (!isValid) {
                                                                event.preventDefault();
                                                                if (!errorMsg) {
                                                                    const errorDiv = document.createElement('div');
                                                                    errorDiv.id = 'lastName-error';
                                                                    errorDiv.style.color = 'red';
                                                                    errorDiv.innerText = 'Sadece harf kullanabilirsiniz.';
                                                                    target.parentNode?.appendChild(errorDiv);
                                                                }
                                                            } else if (errorMsg) {
                                                                errorMsg.remove();
                                                            }

                                                            if (target.value.length >= 100) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    label="E-posta"
                                                    name="email"
                                                    rules={[
                                                        { required: false, message: 'E-posta gerekli' },
                                                        { type: 'email', message: 'Geçerli bir e-posta girin' },
                                                    ]}
                                                >
                                                    <Space.Compact className="width-100">
                                                        <Input
                                                            className='d-input d-input-form'
                                                            placeholder="E-posta girin"
                                                            defaultValue={profileData.email}
                                                        />
                                                        <Button className='d-button-with-input width-auto' type='primary' onClick={handleEmailUpdateClick}>Değiştir</Button>
                                                    </Space.Compact>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    label="Şifre"
                                                    name="password"
                                                    rules={[
                                                        { required: false, message: 'Şifre gerekli' },
                                                        { min: 8, message: 'Şifre en az 8 karakter olmalıdır' }
                                                    ]}
                                                >
                                                    <Space.Compact className="width-100">
                                                        <Input
                                                            placeholder="Şifre"
                                                            className='d-input d-input-form'
                                                            defaultValue={'*********'}
                                                            disabled
                                                        />
                                                        <Button className='d-button-with-input width-auto' type="primary" onClick={() => setIsModalVisible(true)}>Değiştir</Button>
                                                    </Space.Compact>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item>
                                            <Row justify={'end'}>
                                                <Button className='d-button dark-button width-auto' type="primary" htmlType="submit">
                                                    Kaydet
                                                </Button>
                                            </Row>
                                        </Form.Item>
                                    </Form>


                                }
                            </TabPane>
                            {roles && !roles.some((role: any) => role.name === 'ADMIN') && (
                                <TabPane
                                    tab={
                                        <Button type={activeKey === '2' ? 'primary' : 'default'} className='d-button tab-button'>İş Ortağı Bilgileri</Button>
                                    }
                                    key="2">
                                    <Form form={form} layout="vertical" onFinish={handleUpdateProfile}>
                                        <Row gutter={24}>
                                            <Col xs={24} md={12}>
                                                {/* IBAN */}
                                                <Form.Item
                                                    label="IBAN"
                                                    name="iban"
                                                    rules={[
                                                        { required: true, message: 'IBAN gerekli' },
                                                        {
                                                            validator: (_, value) => {
                                                                if (!value) return Promise.resolve();
                                                                const isValid = validateIBAN(value);
                                                                if (!isValid) {
                                                                    return Promise.reject(new Error('Geçersiz IBAN'));
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        addonBefore="TR"
                                                        placeholder="0011 1000 0011 1000 0011 01"
                                                        className="d-input d-input-form fs-24px"
                                                        maxLength={24}
                                                        defaultValue={profileData.iban || ''}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>

                                                {/* Telefon */}
                                                <Form.Item
                                                    label="Telefon"
                                                    name="phoneNumber"
                                                    rules={[
                                                        { required: true, message: 'Telefon gerekli' },
                                                        { len: 10, message: 'Telefon 10 karakter olmalıdır' },
                                                        { pattern: /^[0-9]*$/, message: 'Telefon sadece rakamlardan oluşmalıdır' },
                                                    ]}
                                                >
                                                    <Space.Compact className="width-100">
                                                        <Input
                                                            addonBefore="+90"
                                                            placeholder="5123456789"
                                                            defaultValue={profileData.phoneNumber || ''}
                                                            className="d-input d-input-form "
                                                            maxLength={10}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={() => form.validateFields(['phoneNumber'])} // Bu alan her değiştiğinde validasyon yapılır
                                                        />
                                                        <Form.Item
                                                            shouldUpdate={(prevValues, currentValues) =>
                                                                prevValues.phoneNumber !== currentValues.phoneNumber
                                                            }
                                                            noStyle
                                                        >
                                                            {({ getFieldError }) => {
                                                                const hasError = getFieldError('phoneNumber').length >= 0;
                                                                return (
                                                                    <Button
                                                                        className="d-button-with-input dark-button width-auto"
                                                                        type="primary"
                                                                        onClick={() =>
                                                                            handleUpdatePhone(form.getFieldValue('phoneNumber'))
                                                                        }
                                                                        disabled={hasError}
                                                                    >
                                                                        Doğrula
                                                                    </Button>
                                                                );
                                                            }}
                                                        </Form.Item>
                                                    </Space.Compact>
                                                </Form.Item>

                                                {/* TC Kimlik No */}
                                                <Form.Item
                                                    label="TC Kimlik No"
                                                    name="identityNumber"
                                                    rules={[
                                                        { required: true, message: 'Lütfen TC Kimlik No giriniz!' },
                                                        { pattern: /^[0-9]*$/, message: 'TC Kimlik No sadece rakamlardan oluşmalıdır' },
                                                        {
                                                            validator: (_, value) => {
                                                                if (!value || value.length !== 11) {
                                                                    return Promise.reject('TC Kimlik Numarası 11 haneli olmalıdır');
                                                                }

                                                                const firstTenDigits = value.substring(0, 10).split('').map(Number);
                                                                const sumOfFirstTen = firstTenDigits.reduce((acc: any, curr: any) => acc + curr, 0);
                                                                const lastDigitOfSum = sumOfFirstTen % 10;

                                                                if (lastDigitOfSum !== Number(value[10])) {
                                                                    return Promise.reject('TC Kimlik Numarası geçersiz.');
                                                                }

                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder="12345678901"
                                                        className="d-input d-input-form"
                                                        defaultValue={isNaN(Number(profileData.identityNumber)) ? '' : profileData.identityNumber}
                                                        maxLength={11}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                                <Form.Item name="isIndividual" valuePropName="checked">
                                                    <Checkbox
                                                        defaultChecked={profileData.individual}
                                                        onChange={(e) => {
                                                            handleUpdateIndividual(e.target.checked);
                                                            form.setFieldsValue({ individual: e.target.checked });
                                                        }}
                                                    >
                                                        Şahıs Şirketiniz var ise lütfen işaretleyiniz.
                                                    </Checkbox>
                                                </Form.Item>
                                            </Col>

                                            <Col xs={0} md={1} style={{ minHeight: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                <div style={{ width: '1px', height: '100%', backgroundColor: '#f0f0f0' }}></div>
                                            </Col>

                                            {/* Sağ taraf: Adres Bilgileri */}
                                            <Col xs={24} md={11}>
                                                <Form.Item
                                                    label="Adres"
                                                    name="address"
                                                    rules={[
                                                        { required: true, message: 'Adres gerekli' },
                                                        { max: 500, message: 'Adres en fazla 500 karakter olmalıdır' },
                                                    ]}
                                                >
                                                    <Input.TextArea
                                                        rows={4}
                                                        className="d-input d-input-form"
                                                        placeholder="Adres girin"
                                                        onKeyPress={(event) => {
                                                            const target = event.target as HTMLTextAreaElement;
                                                            if (target.value.length >= 500) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {/* Single Save Button */}
                                        <Row justify={'end'}>
                                            <Button
                                                className="d-button dark-button width-auto"
                                                type="primary"
                                                htmlType="submit" // This makes the button submit the form
                                            >
                                                Kaydet
                                            </Button>
                                        </Row>
                                    </Form>
                                    <div style={{ marginTop: 32 }}>
                                        <Contracts />
                                    </div>

                                </TabPane>
                            )}
                            <TabPane
                                tab={
                                    <Button type={activeKey === '3' ? 'primary' : 'default'} className='d-button tab-button'>Hesap Aktivitileri</Button>
                                } key="3">
                                <UserAttemps />
                            </TabPane>

                        </Tabs>
                    </Card>
                </Col>
            </Row>

            <Modal
                title="Şifre değiştir"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                width={350}
                style={{ borderRadius: '10px' }}
            >
                <Form form={passwordForm} onFinish={handlePasswordChange} layout="vertical">
                    <Form.Item
                        label="Mevcut Şifre"
                        name="currentPassword"
                        rules={[{ required: true, message: 'Şifre boş bırakılamaz' }]}
                    >
                        <Input.Password className='d-input d-input-form' />
                    </Form.Item>
                    <Form.Item
                        label="Yeni Şifre"
                        name="newPassword"
                        rules={[
                            { required: true, message: 'Yeni şifre boş bırakılamaz' },
                            {
                                validator: (_, value) => {
                                    const errors = [];
                                    if (!value) {
                                        return Promise.reject(new Error('Şifre boş bırakılamaz!'));
                                    }
                                    if (value.length < 8) {
                                        errors.push('Şifre en az 8 karakter olmalıdır!');
                                    }
                                    // if (!/[A-Z]/.test(value)) {
                                    //     errors.push('En az bir büyük harf içermelidir!');
                                    // }
                                    // if (!/[a-z]/.test(value)) {
                                    //     errors.push('En az bir küçük harf içermelidir!');
                                    // }
                                    if (!/[0-9]/.test(value)) {
                                        errors.push('En az bir sayı içermelidir!');
                                    }
                                    if (errors.length > 0) {
                                        return Promise.reject(new Error(errors.join('\n')));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input.Password className='d-input d-input-form' />
                    </Form.Item>
                    <Form.Item
                        label="Yeni Şifre Tekrarı"
                        name="confirmPassword"
                        dependencies={['newPassword']}
                        rules={[
                            { required: true, message: 'Yeni şifre (tekrar) boş bırakılamaz' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Şifreler uyuşmuyor.'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password className='d-input d-input-form' />
                    </Form.Item>
                    <Form.Item>
                        <Row justify={'center'}>
                            <Button className='d-button dark-button width-auto' type="primary" htmlType="submit" block>Değiştir</Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="E-posta değiştir"
                visible={isEmailModalVisible}
                onCancel={() => setIsEmailModalVisible(false)}
                footer={null}
                width={350}
                style={{ borderRadius: '10px' }}
            >
                <Form form={emailForm} onFinish={handleEmailChange} layout="vertical">
                    <Form.Item
                        label="Yeni E-Posta"
                        name="email"
                        rules={[{ required: true, message: 'E-Posta boş bırakılamaz', type: 'email' }]}
                    >
                        <Input className='d-input d-input-form' />
                    </Form.Item>

                    <Form.Item
                        label="Mevcut Şifre"
                        name="password"

                        rules={[{ required: true, message: 'Şifre boş bırakılamaz' }]}
                    >
                        <Input.Password className='d-input d-input-form' />
                    </Form.Item>
                    <Form.Item>
                        <Row justify={'center'}>
                            <Button className='d-button dark-button width-auto' type="primary" htmlType="submit" block>Değiştir</Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="Bilgilendirme" visible={isTermModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null} centered bodyStyle={{ maxHeight: '500px', overflowY: 'auto' }}>
                {modalContent}
            </Modal>
        </div>
    );
};

export default ProfileContent;
