import {http} from "../http-common";

const getAll = () => {
  return http.get<Array<any>>("/attributes");
};

const getAllTypes = () => {
  return http.get<Array<any>>("/types");
};

const get = (id: Number) => {
  return http.get<any>(`/attributes/${id}`);
};

const create = (data: any) => {
  return http.post<any>("/attributes", data);
};

const update = (id: any, data: any) => {
  return http.put<any>(`/attributes/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/attributes/${id}`);
};

const updateOrder = (data: any) => {
  return http.post<any>("/attributes/updateOrder", data);
};

const AttributeService = {
  getAll,
  get,
  create,
  update,
  remove,
  getAllTypes,
  updateOrder
};

export default AttributeService;