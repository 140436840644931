import { Button, Form, Input, Checkbox, Divider, Row, Col, Typography } from 'antd';
import { useEffect, useState } from 'react';
import PermissionService from '../../services/PermissonService';
import RoleService from '../../services/RoleService';
import { IPermissionResponseData } from '../../types/Permission';
import { IRoleResponseData } from '../../types/Role';
import { openNotification } from '../../utils';

const { Title } = Typography;

interface RoleEditProps {
    id: number;
    onClose: () => void;
}

const RoleEdit: React.FC<RoleEditProps> = ({ id, onClose }) => {
    const [permissions, setPermissions] = useState<IPermissionResponseData[]>([]);
    const [groupedPermissions, setGroupedPermissions] = useState<{ [key: string]: IPermissionResponseData[] }>({});
    const [role, setRole] = useState<IRoleResponseData | null>(null);
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
    const [form] = Form.useForm();

    useEffect(() => {
        if (role) {
            form.resetFields(); 
            form.setFieldsValue(role);
        }
    }, [role, form]);
    
    useEffect(() => {
        fetchPermissions();
        fetchRole();
    }, [id]);

    const fetchPermissions = () => {
        PermissionService.getAll()
            .then((response: any) => {
                const sortedData = [...response.data].sort((a, b) => a.name.localeCompare(b.name));
                setPermissions(sortedData);
                groupPermissions(sortedData);
            })
            .catch((error: Error) => {
                console.error('İzinler alınamadı', error);
            });
    };

    const groupPermissions = (permissions: IPermissionResponseData[]) => {
        const grouped = permissions.reduce((acc, permission) => {
            const [group] = permission.name.split('.');
            if (!acc[group]) {
                acc[group] = [];
            }
            acc[group].push(permission);
            return acc;
        }, {} as { [key: string]: IPermissionResponseData[] });

        setGroupedPermissions(grouped);
    };

    const fetchRole = () => {
        RoleService.get(id)
            .then((response: any) => {
                setRole(response.data);
                const ids: number[] = response.data.permissions.map((permission: any) => permission.id);
                setSelectedPermissions(ids);
                form.setFieldsValue(response.data); // form alanlarını güncelle
            })
            .catch((error: Error) => {
                console.error('Rol alınamadı', error);
            });
    };

    const onChange = (permissionId: number, checked: boolean) => {
        if (checked) {
            setSelectedPermissions([...selectedPermissions, permissionId]);
        } else {
            setSelectedPermissions(selectedPermissions.filter(id => id !== permissionId));
        }
    };

    const onFinish = (values: any) => {
        const updatedValues = {
            ...values,
            permissionIds: selectedPermissions,
        };
        RoleService.update(id, updatedValues)
            .then(() => {
                openNotification('success', 'Başarılı', 'Rol başarıyla güncellendi');
                onClose();
            })
            .catch((error: Error) => {
                openNotification('error', 'Başarısız', 'Rol güncellenirken hata oluştu');

                console.error('Rol güncellenemedi', error);
            });
    };

    if (!role) {
        return <div>Yükleniyor...</div>;
    }

    return (
        <div className="role-edit-container">
            {/* <Title level={4}>Rol Düzenle</Title> */}
            <Form onFinish={onFinish} layout="vertical" initialValues={role} form={form}>
                <Form.Item
                    label={<p style={{ fontSize: "16px", margin: 0, fontWeight: 'bold' }}>Rol Adı</p>}
                    name="name"
                    rules={[{ required: true, message: 'Lütfen rol adını giriniz!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={<p style={{ fontSize: "16px", margin: 0, fontWeight: 'bold' }}>Rol Yetkileri </p>}
                    name="permissions">
                    <Divider style={{ marginTop: 16 }}></Divider>
                    {Object.keys(groupedPermissions).map(group => (
                        <div key={group} >
                            <Row gutter={[16, 16]} align={'middle'}>
                                <Col span={5}> <span style={{ fontWeight: 'bold' }}>{group}</span></Col>
                                {groupedPermissions[group].map(permission => (
                                    <Col key={permission.id} span={4}>
                                        <Checkbox
                                            value={permission.id}
                                            checked={selectedPermissions.includes(permission.id)}
                                            onChange={e => onChange(permission.id, e.target.checked)}
                                        >
                                            {permission.name.split('.')[1]}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                            <Divider style={{ margin: '20px 0' }}></Divider>
                        </div>
                    ))}
                </Form.Item>
                <Form.Item>
                    <Row justify={'center'}>
                        <Button type="primary" htmlType="submit" className='d-button width-auto dark-button'>
                            Kaydet
                        </Button>
                    </Row>
                </Form.Item>
            </Form>
        </div>
    );
};

export default RoleEdit;
