import { http } from "../http-common";

const getAll = () => {
  return http.get<Array<any>>("/address-properties/me");
};

const get = (id: number) => {
  return http.get<any>(`/address-properties/${id}`);
};
const getCompanyAdresses = (id: number) => {
  return http.get<any>(`/companies/${id}/addresses`);
};

const create = (data: any) => {
  return http.post<any>("/address-properties", data);
};

const update = (id: number, data: any) => {
  return http.put<any>(`/address-properties/${id}`, data);
};

const remove = (id: number) => {
  return http.delete<void>(`/address-properties/${id}`);
};

const AddressService = {
  getAll,
  get,
  create,
  update,
  remove,
  getCompanyAdresses
};

export default AddressService;