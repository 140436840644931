import { http } from "../http-common";

const getAllMe = (params: {
    search?: string,
    page?: number,
    size?: number,
    accountId?: any,
    sortField?: string,
    sortDirection?: 'ASC' | 'DESC'
} = { sortField: 'createdAt', sortDirection: 'DESC' }) => {
    return http.get<any>(`/companies/me`, {
        params: {
            name: params.search || '',
            page: params.page || 0,
            size: params.size || 10,
            sortField: params.sortField ?? '',
            sortDirection: params.sortDirection ?? ''
        }
    });
};

const getAll = (params: {
    search?: string,
    page?: number,
    size?: number,
    accountId?: any,
    sortField?: string,
    sortDirection?: 'ASC' | 'DESC'
} = { sortField: 'createdAt', sortDirection: 'DESC' }) => {

    return http.get<any>(`/companies`, {
        params: {
            name: params.search || '',
            accountId: params.accountId || '',
            page: params.page || 0,
            size: params.size || 10,
            sortField: params.sortField,
            sortDirection: params.sortDirection
        }
    });
};


const getCompany = (id: any) => {
    return http.get<any>(`/companies/${id}`);
};

const create = (data: any) => {
    return http.post<any>(`/companies`, data);
};

const remove = (id: any) => {
    return http.delete<any>(`/companies/${id}`);
};

const update = (id: any, data: any) => {
    return http.put<any>(`/companies/${id}`, data);
};

const postImage = (data: any, code: any) => {
    return http.post<any>(`/images${code}`, data);
};

const getStates = (id: any) => {
    return http.get<any>(`/countries/${id}/states`);
};

const getDistricts = (stateId: any) => {
    return http.get<any>(`/countries/${stateId}/districts`);
};

const getImage = (id: any) => {
    return http.get<any>(`/images/${id}`);
};

const removeAddress = (id: any) => {
    return http.delete<any>(`/address-properties/${id}`);
};

const removeAuthorizedPerson = (id: any) => {
    return http.delete<any>(`/authorized-persons/${id}`);
};

const transfer = (companyId: any, accountId: any) => {
    return http.post<any>(`/companies/transfer`, {
        companyId: companyId,
        newAccountId: accountId
    });
};

const CompanyService = {
    getCompany,
    postImage,
    getAllMe,
    getAll,
    create,
    remove,
    getStates,
    getDistricts,
    getImage,
    update,
    removeAddress,
    removeAuthorizedPerson,
    transfer
};

export default CompanyService;