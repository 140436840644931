import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Table, Collapse, Row, Col, Card, Avatar, Input, Typography, Divider, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CompanyService from '../../services/CompanyService';
import './ProposalCompanySelect.scss';
import { capitalizeSentences, capitalizeWords, formatDate } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

const { Text } = Typography;

const ProposalCompanySelect = ({ setCompanyId, companyId }: { setCompanyId: (id: any) => void, companyId: any }) => {
  const [companies, setCompanies] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedRowKey, setSelectedRowKey] = useState<any>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState<any>([]);
  const [roles, setRoles] = useState<any>();

  // Pagination states
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [searchTerm, setSearchTerm] = useState(''); // For search

  const navigate = useNavigate();
  const logoCache = new Map(); // Logo cache mechanism

  // Fetch companies based on pagination and search
  useEffect(() => {
    fetchCompanies();
  }, [currentPage, pageSize, searchTerm]);

  useEffect(() => {
    if (companyId && !selectedCompany) {
      fetchCompanyById(companyId);
    } else if (!companyId) {
      fetchCompanies();
    }
  }, [companyId]);

  const fetchCompanyById = async (id: number) => {
    try {
      const response = await CompanyService.getCompany(id);
      if (response.data.imageId && !logoCache.has(response.data.imageId)) {
        const logoResponse = await CompanyService.getImage(response.data.imageId);
        response.data.logoUrl = logoResponse.data.preSignUrl;
        logoCache.set(response.data.imageId, response.data.logoUrl); // Cache'e ekle
      } else {
        response.data.logoUrl = logoCache.get(response.data.imageId);
      }
      setSelectedCompany(response.data);
      setSelectedRowKey(response.data.id);
      setCompanyId(response.data.id);
    } catch (error) {
      console.error('Şirket yüklenirken hata oluştu:', error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const storedRoles = localStorage.getItem('roles');
      const parsedRoles = storedRoles ? JSON.parse(storedRoles) : [];
      setRoles(parsedRoles);

      const params = {
        page: currentPage,
        size: pageSize,
        search: searchTerm,
      };

      const response = await CompanyService.getAllMe(params);
      const companiesWithLogo = await fetchCompanyLogos(response.data.content); // Assuming paginated data contains 'content'

      setCompanies(companiesWithLogo);
      setFilteredCompanies(companiesWithLogo);
      setTotalItems(response.data.totalElements); // Assuming total number of items is returned as 'totalElements'
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const fetchCompanyLogos = async (companies: any[]) => {
    return Promise.all(
      companies.map(async (company) => {
        if (company.imageId) {
          if (logoCache.has(company.imageId)) {
            return { ...company, logoUrl: logoCache.get(company.imageId) };
          }
          try {
            const logoResponse = await CompanyService.getImage(company.imageId);
            const logoUrl = logoResponse.data.preSignUrl;
            logoCache.set(company.imageId, logoUrl); // Cache the logo URL
            return { ...company, logoUrl };
          } catch (error) {
            console.error('Error fetching logo:', error);
          }
        }
        return company;
      })
    );
  };

  const handleCompanySelect = (company: any) => {
    setSelectedCompany(company);
    setSelectedRowKey(company.id);
    setCompanyId(company.id);
    setModalVisible(false);
  };

  const handleRowClick = (record: any) => {
    setSelectedRowKey(record.id);
  };

  const handleSave = () => {
    const selectedCompany = companies.find((company: any) => company.id === selectedRowKey);
    if (selectedCompany) {
      handleCompanySelect(selectedCompany);
    }
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      if (value.length >= 1) {
        setSearchTerm(value);
      } else {
        setSearchTerm("");
      }
    }, 300),
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    debouncedSearch(value);
  };

  const columns = [
    {
      title: "Firma Adı",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => {
        const displayText = text.length > 28 ? `${text.substring(0, 28)}...` : text;
        return (
          <div className="d-flex align-items-center">
            <div className='avatar-image-logo' style={{ marginRight: 10 }}>
              {record.logoUrl ? (
                <img
                  src={record.logoUrl}
                  alt={record.name}
                  style={{ width: 40, height: 40, borderRadius: '50%', marginRight: 10 }}
                />
              ) : (
                <Avatar style={{ backgroundColor: '#f0f0f0', color: '#000', marginRight: 10, width: 40, height: 40 }}>
                  {record.name.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </div>
            {displayText.toUpperCase()}
          </div>
        );
      },
    },
    {
      title: "Yetkili Ad Soyad",
      key: "authorizedPersons",
      render: (text: any, record: any) => {
        const defaultPerson = record.authorizedPersons.find((person: any) => person.default) || record.authorizedPersons[0];
        return defaultPerson ? `${capitalizeWords(defaultPerson.firstName)} ${capitalizeWords(defaultPerson.lastName)}` : "Yetkili Bulunamadı";
      },
    },
    {
      title: "Yetkili E-posta",
      dataIndex: "email",
      key: "email",
      render: (text: any, record: any) => `${record.authorizedPersons[0]?.email}`,
    },
    {
      title: "Yetkili Cep Telefonu",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text: any, record: any) => `${record.authorizedPersons[0]?.phoneNumber}`,
    },
    {
      title: "Oluşturma Tarihi",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => <Text>{formatDate(text)}</Text>,
    },
  ];

  const handlePageChange = (page: number, size?: number) => {
    setCurrentPage(page - 1);
    if (size) setPageSize(size);
  };

  const billingAddress = selectedCompany?.addresses?.find(
    (address: any) => address.type === "BILLING"
  );

  return (
    <div>
      {!selectedCompany ? (
        <Card
          style={{
            borderRadius: '30px',
            textAlign: 'center',
          }}
        >
          <Row justify="space-between" align="top">
            <Col xs={24} sm={24} md={8}>
              <h3 className='text-start'>Firma Bilgileri</h3>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <p>Teklif oluşturmak için firma seç</p>
              <Button type="primary" className='d-button width-auto p-20px dark-button' onClick={() => setModalVisible(true)}>
                Firma Seç
              </Button>
            </Col>
            <Col sm={24} md={8}>
            </Col>
          </Row>
        </Card>
      ) : (
        <Collapse
          className="antd-order-detail-collapse"
          expandIconPosition={'end'}
          ghost
          items={[{
            key: '1',
            label: (
              <Row align={'middle'} justify={'space-between'} className='width-100'>
                <Col >
                  <h3>Firma Bilgileri</h3>
                  {selectedCompany.logoUrl ? (
                    <Avatar
                      src={selectedCompany.logoUrl}
                      alt={selectedCompany.name}
                      style={{ width: 50, height: 50, borderRadius: '50%', marginRight: 16 }}
                    />
                  ) : (
                    <Avatar style={{ backgroundColor: '#f0f0f0', color: '#000', width: 50, height: 50, borderRadius: '50%', marginRight: 16 }}>
                      {selectedCompany?.name?.charAt(0)?.toUpperCase()}
                    </Avatar>
                  )}
                  <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{selectedCompany?.name?.toUpperCase()}</span>

                </Col>

              </Row>
            ),
            children: (
              <>
                <Row gutter={[16, 16]} className='mt-16px'>
                  <Col xs={24} sm={12} lg={6}>
                    <Text type="secondary">Firma Ünvanı</Text>
                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{selectedCompany?.name?.toUpperCase()}</div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Text type="secondary">Firma E-posta Adresi</Text>
                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>
                      {selectedCompany.email}
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Text type="secondary">Firma Telefon Numarası</Text>
                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{selectedCompany.phoneNumber}</div>
                  </Col>
                  <Col xs={0} sm={0} lg={6}>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Text type="secondary">Kep Adresi</Text>
                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>
                      {selectedCompany.kepAddress}
                    </div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Text type="secondary">Vergi Dairesi</Text>
                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{capitalizeSentences(selectedCompany.taxOffice)}</div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Text type="secondary">Vergi Numarası</Text>
                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{selectedCompany.taxNumber}</div>
                  </Col>
                  <Col xs={0} sm={0} lg={6}>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Text type="secondary">İl</Text>
                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{capitalizeSentences(billingAddress?.city) || ""}</div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Text type="secondary">İlçe</Text>
                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{capitalizeSentences(billingAddress?.state) || ""}</div>
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Text type="secondary">Adres</Text>
                    <div style={{ wordWrap: 'break-word', whiteSpace: 'normal', width: '100%' }}>{capitalizeWords(billingAddress?.addressLine1) || ""}</div>
                  </Col>
                </Row>
              </>
            ),
          }]}
        />
      )}

      {/* Firma Listesi Modal */}
      <Modal
        title="Firma Listesi"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={1100}
        footer={null}
      >
        <div className="d-flex justify-content-end mb-20px">
          <div className="d-flex align-items-center">
            <Input
              placeholder="Ara"
              prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
              className="d-input d-input-form mr-16px d-input-filter"
              style={{ width: 200, marginRight: 16 }}
              onChange={handleSearch}
            />
            <Button key="submit" type="primary" className="d-button dark-button width-auto p-20px " style={{marginRight: 16}} onClick={handleSave}>
              Seç
            </Button>
            <Button type="primary" className="d-button dark-button width-auto br-30px" onClick={() => navigate('/companies?activeKey=create')}>
              Yeni Firma Ekle
            </Button>
          </div>
        </div>
        <Table
          scroll={{ x: 768 }}
          columns={columns}
          dataSource={filteredCompanies}
          rowKey="id"
          pagination={{
            current: currentPage + 1,
            pageSize: pageSize,
            total: totalItems,
            onChange: (page, pageSize) => {
              setCurrentPage(page - 1);
              setPageSize(pageSize || 10);
            },
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30'],
          }}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: [selectedRowKey],
            onChange: (selectedRowKeys) => {
              setSelectedRowKey(selectedRowKeys[0]);
            },
          }}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </Modal>
    </div>
  );
};

export default ProposalCompanySelect;