import { Button, Col, Form, Input, Row, Typography, Layout, Modal, Space, Image } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { openNotification } from './../../utils';
import './auth.scss';
import { useEffect, useState } from 'react';
import successIcon from './../../assets/auth/success-icon.png';

const { Title, Text, Paragraph } = Typography;
const { Content } = Layout;

const modalContents: { [key: string]: JSX.Element } = {
  privacyPolicy: (
    <Typography>
      <Paragraph>
        Ticari Elektronik İleti İnzi metni burada yer alacak. Bu metin, kullanıcıların gizlilik hakları ve kişisel verilerin nasıl korunduğu hakkında bilgi içerir.
      </Paragraph>
    </Typography>
  ),
  terms: (
    <Typography>
      <Paragraph>
        Açık Rıza metni burada yer alacak. Bu metin, kullanıcıların siteyi kullanırken uymaları gereken kurallar ve şartlar hakkında bilgi içerir.
      </Paragraph>
    </Typography>
  ),
  clarification: (
    <Typography>
      <Title level={4}>Kişisel Verilerin Korunması Kanunu (KVKK) Aydınlatma Metni</Title>
      <Paragraph>
        <strong>1. Veri Sorumlusu ve Temsilcisi</strong><br />
        Bu aydınlatma metni, Kontrol X İş Güvenliği Hizmetleri tarafından, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) uyarınca, veri sorumlusu sıfatıyla, iş güvenliği uzmanlarının kişisel verilerinin işlenmesine ilişkin olarak bilgilendirme amacıyla hazırlanmıştır.
      </Paragraph>
      <Paragraph>
        <strong>2. Kişisel Verilerin İşlenme Amacı</strong><br />
        Toplanan kişisel veriler, iş güvenliği uzmanlarının ihtiyaç duyduğu ekipmanlar için teklif alabilmesi amacıyla işlenecektir. Bu kapsamda kişisel veriler şu amaçlarla işlenmektedir:
        <ul>
          <li>Teklif sürecinin yürütülmesi</li>
          <li>İletişim süreçlerinin yönetilmesi</li>
          <li>Müşteri memnuniyetinin sağlanması ve artırılması</li>
          <li>Yasal yükümlülüklerin yerine getirilmesi</li>
        </ul>
      </Paragraph>
      <Paragraph>
        <strong>3. İşlenen Kişisel Veriler</strong><br />
        Uygulama aracılığıyla işlenen kişisel veriler şunlardır:
        <ul>
          <li>Ad, soyad</li>
          <li>İletişim bilgileri (telefon numarası, e-posta adresi)</li>
          <li>İş yeri bilgileri</li>
          <li>Teklif taleplerine ilişkin detaylar</li>
        </ul>
      </Paragraph>
    </Typography>
  ),
};

const Register = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(modalContents.clarification);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const showModal = (contentKey: keyof typeof modalContents) => {
    setModalContent(modalContents[contentKey]);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values: any) => {
    setIsLoading(true);
    let userData = {
      "firstName": values.firstName,
      "lastName": values.lastName,
      "email": values.email.toLowerCase(),
      "password": values.password,
      "phone": values.phone
    };

    const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/register';
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: apiUrl,
      data: userData
    };

    axios.request(config)
      .then((response: any) => {
        console.log(response);
        localStorage.setItem('accessToken', response?.data?.accessToken);
        localStorage.setItem('accessTokenExpiredAt', response?.data?.accessTokenExpiredAt);
        localStorage.setItem('refreshToken', response?.data?.refreshToken);
        localStorage.setItem('refreshTokenExpiredAt', response?.data?.refreshTokenExpiredAt);
        setIsRegisterSuccess(true)
        setIsLoading(false);
        // openNotification('success', 'Başarılı', 'Başarıyla Kayıt Oluşturuldu, Lütfen Giriş Yapın.');
      })
      .catch((e: any) => {
        console.log(userData);
        console.log(e);

        if (e.response?.data?.errorCode === 'account-already-exists') {
          openNotification('error', 'Başarısız', 'E-posta daha önce kayıt edilmiş.');
        } else {
          openNotification('error', 'Başarısız', 'Kayıt Yapılamadı.');
        }
        setIsLoading(false);
      });
  };

  return (
    <Content>
      {isRegisterSuccess ? (
        <>
          <Row justify={"center"}>
            <Title level={1}>Kontrol X</Title>
          </Row>
          <Row justify={'center'}>
            <Image src={successIcon} preview={false} width={64} />
          </Row>
          <Row align={'middle'} justify={'center'} className='text-center'>
            <Title level={2}>Kullanıcı kayıt talebiniz başarıyla alınmıştır.</Title>
            <Paragraph className='fs-20 fw-400 font-color-subtext'>
              E-posta adresinize gelen aktivasyon linkine tıklayarak kullanıcı kayıt işleminizi tamamlayabilirsiniz.            </Paragraph>
          </Row>
        </>
      ) : (
        <>
          <Row justify={"center"}>
            <Title level={1}>Kontrol X</Title>
          </Row>
          <Row justify={"center"}>
            <Title level={2} className='form-header-title'>Kayıt Ol</Title>
          </Row>
          <Form
            name="register_form"
            className="register-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <Form.Item
              name="firstName"
              label="Ad"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Lütfen adınızı giriniz!' }]}
            >
              <Input className='d-input' placeholder="Ad" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Soyad"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[{ required: true, message: 'Lütfen soyadınızı giriniz!' }]}
            >
              <Input className='d-input' placeholder="Soyad" />
            </Form.Item>
            <Form.Item
              name="email"
              label="E-Posta"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: 'Lütfen e-posta adresinizi giriniz!' },
                { type: 'email', message: 'Lütfen geçerli bir e-posta adresi giriniz!' }
              ]}
            >
              <Input className='d-input' placeholder="E-Posta" />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Telefon"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.resolve(); // Boş değer kabul ediliyor
                    }

                    // Telefon numarasının 5 ile başlaması ve 10 haneli olması
                    if (!/^[5][0-9]{9}$/.test(value)) {
                      return Promise.reject(new Error('Telefon 5 ile başlamalı ve 10 rakamdan oluşmalıdır.'));
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                className="d-input"
                placeholder="Telefon"
                addonBefore="+90"
                maxLength={10}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Şifre"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: 'Lütfen şifrenizi giriniz!' },
                {
                  validator: (_: any, value: any) => {
                    const errors: string[] = [];

                    if (!value) {
                      return Promise.reject(new Error('Şifre boş bırakılamaz!'));
                    }
                    if (value.length < 8) {
                      errors.push('Şifre en az 8 karakter olmalıdır!');
                    }
                    // if (!value.match(/[A-Z]/)) {
                    //   errors.push('En az bir büyük harf içermelidir!');
                    // }
                    // if (!value.match(/[a-z]/)) {
                    //   errors.push('En az bir küçük harf içermelidir!');
                    // }
                    if (!value.match(/[0-9]/)) {
                      errors.push('En az bir sayı içermelidir!');
                    }

                    if (errors.length > 0) {
                      return Promise.reject(new Error(errors.join('\n')));
                    }
                    return Promise.resolve();
                  }
                }
              ]}
            >
              <Input.Password
                placeholder="Şifre"
                className='d-input'
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Şifre Tekrarı"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                { required: true, message: 'Lütfen şifrenizi tekrar giriniz!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Şifreler uyuşmuyor!'));
                  },
                }),
              ]}
            >
              <Input.Password
                className='d-input'
                placeholder="Şifre Tekrarı"
              />
            </Form.Item>
            <Row className='text-center mb-8px'>
              <Text type='secondary'>
                Kişisel verileriniz, <Text onClick={() => showModal('clarification')} style={{ color: '#000', cursor: 'pointer' }}>Aydınlatma Metni</Text> kapsamında işlenmektedir. “Kaydol” butonuna basarak <Text onClick={() => showModal('terms')} style={{ color: '#000', cursor: 'pointer' }}>Açık Rıza Metni</Text>’ni ve <Text onClick={() => showModal('privacyPolicy')} style={{ color: '#000', cursor: 'pointer' }}>Ticari Elektronik İleti İzni</Text>’ni okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz.
              </Text>
            </Row>
            <Form.Item>
              <Button htmlType="submit" className='d-button mt-16px' type='primary' loading={isLoading}>
                Kaydol
              </Button>
            </Form.Item>
          </Form>
          <Row justify={'center'}>
            <Text >
              Zaten bir hesabım var <Link className='fw-400' to={'/login'}>Giriş Yap</Link>
            </Text>
          </Row>
          <Modal title="Bilgilendirme" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null} centered bodyStyle={{ maxHeight: '500px', overflowY: 'auto' }}>
            {modalContent}
          </Modal>
        </>
      )}
    </Content>
  );
}

export default Register;
