import { http } from '../http-common';

const getAll = (search?: string) => {
    // If a search term is provided, add it as a query parameter
    const params = search ? { params: { search } } : {};
    return http.get<any[]>('/discount-rates', params);
};

const create = (data: any) => {
    return http.post<any>('/discount-rates', data);
};

const update = (id: number, data: any) => {
    return http.put<any>(`/discount-rates/${id}`, data);
};

const remove = (id: number) => {
    return http.delete<void>(`/discount-rates/${id}`);
};

const DiscountRateService = {
    getAll,
    create,
    update,
    remove,
};

export default DiscountRateService;