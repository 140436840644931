// EmailVerification.tsx
import { Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EmailVerification: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const navigate = useNavigate();
    const [title, setTitle] = useState("E-posta onaylanıyor lütfen bekleyiniz")

    useEffect(() => {
        let data = JSON.stringify({
            "token": token
        });

        const apiUrl = process.env.REACT_APP_API_BASE_URL + '/auth/email-verification';

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: apiUrl,
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                setTitle("E-Posta adresiniz onaylandı, girişe yönlendiriliyorsunuz.")
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            })
            .catch((error) => {
                setTitle("E-Posta adresiniz onaylanmadı")
                console.log(error);
            });


        return () => {
        }
    }, [])


    return (
        <Row justify={"center"} align={"middle"} style={{ height: "100vh" }}>
            <h2>{title}</h2>
        </Row>
    );
};

export default EmailVerification;
