import { Button, Popconfirm, Radio, Row, Space, Table } from 'antd'
import React, { useState, useEffect } from 'react'
import AddressService from '../../services/AddressService';
import { capitalizeSentences, capitalizeWords, openNotification } from '../../utils';
import ControlAddressDrawer from './ControlAddressDrawer';
import { useParams } from 'react-router-dom';
import TrashIcon from '../../assets/icons/trash.svg';
import PenIcon from '../../assets/icons/pen.svg';

interface Address {
    id: number;
    addressLine1: string;
    addressLine2: string | null;
    city: string | null;
    state: string | null;
    postalCode: string | null;
    phone: string;
    taxNumber: string;
    taxOffice: string;
    title: string;
    type: any;
    companyId: any;
}



const ControlAddress = ({ companyId, type = false, setSelectedAddress, selectedAddressId, isEdit = false }: { companyId: any, type: boolean, setSelectedAddress: any, selectedAddressId: any, isEdit: boolean }) => {
    const [isControlAddressDrawerVisible, setControlAddressDrawerVisible] = useState(false);
    const [editAddress, setEditAddress] = useState<Address | null>(null);
    const [addresses, setAddresses] = useState<any | null>(null);
    const [selectedRow, setSelectedRow] = useState<any>(selectedAddressId);
    useEffect(() => {
        fetchCompany();
    }, [companyId])

    const fetchCompany = async () => {
        try {
            const response = await AddressService.getCompanyAdresses(companyId);
            setAddresses(response.data);
            console.log(response.data)
        } catch (error) {
            console.error('Şirket adresleri yüklenirken hata oluştu:', error);
        }
    };

    const handleRadioChange = (record: any) => {
        setSelectedRow(record.id);
        setSelectedAddress(record.id);
    };


    const addressColumns = [
        ...(type
            ? [
                {
                    title: '',
                    width: 50,
                    key: 'selection',
                    render: (text: string, record: any) => (
                        <Radio
                            checked={selectedRow === record.id} // Eğer bu satır seçildiyse işaretlenir
                            onChange={() => handleRadioChange(record)} // Radio'ya tıklandığında state güncellenir
                        />
                    ),
                },
            ]
            : []), // Eğer type true ise eklenir
        {
            title: 'Adres Başlığı',
            dataIndex: 'title',
            key: 'title',
            width: 200,
            render: (text: string) => capitalizeSentences(text),
        },
        {
            title: 'Adres',
            dataIndex: 'addressLine1',
            key: 'addressLine1',
            width: 400,
            render: (text: string, record: any) => (
                <>
                    {capitalizeWords(text)} {capitalizeWords(record.addressLine2)}
                </>
            ),
        },
        {
            title: 'İl',
            dataIndex: 'city',
            key: 'city',
            render: (text: string) => capitalizeSentences(text),
        },
        {
            title: 'İlçe',
            dataIndex: 'state',
            key: 'state',
            render: (text: string) => capitalizeSentences(text),
        },
        {
            title: 'İşlem',
            key: 'action',
            render: (text: string, record: any) => (
                <Space>
                    <Button
                        icon={<img src={PenIcon} alt="pen" className="icon" />}
                        shape="circle"
                        onClick={() => handleControlAddressClick(record)}
                        className="icon-button"
                        disabled={isEdit}
                    />
                    <Popconfirm
                        title="Bu adresi silmek istediğinizden emin misiniz?"
                        onConfirm={() => handleDeleteAddress(record)}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button
                            icon={<img src={TrashIcon} alt="trash" className="icon" />}
                            shape="circle"
                            className="icon-button"
                            disabled={isEdit}
                        />
                    </Popconfirm>
                </Space>
            ),
        },
    ];


    const handleControlAddressClick = (address?: Address) => {
        if (address) {
            setEditAddress(address);
        } else {
            setEditAddress(null);
        }
        setControlAddressDrawerVisible(true);
    };

    const handleControlAddressDrawerClose = () => {
        setControlAddressDrawerVisible(false);
        setEditAddress(null);
        fetchCompany();
        // fetchCompany();
    };

    const handleDeleteAddress = async (address: Address) => {
        try {
            await AddressService.remove(address.id);
            fetchCompany();
            openNotification('success', 'Başarılı', 'Adres başarıyla silindi');
        } catch (error) {
            console.error('Adres silinirken hata oluştu:', error);
            openNotification('error', 'Hata', 'Adres silinemedi');
        }
    };

    const handleAddAddressClick = () => {
        setEditAddress(null);
        setControlAddressDrawerVisible(true);
        fetchCompany();
    };

    return (
        <>
            <Row justify={type == true ? 'space-between' : 'start'}>
                {type == true ? <h3>Kontrol Adresi</h3> : null}
                {type == true ? <Button disabled={isEdit} type="primary" className='d-button dark-button width-auto mb-16px' onClick={handleAddAddressClick}>
                    Yeni Kontrol Adresi Ekle
                </Button> : <Button disabled={isEdit} type="primary" className='d-button dark-button mb-16px width-auto' onClick={handleAddAddressClick}>
                    Yeni Kontrol Adresi Ekle
                </Button>}

            </Row>
            {type == true &&
                <div style={{ marginBottom: 16 }}>Periyodik kontrolün gerçekleştirileceği adresi seçiniz.</div>
            }
            <Table
                scroll={{ x: 768 }}
                columns={addressColumns}
                pagination={false}
                dataSource={addresses?.filter((address: any) => address?.type === 'CONTROL')}
                rowKey={(record) => record.id}
                onRow={(record) => ({
                    onClick: () => handleRadioChange(record),
                })}
            />
            <ControlAddressDrawer
                visible={isControlAddressDrawerVisible}
                onClose={handleControlAddressDrawerClose}
                companyId={companyId as string}
                addresses={addresses as any}
                editAddress={editAddress}
            />
        </>
    )
}

export default ControlAddress