import React from 'react';
import NotFound from '../views/NotFound';

interface PrivateRouteProps {
  element: JSX.Element;
  permission: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element, permission }) => {
  let userPermissions: string[] = [];

  try {
    userPermissions = JSON.parse(localStorage.getItem('permissions') || '[]');
  } catch (e) {
    console.error("Error parsing permissions from localStorage", e);
  }

  const hasPermission = userPermissions.includes(permission);

  if (hasPermission) {
    return element;
  } else {
    return <NotFound />;
  }
};

export default PrivateRoute;
