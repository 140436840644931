import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import ProposalService from '../../services/ProposalService';
import { ApexOptions } from 'apexcharts';

const ProposalChart: React.FC = () => {
  const [series, setSeries] = useState<any[]>([]);

  // Ay isimlerini ve karşılık gelen ay numaralarını eşleştiren bir nesne
  const monthMap: { [key: string]: number } = {
    'Ocak': 1,
    'Şubat': 2,
    'Mart': 3,
    'Nisan': 4,
    'Mayıs': 5,
    'Haziran': 6,
    'Temmuz': 7,
    'Ağustos': 8,
    'Eylül': 9,
    'Ekim': 10,
    'Kasım': 11,
    'Aralık': 12,
  };

  const fetchProposals = async () => {
    try {
      const response: any = await ProposalService.monthlyCounts();

      if (response && response.data) {
        // Yılı dinamik olarak alabilirsiniz veya sabit bir yıl kullanabilirsiniz
        const currentYear = new Date().getFullYear();

        const approvedData = response.data.approved.map((item: any) => {
          const monthNumber = monthMap[item.month.trim()] || 1;
          const date = new Date(currentYear, monthNumber - 1, 1).getTime();
          return { x: date, y: Number(item.count) || 0 };
        });

        const rejectedData = response.data.rejected.map((item: any) => {
          const monthNumber = monthMap[item.month.trim()] || 1;
          const date = new Date(currentYear, monthNumber - 1, 1).getTime();
          return { x: date, y: Number(item.count) || 0 };
        });

        setSeries([
          { name: 'Teklif Onaylandı', data: approvedData },
          { name: 'Olumsuz', data: rejectedData },
        ]);
      } else {
        console.error('API Yanıtı eksik:', response);
      }
    } catch (error) {
      console.error('API Hatası:', error);
    }
  };

  useEffect(() => {
    fetchProposals();
  }, []);

  const options: ApexOptions = {
    chart: {
      type: 'line',
      height: 350,
      background: '#ffffff',
      toolbar: { show: false },
      zoom: { enabled: false },
      animations: { enabled: true },
    },
    colors: ['#000', '#D1D1D1'],
    stroke: {
      curve: 'smooth',
      width: [3, 3],
      dashArray: [0, 5],
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'MMM',
        style: { colors: '#6c757d' },
      },
      tooltip: {
        formatter: (value: any) => {
          const date = new Date(value);
          return date.toLocaleDateString('tr-TR', { month: 'long', year: 'numeric' });
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value: number) => value.toString(),
        style: { colors: '#6c757d' },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        formatter: (value: number) => {
          const date = new Date(value);
          return date.toLocaleDateString('tr-TR', { month: 'long', year: 'numeric' });
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      itemMargin: { horizontal: 10, vertical: 5 },
    },
  };

  return (
    <div>
      <h3 style={{ color: '#001F3F', fontSize: 24 }}>Teklifler</h3>
      {series.length > 0 ? (
        <Chart options={options} series={series} type="line" height={410} />
      ) : (
        <p>Veriler yükleniyor...</p>
      )}
    </div>
  );
};

export default ProposalChart;
