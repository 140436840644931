import { Button, Form, Input, Checkbox, Divider, Row, Col, Typography } from 'antd';
import { useEffect, useState } from 'react';
import PermissionService from '../../services/PermissonService';
import RoleService from '../../services/RoleService';
import { IPermissionResponseData } from '../../types/Permission';
import { useNavigate } from 'react-router-dom';
import { openNotification } from '../../utils';

const { Title } = Typography;

interface RoleCreateProps {
    onClose: () => void;
    getRoles: () => void;
}

const RoleCreate: React.FC<RoleCreateProps> = ({ onClose, getRoles }) => {
    const [permissions, setPermissions] = useState<IPermissionResponseData[]>([]);
    const [groupedPermissions, setGroupedPermissions] = useState<{ [key: string]: IPermissionResponseData[] }>({});
    const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);
    const navigate = useNavigate();
    const [form] = Form.useForm();


    useEffect(() => {
        fetchPermissions();
    }, []);

    const fetchPermissions = () => {
        PermissionService.getAll()
            .then((response: any) => {
                const sortedData = [...response.data].sort((a, b) => a.name.localeCompare(b.name));
                setPermissions(sortedData);
                groupPermissions(sortedData);
            })
            .catch((error: Error) => {
                console.error('İzinler alınamadı', error);
            });
    };

    const groupPermissions = (permissions: IPermissionResponseData[]) => {
        const grouped = permissions.reduce((acc, permission) => {
            const [group] = permission.name.split('.');
            if (!acc[group]) {
                acc[group] = [];
            }
            acc[group].push(permission);
            return acc;
        }, {} as { [key: string]: IPermissionResponseData[] });

        setGroupedPermissions(grouped);
    };

    const onChange = (checkedValues: any[]) => {
        setSelectedPermissions(checkedValues as number[]);
    };

    const onFinish = (values: any) => {
        const updatedValues = {
            ...values,
            permissionIds: selectedPermissions,
        };
        RoleService.create(updatedValues)
            .then(() => {
                // navigate('/roles');
                openNotification('success', 'Başarılı', 'Rol başarıyla eklendi');
                getRoles();
                onClose();
            })
            .catch((error: Error) => {
                console.error('Rol oluşturulamadı', error);
                openNotification('error', 'Başarısız', 'Rol eklenirken hata oluştu');

            });
    };

    return (
        <Form onFinish={onFinish} layout="vertical" className="role-create-form">
            <Form.Item
                name="name"
                label={<p style={{ fontSize: "16px", margin: 0, fontWeight: 'bold' }}>Rol Adı </p>}
                rules={[{ required: true, message: 'Lütfen rol adını giriniz!' }]}
            >
                <Input
                    placeholder="Lütfen Rol Adı Giriniz."
                    className='d-input d-input-form'
                />
            </Form.Item>
            {/* <Form.Item
                name="description"
                label={<p style={{ fontSize: "16px", margin: 0, fontWeight: 'bold' }}>Rol Açıklaması </p>}
                rules={[{ required: true, message: 'Lütfen rol açıklaması giriniz!' }]}
            >
                <Input
                    placeholder="Lütfen Rol Açıklaması Giriniz."
                    className='d-input d-input-form'
                />
            </Form.Item> */}
            <Form.Item name="permissions" label={<p style={{ fontSize: "16px", margin: 0, fontWeight: 'bold' }}>Rol Yetkileri </p>}
            >
                <Divider style={{ marginTop: 16 }}></Divider>
                {Object.keys(groupedPermissions).map(group => (
                    <div key={group}>
                        <Row gutter={[16, 16]} className="permission-row">
                            <Col span={5}> <span style={{ fontWeight: 'bold' }}>{group}</span></Col>
                            {groupedPermissions[group].map(permission => (
                                <Col key={permission.id} span={4}>
                                    <Checkbox
                                        value={permission.id}
                                        onChange={e => {
                                            const newSelectedPermissions = e.target.checked
                                                ? [...selectedPermissions, permission.id]
                                                : selectedPermissions.filter(id => id !== permission.id);
                                            onChange(newSelectedPermissions);
                                        }}
                                    >
                                        {permission.name.split('.')[1]}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                        <Divider></Divider>
                    </div>
                ))}
            </Form.Item>
            <Form.Item>
                <Row justify={'center'}>
                    <Button type="primary" htmlType="submit" className="d-button width-auto dark-button">
                        Yeni Rol Ekle
                    </Button>
                </Row>
            </Form.Item>
        </Form>
    );
};

export default RoleCreate;
