import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { formatDate } from '../../utils';
import TrashIcon from './../../assets/icons/trash.svg';
import PenIcon from './../../assets/icons/pen.svg';
import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, Popconfirm, Card, Spin, Tooltip, Tag, Select, Row, Col, Typography } from 'antd';
import { deleteTicket, getAllTickets } from './Service/TicketService';
import NewTicketDrawer from './NewTicketDrawer';
import EditTicketDrawer from './EditTicketDrawer';
import { Ticket } from './Type/Ticket';
import UserService from './../../services/UserService';
import { debounce } from 'lodash';

const { Option } = Select;
const { Title } = Typography;


const TicketList: React.FC = () => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [userPage, setUserPage] = useState(1);
  const [userTotal, setUserTotal] = useState(0);
  const [userSearchTerm, setUserSearchTerm] = useState('');

  const fetchTickets = async (accountId?: number) => {
    setLoading(true);
    let parametre = accountId ? `?accountId=${accountId}` : '';
    try {
      const data: Ticket[] = await getAllTickets(parametre);
      setTickets(data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
    } catch (error) {
      console.error('Failed to fetch tickets:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async (page: number = 1, search: string = '') => {
    try {
      const response: any = await UserService.getAll({ page: page - 1, size: 10, name: search });
      setUsers(page === 1 ? response.data.content : [...users, ...response.data.content]);
      setUserTotal(response.data.totalElements);
    } catch (error) {
      console.error('Failed to fetch users:', error);
    }
  };

  useEffect(() => {
    fetchTickets();
    fetchUsers();
  }, []);

  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteTicket(id);
      fetchTickets();
    } catch (error) {
      console.error('Failed to delete ticket:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUserChange = (userId: number) => {
    setSelectedUserId(userId);
    fetchTickets(userId);
  };

  const debouncedUserSearch = useCallback(
    debounce((value: string) => {
      setUserPage(1); // Aramada ilk sayfaya dön
      setUsers([]); // Arama başında users listesini temizle
      fetchUsers(1, value); // Arama terimiyle kullanıcıları getir
    }, 300),
    []
  );

  const handleUserSearch = (value: string) => {
    setUserSearchTerm(value);
    debouncedUserSearch(value);
  };

  const handleUserScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { target } = event;
    if ((target as HTMLElement).scrollTop + (target as HTMLElement).clientHeight >= (target as HTMLElement).scrollHeight) {
      if (users.length < userTotal) {
        const nextPage = userPage + 1;
        setUserPage(nextPage);
        fetchUsers(nextPage, userSearchTerm);
      }
    }
  };

  const translatePriority = (priority: string) => {
    switch (priority) {
      case 'LOW':
        return <Tag color="green">Düşük</Tag>;
      case 'MEDIUM':
        return <Tag color="orange">Orta</Tag>;
      case 'HIGH':
        return <Tag color="red">Yüksek</Tag>;
      case 'OPEN':
        return <Tag color="orange">AÇIK</Tag>;
      case 'CLOSED':
        return <Tag color="green">KAPALI</Tag>;
      default:
        return null;
    }
  };

  const columns = [
    { title: 'Ticket ID', dataIndex: 'id', key: 'id',width:100, render: (text: number) => <b>#{text}</b> },
    { title: 'Konu',width:400, dataIndex: 'subject', key: 'subject' },
    {
      title: 'Kullanıcı',
      dataIndex: 'account',
      key: 'account',
      render: (account: any) => `${account.firstName} ${account.lastName}`,
    },
    { title: 'Oluşturma Tarihi', dataIndex: 'createdAt', key: 'createdAt', width: 150, render: (text: string) => formatDate(text) },
    { title: 'Öncelik', dataIndex: 'priority', key: 'priority',width:90, render: (text: string) => translatePriority(text) },
    { title: 'Durum', dataIndex: 'status', key: 'status',width:90, render: (text: string) => translatePriority(text) },
    {
      title: 'İşlem',
      key: 'action',
      render: (text: any, record: Ticket) => (
        <div style={{ display: 'flex', gap: '16px' }}>
          <Tooltip title="Düzenle">
            <Button
              icon={<img src={PenIcon} alt="pen" className="icon" />}
              shape="circle"
              onClick={() => { setSelectedTicket(record); setDrawerVisible(true); }} className='icon-button'
            />
          </Tooltip>
          <Tooltip title="Sil">
            <Popconfirm
              title="Bu destek talebini silmek istediğinizden emin misiniz?"
              onConfirm={() => handleDelete(record.id)}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button
                icon={<img src={TrashIcon} alt="trash" className="icon" />}
                shape="circle"
                className='icon-button'
              />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className='ticket-list-container mb-120px'>
      <Card className='br-30px'>
        <Row gutter={[16, 16]} align="middle" justify="space-between" className="mb-20px">
          <Col xs={24} sm={12} lg={16}>
            <Title level={4} style={{ margin: 0 }}>
              Destek Talepleri
            </Title>
          </Col>
          <Col xs={24} sm={12} lg={8} style={{ textAlign: 'center' }} className="d-flex justify-content-end">
            <>
              <Select
                showSearch
                placeholder="Kullanıcı Seçin"
                style={{ width: '200px' }}
                className='d-input-filter'
                onChange={handleUserChange}
                onSearch={handleUserSearch} // Arama işlevini buraya ekledik
                onPopupScroll={handleUserScroll}
                allowClear
                filterOption={false} // Manuel arama için filtreleme kapatıldı
              >
                {users.map((user: any) => (
                  <Option key={user.id} value={user.id}>
                    {`${user.firstName} ${user.lastName}`}
                  </Option>
                ))}
              </Select>
            </>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Table
            columns={columns}
            dataSource={tickets}
            rowKey="id"
            scroll={{ x: 'max-content' }}
          />
        </Spin>
        {selectedTicket ? (
          <EditTicketDrawer
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            ticketId={selectedTicket.id}
            onSave={fetchTickets}
            initialValues={selectedTicket}
          />
        ) : (
          <NewTicketDrawer
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            onSave={fetchTickets}
            initialValues={{ priority: 'LOW' }}
          />
        )}
      </Card>
    </div>
  );
};

export default TicketList;