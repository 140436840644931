import { http } from "../http-common";

const getAllOfferings = (search?: string) => {
    const params = search ? { params: { search } } : {};
    return http.get<any[]>('/offerings', params);
};

const createOffering = (data: any) => {
    return http.post<any>('/offerings', data);
};

const updateOffering = (id: number, data: any) => {
    return http.put<any>(`/offerings/${id}`, data);
};

const deleteOffering = (id: number) => {
    return http.delete(`/offerings/${id}`);
};

const OfferingService = {
    getAllOfferings,
    createOffering,
    updateOffering,
    deleteOffering,
};

export default OfferingService;