import { Card, Col, Row, Button, Select, Table, Tag, Space, Input, DatePicker, Typography } from 'antd';
import { PlusOutlined, CalendarOutlined, ArrowUpOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import './Dashboard.scss';
import { Link } from 'react-router-dom';
import ArrowIcon from '../../assets/icons/arrow-up.svg';
import ProposalChart from '../../components/Charts/ProposalChart';
import RenuewChart from '../../components/Charts/RenuewChart';
import JobTrackingChart from '../../components/Charts/JobTrackingChart';
import RenuewCard from '../../components/Vallet/RenuewCard';
import ContractCountCard from '../../components/Vallet/ContractCountCard';
import SummaryCard from '../../components/Vallet/SummaryCard';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text, Title } = Typography;

const Dashboard = () => {

  return (
    <div className='p-20px'>
      <Row gutter={[16, 16]}>
        <Col lg={6} md={24} xs={24}>
          <Link to="/proposals/create">
            <Card bordered={false} className="dashboard-card dashboard-card-bg-dark" bodyStyle={{ padding: 10 }}>
              <div className='dashboard-container'>
                <div className='card-icon'>
                  <PlusOutlined width={20} height={20} />
                </div>
                <div>
                  <Title style={{ fontWeight: 'bold', color: "white" }} className='mb-0' level={3}>Teklif Oluşturun</Title>
                  <Text style={{ fontWeight: 300, color: "white", fontSize: 18 }} className="m-0">Gelirinizi Arttırın</Text>
                </div>
              </div>
            </Card>
          </Link>
        </Col>
        <Col lg={6} md={24} xs={24}>
          <Link to="/proposals?activeKey=2">
           <SummaryCard/>
          </Link>
        </Col>
        <Col lg={6} md={24} xs={24}>
          <Link to="/project-followup">
           <ContractCountCard/>
          </Link>
        </Col>
        <Col lg={6} md={24} xs={24}>
            <RenuewCard />
        </Col>

      </Row>

      {/* Teklifler Bölümü */}
      <Row gutter={[16, 16]} style={{ marginTop: '24px' }}>
        <Col lg={14}>
          <Card style={{ height: 540 }} className='chart-card'>
            <ProposalChart />
          </Card>
        </Col>
        <Col lg={10}>
          <Card style={{ height: 260, marginBottom: 20 }} className='chart-card'>
            <JobTrackingChart />
          </Card>
          <Card style={{ height: 260 }} className='chart-card'>
            <RenuewChart />
          </Card>
        </Col>
      </Row>


    </div>
  );
};

export default Dashboard;