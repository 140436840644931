import { Button, Col, Row, Space, Table, Typography, Popconfirm, Modal, Tooltip, Card, Input } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import RoleService from "../../services/RoleService";
import { useEffect, useState } from 'react';
import { IRoleResponseData } from '../../types/Role';
import RoleDetail from './RoleDetail';
import RoleCreate from './RoleCreate';
import RoleEdit from './RoleEdit';
import TrashIcon from '../../assets/icons/trash.svg';
import PenIcon from '../../assets/icons/pen.svg';
import { openNotification } from '../../utils';
import './../../App.scss';
import { SearchOutlined } from '@ant-design/icons';

const { Title } = Typography;

export const RoleList = () => {
    const [roles, setRoles] = useState<IRoleResponseData[]>([]);
    const [filteredRoles, setFilteredRoles] = useState<IRoleResponseData[]>([]); // Filtrelenmiş roller için state
    const [loading, setLoading] = useState(true);
    const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedRoleId, setSelectedRoleId] = useState<number | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>(''); // Arama terimi için state

    const columns: ColumnsType<IRoleResponseData> = [
        {
            title: 'Rol Adı',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Oluşturma Tarihi',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => text ? new Date(text).toLocaleDateString('tr-TR') + ' ' + new Date(text).toLocaleTimeString('tr-TR') : '-',
        },
        {
            title: 'Son Güncelleme Tarihi',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (text) => text ? new Date(text).toLocaleDateString('tr-TR') + ' ' + new Date(text).toLocaleTimeString('tr-TR') : '-',
        },
        {
            title: 'Detay',
            dataIndex: 'Detay',
            key: 'Detay',
            render: (_, row) => (
                <Space size={14}>
                    <Button
                        icon={<img src={PenIcon} alt="pen" className="icon" />}
                        shape="circle"
                        onClick={() => handleEditClick(row.id)}
                        className='icon-button'
                    />
                    <Popconfirm
                        title="Bu rolü silmek istediğinize emin misiniz?"
                        onConfirm={() => deleteRole(row.id)}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button
                            icon={<img src={TrashIcon} alt="trash" className="icon" />}
                            shape="circle"
                            className='icon-button'
                        />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        getRoles();
    }, []);

    const getRoles = () => {
        setLoading(true);
        RoleService.getAll()
            .then((response: any) => {
                let data = response.data;
                setRoles(data);
                setFilteredRoles(data); // İlk başta tüm rolleri filtrelenmiş olarak ayarla
                setLoading(false);
            })
            .catch((e: Error) => {
                console.log(e);
                setLoading(false);
            });
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchTerm(value);

        // Arama terimi ile roles listesini filtreleyip filteredRoles state'ine aktar
        const filteredData = roles.filter(role =>
            role.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredRoles(filteredData);
    };

    const deleteRole = (id: number) => {
        RoleService.remove(id)
            .then(() => {
                const updatedRoles = roles.filter(role => role.id !== id);
                setRoles(updatedRoles);
                setFilteredRoles(updatedRoles); // Silme işleminden sonra filtrelenmiş listeyi de güncelle
                openNotification('success', 'Başarılı', 'Rol başarıyla silindi.');
            })
            .catch((e: Error) => {
                console.log(e);
                openNotification('error', 'Hata', 'Rol silinirken bir hata oluştu.');
            });
    };

    const handleDetailClick = (id: number) => {
        setSelectedRoleId(id);
        setIsDetailModalVisible(true);
    };

    const handleCreateClick = () => {
        setIsCreateModalVisible(true);
    };

    const handleEditClick = (id: number) => {
        setSelectedRoleId(id);
        setIsEditModalVisible(true);
    };

    const handleDetailModalCancel = () => {
        setIsDetailModalVisible(false);
        setSelectedRoleId(null);
    };

    const handleCreateModalCancel = () => {
        setIsCreateModalVisible(false);
    };

    const handleEditModalCancel = () => {
        setIsEditModalVisible(false);
        setSelectedRoleId(null);
        getRoles();
    };

    return (
        <div className='role-container'>
            <Card className='br-30px'>
                <Row justify={'space-between'} align="middle">
                    <Col>
                        <Title style={{ margin: 0 }} level={4}>Rol Listesi</Title>
                    </Col>
                    <Col>
                        <Row>

                            <Input
                                placeholder="Ara"
                                prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
                                className="d-input d-input-form mr-16px d-input-filter"
                                style={{ width: 200, marginRight: 16 }}
                                onChange={handleSearchChange}
                            />
                            <Button type="primary" onClick={handleCreateClick} className='width-auto d-button dark-button'>
                                Yeni Rol Ekle
                            </Button>
                        </Row>
                    </Col>
                </Row>
                <Row className='mt-30px'>
                    <Col span={24}>
                        <Table
                            scroll={{ x: 768 }}
                            loading={loading}
                            columns={columns}
                            dataSource={filteredRoles} // Filtrelenmiş listeyi tabloya aktarıyoruz
                            rowKey={(record) => record.id}
                            pagination={false}
                        />
                    </Col>
                </Row>
                <Modal
                    title="Rol Detay"
                    visible={isDetailModalVisible}
                    onCancel={handleDetailModalCancel}
                    footer={null}
                >
                    {selectedRoleId && <RoleDetail id={selectedRoleId} />}
                </Modal>
                <Modal
                    visible={isCreateModalVisible}
                    onCancel={handleCreateModalCancel}
                    footer={null}
                >
                    <RoleCreate onClose={handleCreateModalCancel} getRoles={getRoles} />
                </Modal>
                <Modal
                    visible={isEditModalVisible}
                    onCancel={handleEditModalCancel}
                    footer={null}
                >
                    {selectedRoleId !== null && <RoleEdit id={selectedRoleId} onClose={handleEditModalCancel} />}
                </Modal>
            </Card>
        </div>
    );
};