import { http } from "../http-common";

const getAll = () => {
  return http.get<Array<any>>("/authorized-persons");
};

const getAllCompany = (companyId: number) => {
  return http.get<Array<any>>(`/authorized-persons/${companyId}/company`);
};


const get = (id: number) => {
  return http.get<any>(`/authorized-persons/${id}`);
};

const getCompanyAuthorizedPersons = (companyId: number) => {
  return http.get<any>(`/authorized-persons/${companyId}/company`);
};

const create = (data: any) => {
  return http.post<any>("/authorized-persons", data);
};

const update = (id: number, data: any) => {
  return http.put<any>(`/authorized-persons/${id}`, data);
};

const remove = (id: number) => {
  return http.delete<void>(`/authorized-persons/${id}`);
};

const setDefault = (authorizedPersonId: number) => {
  return http.post(`/authorized-persons/${authorizedPersonId}/default`);
};


const AuthorizedPersonService = {
  getAll,
  get,
  create,
  update,
  remove,
  getCompanyAuthorizedPersons,
  setDefault,
  getAllCompany
};

export default AuthorizedPersonService;