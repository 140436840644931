import React, { useEffect, useState } from 'react';
import { Modal, InputNumber, Input, Button, Form, Row, Col, Select } from 'antd';
import DiscountRateService from '../../services/DiscountRateService';
import OfferingService from '../../services/OfferingService';
import { openNotification } from '../../utils';

interface DiscountRateModalProps {
    visible: boolean;
    onClose: () => void;
    selectedDiscountRate: DiscountRate | any;
    fetchDiscountRates: () => void;
}

interface DiscountRate {
    id: number;
    maxPrice: number;
    discountRate: number;
    description: string;
    offeringId: number;
}

const DiscountRateModal: React.FC<DiscountRateModalProps> = ({
    visible,
    onClose,
    selectedDiscountRate,
    fetchDiscountRates,
}) => {
    const [form] = Form.useForm(); // Ant Design form instance
    const [offerings, setOfferings] = useState<any[]>([]); // Offering'leri tutmak için state

    useEffect(() => {
        fetchOfferings(); // Modal açıldığında Offering'leri getir

        if (selectedDiscountRate) {
            form.setFieldsValue({
                maxPrice: selectedDiscountRate.maxPrice,
                discountRate: selectedDiscountRate.discountRate,
                description: selectedDiscountRate.description,
                offeringId: selectedDiscountRate.offeringId, // offeringId set et
            });
        } else {
            form.resetFields();
        }
    }, [selectedDiscountRate, form]);

    // Offering'leri getirme fonksiyonu
    const fetchOfferings = async () => {
        try {
            const response = await OfferingService.getAllOfferings();
            setOfferings(response.data); // Offering'leri state'e set et
        } catch (error) {
            console.error('Offering verileri alınırken hata oluştu:', error);
        }
    };

    const handleSave = async (values: any) => {
        const newData = {
            maxPrice: values.maxPrice,
            discountRate: values.discountRate,
            description: values.description,
            offeringId: values.offeringId, // Offering ID'yi ekliyoruz
        };

        try {
            if (selectedDiscountRate) {
                await DiscountRateService.update(selectedDiscountRate.id, newData);
                openNotification('success', 'Başarılı', 'İndirim oranı başarıyla güncellendi');
            } else {
                await DiscountRateService.create(newData);
                openNotification('success', 'Başarılı', 'İndirim oranı başarıyla eklendi');
            }
            fetchDiscountRates();
            onClose();
        } catch (error) {
            console.error('Kaydedilirken hata:', error);
        }
    };

    return (
        <Modal
            title={selectedDiscountRate ? 'İndirim Düzenle' : 'Yeni İndirim Ekle'}
            visible={visible}
            onCancel={onClose}
            footer={null} // We'll handle the footer inside the form
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSave}
            >
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            label="İndirim Taban Fiyatı"
                            name="maxPrice"
                            rules={[{ required: true, message: 'Lütfen maksimum fiyatı girin' }]}
                        >
                            <Input
                                style={{ width: '100%' }}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    const value = event.target.value.replace(/[^0-9]/g, '');
                                    event.target.value = value;
                                    if (Number(value) < 0) {
                                        event.target.value = '0';
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="İndirim Oranı"
                            name="discountRate"
                            rules={[{ required: true, message: 'Lütfen indirim oranını girin' }]}
                        >
                            <Input
                                style={{ width: '100%' }}
                                onKeyPress={(event) => {
                                    if (!/[0-9.]$/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    let value = event.target.value;

                                    value = value.replace(',', '.');

                                    if (value.split('.').length > 2) {
                                        value = value.replace(/\.+$/, '');
                                    }

                                    if (Number(value) > 100) {
                                        value = '100';
                                    }

                                    if (Number(value) < 0) {
                                        value = '0';
                                    }

                                    event.target.value = value;
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item
                            label="Grup"
                            name="offeringId"
                            rules={[{ required: true, message: 'Lütfen bir grup seçin' }]}
                        >
                            <Select placeholder="Grup seçin" style={{ width: '100%' }}>
                                {offerings.map((offering) => (
                                    <Select.Option key={offering.id} value={offering.id}>
                                        {offering.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Form.Item
                            label="Açıklama"
                            name="description"
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="center" style={{ marginTop: '20px' }}>
                    <Button
                        key="submit"
                        type="primary"
                        htmlType="submit"
                        className='d-button width-auto dark-button'
                    >
                        {selectedDiscountRate ? 'Güncelle' : 'Kaydet'}
                    </Button>
                </Row>
            </Form>
        </Modal>
    );
};

export default DiscountRateModal;