import TrashIcon from './../../assets/icons/trash.svg';
import PenIcon from './../../assets/icons/pen.svg';
import { formatDate } from '../../utils';
import React, { useEffect, useState } from 'react';
import { Table, Button, Popconfirm, Card, Spin, Tooltip, Tag, Row, Col, Typography } from 'antd';
import { getTickets, deleteTicket } from './Service/TicketService';
import NewTicketDrawer from './NewTicketDrawer';
import EditTicketDrawer from './EditTicketDrawer';
import { Ticket } from './Type/Ticket'; import { useLocation } from 'react-router-dom';
;


const { Title } = Typography;


const TicketList: React.FC = () => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };

  const fetchTickets = async () => {
    setLoading(true);
    try {
      const data: Ticket[] = await getTickets();
      setTickets(data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
    } catch (error) {
      console.error('Failed to fetch tickets:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTickets();

    const queryParams = getQueryParams(location.search);
    const paramParametres: any = queryParams.get('parametres');
    if (paramParametres === 'help') {
      setDrawerVisible(true);
    }
  }, []);


  const handleDelete = async (id: number) => {
    setLoading(true);
    try {
      await deleteTicket(id);
      fetchTickets();
    } catch (error) {
      console.error('Failed to delete ticket:', error);
    } finally {
      setLoading(false);
    }
  };

  const translatePriority = (priority: string) => {
    switch (priority) {
      case 'LOW':
        return <Tag color="green">Düşük</Tag>;
      case 'MEDIUM':
        return <Tag color="orange">Orta</Tag>;
      case 'HIGH':
        return <Tag color="red">Yüksek</Tag>;
      case 'OPEN':
        return <Tag color="orange">AÇIK</Tag>;
      case 'CLOSED':
        return <Tag color="green">KAPALI</Tag>;
      default:
        return null;
    }
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id', width: 100, render: (text: number) => <b>#{text}</b> },
    { title: 'Konu', dataIndex: 'subject', width: 400, key: 'subject' },
    { title: 'Oluşturma Tarihi', dataIndex: 'createdAt', width: 150, key: 'createdAt', render: (text: string) => formatDate(text) },
    { title: 'Açıklama', dataIndex: 'description', key: 'description' },
    { title: 'Öncelik', dataIndex: 'priority', key: 'priority', width: 90, render: (text: string) => translatePriority(text) },
    { title: 'Durum', dataIndex: 'status', key: 'status', width: 90, render: (text: string) => translatePriority(text) },
    {
      title: 'İşlem',
      key: 'action',
      render: (text: any, record: Ticket) => (
        <div style={{ display: 'flex', gap: '16px' }}>
          <Tooltip title="Düzenle">
            <Button
              icon={<img src={PenIcon} alt="pen" className="icon" />}
              shape="circle"
              onClick={() => { setSelectedTicket(record); setDrawerVisible(true); }} className='icon-button'
            />
          </Tooltip>
          {/* <Tooltip title="Sil">
            <Popconfirm
              title="Bu destek talebini silmek istediğinizden emin misiniz?"
              onConfirm={() => handleDelete(record.id)}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button
                icon={<img src={TrashIcon} alt="trash" className="icon" />}
                shape="circle"
                className='icon-button'
              />
            </Popconfirm>
          </Tooltip> */}
        </div>
      ),
    },
  ];

  return (
    <div className='ticket-list-container mb-120px'>
      <Card className='br-30px'>
        <Row gutter={[16, 16]} align="middle" justify="space-between" className="mb-20px">
          <Col xs={24} sm={12} lg={16}>
            <Title level={4} style={{ margin: 0 }}>
              Destek Taleplerim
            </Title>
          </Col>
          <Col xs={24} sm={12} lg={8} style={{ textAlign: 'center' }} className="d-flex justify-content-end">
            <Button type="primary" className='dark-button d-button width-auto' onClick={() => { setSelectedTicket(null); setDrawerVisible(true); }}>Yeni Destek Talebi Oluştur</Button>
          </Col>
        </Row>
        <Spin spinning={loading}>
          <div style={{ overflowX: 'auto' }}>
            <Table
              columns={columns}
              dataSource={tickets}
              rowKey="id"
              scroll={{ x: 'max-content' }}
            />
          </div>
        </Spin>
        {selectedTicket ? (
          <EditTicketDrawer
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            ticketId={selectedTicket.id}
            onSave={fetchTickets}
            initialValues={selectedTicket}
          />
        ) : (
          <NewTicketDrawer
            visible={drawerVisible}
            onClose={() => setDrawerVisible(false)}
            onSave={fetchTickets}
            initialValues={{ priority: 'LOW' }}
          />
        )}
      </Card>
    </div>
  );
};

export default TicketList;