import React, { act, useEffect, useState } from 'react';
import { Card, Col, Row, Input, Avatar, Button, Form, Modal, Typography, Progress, Tabs, Space, Upload } from 'antd';
import UserService from '../../services/UserService';
import { formatDate, formatDateString, openNotification } from '../../utils';
import Contracts from '../../components/Proile/Contracts';
import ImageUpload from '../../components/Uploads/ImageUpload';
import { useParams } from 'react-router-dom';
import TabPane from 'antd/es/tabs/TabPane';
import UserAttemps from '../../views/Profile/UserAttemps';
import UserTabs from './UserTabs';

const { Title, Text } = Typography;

interface ProfileData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    address: string;
    identityNumber: string;
    iban: string;
    password?: string;
    profilePhoto?: string;
    createdAt: string;
    incomeSharing: number;
    maxDiscountRate: any;
}



const UserDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const [profileData, setProfileData] = useState<ProfileData>({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        identityNumber: '',
        iban: '',
        password: '',
        profilePhoto: '',
        createdAt: '',
        incomeSharing: 0,
        maxDiscountRate: 0,
    });
    const [form] = Form.useForm();
    const [roles, setRoles] = useState<any>();
    const [profileCompletion, setProfileCompletion] = useState(0);
    const [imageId, setImageId] = useState<string | null>(null);
    const [activeKey, setActiveKey] = useState('1');

    const getCustomer = () => {
        UserService.get(id)
            .then((response: any) => {
                setProfileData(response.data);
                setImageId(response.data.profilePhoto);


                form.setFieldsValue(response.data);
            })
            .catch((error: any) => {
                console.error('Profil verilerini çekerken hata oluştu!', error);
            });
    }


    useEffect(() => {
        if (id) {
            getCustomer()
        }
    }, [id])

    const calculateProfileCompletion = () => {
        const totalFields = Object.keys(profileData).length;
        const filledFields = Object.values(profileData).filter(value => value).length;
        setProfileCompletion((filledFields / totalFields) * 100);
    };

    useEffect(() => {
        form.setFieldsValue(profileData);
        calculateProfileCompletion();
    }, [profileData])


    const handleUpdateIncomeSharing = (value: any) => {
        UserService.update(id, { ['incomeSharing']: value })
            .then(() => {
                openNotification('success', 'Başarılı', `Gelir paylaşımı başarıyla güncellendi.`);
                getCustomer();
            })
            .catch((e: Error) => {
                // Hata oluşursa
                console.log(e);
                openNotification('error', 'Hata', `Gelir paylaşımı güncellenirken bir hata oluştu.`);
            });
    };

    const handleUpdateMaxDiscountRate = (value: any) => {
        UserService.update(id, { ['maxDiscountRate']: value })
            .then(() => {
                openNotification('success', 'Başarılı', `İndirim oranı başarıyla güncellendi.`);
                getCustomer();
            })
            .catch((e: Error) => {
                // Hata oluşursa
                console.log(e);
                openNotification('error', 'Hata', `İndirim oranı güncellenirken bir hata oluştu.`);
            });
    };

    
    const handleTabChange = (key: string) => {
        setActiveKey(key);
    };

    return (
        profileData.email === '' ? <></> :
            <div className='profile-section mb-120px'>
                <Row gutter={[16, 16]} justify="center">
                    <Col xs={24}>
                        <Card bordered={false} className='profile-card'>
                            <Row align="middle" justify="space-between">
                                <Col>
                                    <Row gutter={[16, 16]}>
                                        <Col>
                                            <ImageUpload disabled={true} imageId={profileData.profilePhoto} setImageId={setImageId} type={'profile'} />
                                        </Col>
                                        <Col>
                                            <div>
                                                <Title level={4} style={{ margin: 0 }}>{profileData.firstName + ' ' + profileData.lastName}</Title>
                                                <Text>{profileData.email}</Text><br />
                                                {roles && !roles.some((role: any) => role.name === 'ADMIN') && (
                                                    <>
                                                        <Text>Kayıt Tarihi: {formatDateString(profileData.createdAt)}</Text><br />
                                                    </>
                                                )}
                                                <Row >
                                                    <Progress percent={profileCompletion} showInfo={false} size="small" trailColor='primary' style={{ flex: 1 }} strokeColor={'#6179fa'} />
                                                    <Text style={{ marginLeft: '10px' }}> %{Math.round(profileCompletion)}</Text>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    {/* Additional Buttons or Elements */}
                                </Col>
                            </Row>
                        </Card>
                        <Card className='profile-card' style={{ marginTop: 32 }}>

                            <Form form={form} layout="vertical">
                                <Row gutter={24}>
                                    <Col xs={24} md={12}>
                                        <Form.Item
                                            label="IBAN"
                                            name="iban"
                                        >
                                            <Input
                                                disabled
                                                addonBefore="TR"
                                                className="d-input d-input-form fs-24px"
                                                maxLength={24}
                                                defaultValue={profileData.iban || ''}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Form.Item>

                                        {/* Telefon */}
                                        <Form.Item
                                            label="Telefon"
                                            name="phoneNumber"
                                            rules={[
                                                { required: true, message: 'Telefon gerekli' },
                                                { len: 10, message: 'Telefon 10 karakter olmalıdır' },
                                                { pattern: /^[0-9]*$/, message: 'Telefon sadece rakamlardan oluşmalıdır' },
                                            ]}
                                        >
                                            <Space.Compact className="width-100">
                                                <Input
                                                    disabled
                                                    addonBefore="+90"
                                                    defaultValue={profileData.phoneNumber || ''}
                                                    className="d-input d-input-form "
                                                    maxLength={10}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={() => form.validateFields(['phoneNumber'])} // Bu alan her değiştiğinde validasyon yapılır
                                                />
                                                <Form.Item
                                                    shouldUpdate={(prevValues, currentValues) =>
                                                        prevValues.phoneNumber !== currentValues.phoneNumber
                                                    }
                                                    noStyle
                                                >
                                                </Form.Item>
                                            </Space.Compact>
                                        </Form.Item>

                                        {/* TC Kimlik No */}
                                        <Form.Item
                                            label="TC Kimlik No"
                                            name="identityNumber"
                                            rules={[
                                                { required: true, message: 'Lütfen TC Kimlik No giriniz!' },
                                                { pattern: /^[0-9]*$/, message: 'TC Kimlik No sadece rakamlardan oluşmalıdır' },
                                                {
                                                    validator: (_, value) => {
                                                        if (!value || value.length !== 11) {
                                                            return Promise.reject('TC Kimlik Numarası 11 haneli olmalıdır');
                                                        }

                                                        const firstTenDigits = value.substring(0, 10).split('').map(Number);
                                                        const sumOfFirstTen = firstTenDigits.reduce((acc: any, curr: any) => acc + curr, 0);
                                                        const lastDigitOfSum = sumOfFirstTen % 10;

                                                        if (lastDigitOfSum !== Number(value[10])) {
                                                            return Promise.reject('TC Kimlik Numarası geçersiz.');
                                                        }

                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                disabled
                                                className="d-input d-input-form"
                                                defaultValue={isNaN(Number(profileData.identityNumber)) ? '' : profileData.identityNumber}
                                                maxLength={11}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={0} md={1} style={{ minHeight: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                        <div style={{ width: '1px', height: '100%', backgroundColor: '#f0f0f0' }}></div>
                                    </Col>

                                    {/* Sağ taraf: Adres Bilgileri */}
                                    <Col xs={24} md={11}>
                                        <Form.Item
                                            label="Adres"
                                            name="address"
                                            rules={[
                                                { required: true, message: 'Adres gerekli' },
                                                { max: 500, message: 'Adres en fazla 500 karakter olmalıdır' },
                                            ]}
                                        >
                                            <Input.TextArea
                                                disabled
                                                rows={4}
                                                className="d-input d-input-form"
                                                onKeyPress={(event) => {
                                                    const target = event.target as HTMLTextAreaElement;
                                                    if (target.value.length >= 500) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Item
                                                    label="Gelir Paylaşımı"
                                                    name="incomeSharing"
                                                    rules={[
                                                        { pattern: /^[0-9]*$/, message: 'Gelir Paylaşımı sadece rakamlardan oluşmalıdır' },
                                                    ]}
                                                >
                                                    <Space.Compact className="width-100">
                                                        <Input
                                                            addonBefore="%"
                                                            placeholder="0"
                                                            defaultValue={profileData.incomeSharing || ''}
                                                            className="d-input d-input-form"
                                                            style={{ width: '100px' }}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={() => form.validateFields(['incomeSharing'])}
                                                        />
                                                        <Form.Item
                                                            shouldUpdate={(prevValues, currentValues) =>
                                                                prevValues.incomeSharing !== currentValues.incomeSharing
                                                            }
                                                            noStyle
                                                        >
                                                            {({ getFieldError, getFieldValue }) => {
                                                                const hasError = getFieldError('incomeSharing').length > 0 || !getFieldValue('incomeSharing');
                                                                return (
                                                                    <Button
                                                                        className="d-button-with-input dark-button width-auto"
                                                                        type="primary"
                                                                        onClick={() => handleUpdateIncomeSharing(form.getFieldValue('incomeSharing'))}
                                                                        disabled={hasError}
                                                                    >
                                                                        Kaydet
                                                                    </Button>
                                                                );
                                                            }}
                                                        </Form.Item>
                                                    </Space.Compact>
                                                </Form.Item>
                                            </Col>
                                            <Col md={12}>

                                                <Form.Item
                                                    label="İndirim Oranı"
                                                    name="maxDiscountRate"
                                                    rules={[
                                                        { pattern: /^[0-9]*$/, message: 'Gelir Paylaşımı sadece rakamlardan oluşmalıdır' },
                                                    ]}
                                                >
                                                    <Space.Compact className="width-100">
                                                        <Input
                                                            addonBefore="%"
                                                            placeholder="0"
                                                            defaultValue={profileData.maxDiscountRate || ''}
                                                            className="d-input d-input-form"
                                                            style={{ width: '100px' }}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={() => form.validateFields(['maxDiscountRate'])}
                                                        />
                                                        <Form.Item
                                                            shouldUpdate={(prevValues, currentValues) =>
                                                                prevValues.maxDiscountRate !== currentValues.maxDiscountRate
                                                            }
                                                            noStyle
                                                        >
                                                            {({ getFieldError, getFieldValue }) => {
                                                                const hasError = getFieldError('maxDiscountRate').length > 0 || !getFieldValue('maxDiscountRate');
                                                                return (
                                                                    <Button
                                                                        className="d-button-with-input dark-button width-auto"
                                                                        type="primary"
                                                                        onClick={() => handleUpdateMaxDiscountRate(form.getFieldValue('maxDiscountRate'))}
                                                                        disabled={hasError}
                                                                    >
                                                                        Kaydet
                                                                    </Button>
                                                                );
                                                            }}
                                                        </Form.Item>
                                                    </Space.Compact>
                                                </Form.Item>
                                            </Col>
                                        </Row>


                                    </Col>

                                </Row>

                                {/* Single Save Button */}
                                <Row justify={'end'}>

                                </Row>
                            </Form>

                        </Card>
                        <Card className='br-30px mt-16px'>
                            <UserTabs profileData={profileData} />
                        </Card>
                    </Col>
                </Row>

            </div>
    );
};

export default UserDetail;
